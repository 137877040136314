import { useState } from "react";

import { CloseIcon } from "studio-design-system";

import { Post, reportPost } from "~/api/sfPeersApi";
import { OverlayModal } from "~/components/common/OverlayModal";
import { Button } from "~/components/school/common/Button/Button";

import { Dropdown } from "../../common/Dropdown";
import * as styles from "./ReportPostModal.css";

export interface ReportPostModalProps {
  post?: Post | null;
  isOpen: boolean;
  onClose: () => void;
}

const reportReasons = [
  {
    label: "Harassment",
    value: "Harassment",
  },
  {
    label: "Suicide or self-injury",
    value: "Suicide or self-injury",
  },
  {
    label: "Sharing inappropriate things",
    value: "Sharing inappropriate things",
  },
  {
    label: "Hate speech",
    value: "Hate speech",
  },
  {
    label: "Unauthorized or illegal activity",
    value: "Unauthorized or illegal activity",
  },
  {
    label: "Violent or graphic content",
    value: "Violent or graphic content",
  },
];

export const ReportPostModal = ({ post, isOpen, onClose }: ReportPostModalProps) => {
  const [submitted, setSubmitted] = useState(false);
  const [selectedReason, setSelectedReason] = useState({
    label: "",
    value: "",
  });

  const handleSubmit = async () => {
    if (!post) return;

    await reportPost(post, selectedReason.label, selectedReason.value);

    setSubmitted(true);
  };

  return (
    <OverlayModal contentClass={styles.modalContent} isOpen={isOpen} onClose={onClose}>
      <div>
        <div className={styles.closeButton} onClick={onClose}>
          <CloseIcon width={18} height={18} />
        </div>
        {submitted ? (
          <div>
            <h3 className={styles.modalTitle}>Report submitted</h3>
            <p className={styles.description}>
              Thanks for your help keeping this platform safe. We’ll review your report for further
              action.
            </p>
          </div>
        ) : (
          <div>
            <h3 className={styles.modalTitle}>Report this post</h3>
            <p className={styles.description}>
              We take each report very seriously—a human on our side will take a look at this
              report. To best address this, can you share why you’re reporting this post?
            </p>
            <Dropdown
              label="Select a reason"
              placeholder="Please select a reason"
              options={reportReasons}
              selected={selectedReason}
              className={styles.dropdown}
              setSelected={setSelectedReason}
            />
            <div className={styles.buttonContainer}>
              <Button variant="tertiary" label="Cancel" onClick={onClose} />
              <Button
                variant="quaternary"
                label="Submit report"
                removeMargin
                onClick={handleSubmit}
                className={styles.submitReportButton}
              />
            </div>
          </div>
        )}
      </div>
    </OverlayModal>
  );
};
