import { useRouter } from "next/router";
import { useQuery } from "react-query";

import { ExperienceId } from "data/experiences/experiences";
import { ProductData, PurchaseableItem } from "data/product/products";

import { PaymentInfo } from "~/components/checkout/Checkout.data";
import { useProductReferralInformation } from "~/components/checkout/PaymentMethods/hooks/useReferralInformation";
import { Enrollment } from "~/components/checkout/interfaces/enrollment";
import { useAuth } from "~/hooks/auth/useAuth";
import { resetReferralParams } from "~/util/persistUrlParams";

import { ApiStatus, ApiResult, fetchApi } from "./fetchStudioApi";
import { MembershipTier } from "./membershipApi";
import { QueryKeys } from "./reactQuery/client";
import { StudioUser } from "./userApi";

export const useGetUserCardInfo = () => {
  const { user } = useAuth();

  const { data: cardInfo, isLoading } = useQuery([QueryKeys.UserCardInfo], () =>
    fetchUserCardInfo(),
  );

  const { stripeCustomerId } = user || {};

  const fetchUserCardInfo = async () => {
    if (stripeCustomerId) {
      const response = fetchApi({
        method: "POST",
        body: {},
        path: "/getCardData",
        authRequired: true,
      });
      if (response) {
        return response;
      }
    } else {
      return undefined;
    }
  };

  return { cardInfo, isLoading };
};

export const useChargeForProduct = () => {
  const router = useRouter();

  const chargeForProduct = ({
    paymentInfo,
    productIds,
  }: {
    paymentInfo: PaymentInfo;
    productIds: string[] | string;
  }): Promise<ApiResult> => {
    const queryParams = router.query;

    console.log("url params in charging for product ", queryParams);
    // Get the persisted referral codes

    console.log("and specifically referrer ? ", queryParams["ref"] as string);

    if ("sessionSlug" in queryParams) {
      paymentInfo["sessionSlug"] = queryParams["sessionSlug"] as string;
      console.log("got the session slug from query params ", paymentInfo["sessionSlug"]);
    }

    if ("schoolSlug" in queryParams) {
      paymentInfo["schoolSlug"] = queryParams["schoolSlug"] as string;
    }

    paymentInfo["utm_campaign"] =
      (queryParams["utm_campaign"] as string) || localStorage.getItem("utm_campaign") || "";

    paymentInfo["utm_content"] =
      (queryParams["utm_content"] as string) || localStorage.getItem("utm_content") || "";
    paymentInfo["utm_medium"] =
      (queryParams["utm_medium"] as string) || localStorage.getItem("utm_medium") || "";
    paymentInfo["utm_source"] =
      (queryParams["utm_source"] as string) || localStorage.getItem("utm_source") || "";
    paymentInfo["utm_term"] =
      (queryParams["utm_term"] as string) || localStorage.getItem("utm_term") || "";
    paymentInfo["referrer"] = (queryParams["ref"] as string) || localStorage.getItem("ref") || "";
    paymentInfo["discountCode"] =
      (queryParams["code"] as string) || localStorage.getItem("code") || "";
    paymentInfo["friend"] =
      (queryParams["friend"] as string) || localStorage.getItem("friend") || "";
    let noPriceOffer =
      (queryParams["noPriceOffer"] as string) || localStorage.getItem("noPriceOffer");
    console.log("got no price offer ? ", noPriceOffer);
    if (noPriceOffer) {
      paymentInfo["noPriceOffer"] = noPriceOffer;
    }

    console.log("got person code ? ", queryParams["p"] as string);
    console.log("got person code from local storage ? ", localStorage.getItem("p"));
    let personCode = (queryParams["p"] as string) || localStorage.getItem("p");
    if (personCode) {
      paymentInfo["p"] = personCode;
    }
    console.log("SO THE PAYMENTINFO IS ", paymentInfo);
    var urlPath = "/purchase";
    var postData = JSON.stringify({ payment_info: paymentInfo, product_id: productIds });
    if (Array.isArray(productIds)) {
      urlPath = "/purchaseItems";
      postData = JSON.stringify({ payment_info: paymentInfo, product_ids: productIds });
    }

    resetReferralParams();
    return fetchApi({
      path: urlPath,
      body: postData,
      method: "POST",
      isJsonPayload: true,
      authRequired: true,
    });
  };

  return { chargeForProduct };
};

export const chargeForSubscription = ({
  paymentInfo,
  classId,
  tier,
  classBatchName,
}: {
  paymentInfo: PaymentInfo;
  classId?: ExperienceId;
  tier: MembershipTier;
  classBatchName?: string;
}): Promise<{ status: ApiStatus; userInfo?: StudioUser }> => {
  console.log("about to SUBSCRIPTION charge!! ");
  return fetchApi({
    path: "/purchase",
    body: JSON.stringify({ paymentInfo: paymentInfo, product_id: classId }),
    method: "POST",
    isJsonPayload: true,
    authRequired: true,
  });
};

// export const chargeForSubscription = ({
//   paymentInfo,
//   classId,
//   tier,
//   classBatchName,
// }: {
//   paymentInfo: PaymentInfo;
//   classId?: ExperienceId;
//   tier: MembershipTier;
//   classBatchName?: string;
// }): Promise<{ status: ApiStatus; userInfo?: StudioUser }> => {

//   return fetchApi({
//     path: "/subscription",
//     body: JSON.stringify({ paymentInfo, classId, tier, classBatchName }),
//     method: "POST",
//     isJsonPayload: true,
//     authRequired: true,
//   });
// };

export interface CourseEnrollmentInfo {
  classId: ExperienceId;
  classStartDate: string;
  classEndDate: string;
  classTitle: string;
  classBatchName: string;
}

export const chargeAndEnrollInClass = ({
  paymentInfo,
  courseEnrollmentInfo,
}: {
  paymentInfo: PaymentInfo;
  courseEnrollmentInfo: CourseEnrollmentInfo;
}): Promise<{
  message: string;
  status: ApiStatus;
  userInfo?: StudioUser;
}> => {
  const data = new FormData();
  data.append("paymentInfo", JSON.stringify(paymentInfo));
  data.append("courseEnrollmentInfo", JSON.stringify(courseEnrollmentInfo));

  return fetchApi({
    path: "/charge",
    body: data,
    method: "POST",
    authRequired: true,
  });
};

export const enrollUserInChallenge = async (courseEnrollmentInfo: {
  classId: ExperienceId;
  classBatchName: string;
  classStartDate: string;
  classEndDate: string;
  friendCode?: string;
}): Promise<{
  status: ApiStatus;
  userInfo: StudioUser;
  message?: string;
}> => {
  const data = new FormData();
  data.append("courseEnrollmentInfo", JSON.stringify(courseEnrollmentInfo));
  return await fetchApi({
    path: "/enroll_in_challenge",
    method: "POST",
    body: data,
    authRequired: true,
  });
};

export const enrollInClass = async ({
  classId,
  classBatchName,
  friendUsername,
  campaignId,
}: {
  classId: ExperienceId;
  classBatchName: string;
  friendUsername?: string;
  campaignId?: string;
}): Promise<{ status: ApiStatus; enrollment?: Enrollment }> => {
  return await fetchApi({
    path: "/v2/enroll",
    body: JSON.stringify({ classId, classBatchName, friendUsername, campaignId }),
    method: "POST",
    authRequired: true,
    isJsonPayload: true,
  });
};

export const getStudioCreditForUser = (): Promise<any> => {
  // console.log("hey here about to get credit for user ");

  return fetchApi({
    path: "/getMonthlyCreditForUser",
    method: "GET",
    isJsonPayload: true,
    authRequired: true,
  });
};

export const updateCart = (products: PurchaseableItem[]): Promise<any> => {
  const updateCartInfo = {
    product_ids: products.map((product) => product.id),
    offer_valid: localStorage.getItem("noPriceOffer") ? false : true,
  };
  return fetchApi({
    path: "/updateCart",
    method: "POST",
    body: JSON.stringify(updateCartInfo),
    isJsonPayload: true,
    authRequired: true,
  });
};

interface UserCartData {
  lastUpdated?: Date;
  productsInCart?: PurchaseableItem[];
}

export const getCartForUser = async (): Promise<UserCartData> => {
  const response = await fetchApi({
    path: "/getCart",
    method: "GET",
    isJsonPayload: true,
    authRequired: true,
  });

  const lastUpdated = response.cart.lastUpdated ? new Date(response.cart.lastUpdated) : undefined;
  const productsInCart = response.cart?.productsInCart;

  return {
    lastUpdated: lastUpdated,
    productsInCart: productsInCart,
  };
};

export const getRecommendations = async (
  products: PurchaseableItem[],
  products_to_exclude: PurchaseableItem[] = [],
  useAllPurchases = false,
  userId?: string,
): Promise<ProductData[]> => {
  // console.log("ZZZ: products: ", products);
  let requestBody: Object = {
    product_ids: products.map((product) => product.id),
    exclude_product_ids: products_to_exclude.map((product) => product.id),
    use_all_purchases: useAllPurchases,
  };
  if (userId) {
    requestBody = { ...requestBody, user_id: userId };
  }
  const response = await fetchApi({
    path: "/getRecommendations",
    body: JSON.stringify(requestBody),
    method: "POST",
    isJsonPayload: true,
  });

  if (response.recommendations && response.recommendations.length > 0) {
    return response.recommendations;
  }
  return [];
};
