import { useQueryState } from "~/components/browse/hooks/useQueryState";

import * as styles from "./HeroContent.css";

export const HeroContent = ({
  title,
  description,
  browseCategories = [],
}: {
  title: string;
  description: string;
  onCtaClick: () => void;
  ctaText: string;
  pricingText: string;
  browseCategories?: string[];
}): React.ReactElement => {
  const { state, setQueryParams } = useQueryState({
    isHomepage: true,
  });
  return (
    <div className={styles.contentContainer}>
      <h1 className={styles.title}>{title}</h1>
      <p className={styles.description} dangerouslySetInnerHTML={{ __html: description }}></p>
      <div className={styles.categoryBtnWrap}>
        {browseCategories.map((category, index) => (
          <div
            className={styles.categoryBtn}
            key={`cat_${index}`}
            onClick={() => {
              setQueryParams({
                ...state,
                view: "",
                category: category,
              });
            }}
          >
            {category}
          </div>
        ))}
      </div>
    </div>
  );
};
