// initialized in pages/_document
const TIKTOK_PIXEL_WEB_1_0_ID = process.env.NEXT_PUBLIC_TIKTOK_PIXEL_ID;

export enum TiktokPixelEvents {
  COMPLETE_PAYMENT = "CompletePayment",
  COMPLETE_REGISTRATION = "CompleteRegistration",
  VIEW_CONTENT = "ViewContent",
}

export const trackTiktokCreateAccount = (): void => {
  const TIKTOK_PIXEL_CLIENT = (window as any)?.ttq;

  if (TIKTOK_PIXEL_CLIENT) {
    TIKTOK_PIXEL_CLIENT.instance(TIKTOK_PIXEL_WEB_1_0_ID).track(
      TiktokPixelEvents.COMPLETE_REGISTRATION.valueOf(),
    );
  }
};
