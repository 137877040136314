import React, { createContext, useContext, useEffect, useState } from "react";

import { Console } from "console";

import io, { Socket } from "socket.io-client";

// Define the context
interface SocketContextType {
  socket: Socket | null;
  connect: () => void;
  disconnect: () => void;
  sendMessage: (messageObject: MessageObject) => void;
}

const SocketContext = createContext<SocketContextType | undefined>(undefined);

// Define props for the provider component
interface SocketProviderProps {
  children: React.ReactNode;
}

export interface MessageObject {
  content: string;
  userId: string;
  role: string;
  conversationId: string;
}

export interface ConversationObject {
  conversationId: string;
  userId: string;
  title: string;
  metadata?: any;
}

// Define a provider component
export const SocketProvider: React.FC<SocketProviderProps> = ({ children }) => {
  const [socket, setSocket] = useState<Socket | null>(null);
  const API_ENDPOINT = process.env.NEXT_PUBLIC_ENV_API;
  const connect = () => {
    console.log("attempting to CONNECT socket");
    if (!socket) {
      const newSocket = io(API_ENDPOINT || "");
      setSocket(newSocket);

      newSocket.on("connect", function () {
        console.log("SOCKET Connected to server");
      });
    }
  };

  const disconnect = () => {
    console.log("attempting to disconnect socket");
    if (socket) {
      socket.disconnect();
      setSocket(null);
    }
  };

  const sendMessage = (messageObject: MessageObject) => {
    console.log("sending message ? ", socket);

    if (!socket || !socket.connected) {
      connect();
    }
    if (socket) {
      console.log("actually emitting ", messageObject);
      socket.emit("message", messageObject);
    }
  };

  useEffect(() => {
    // return () => {
    //   disconnect();
    // };
  }, []);

  return (
    <SocketContext.Provider value={{ socket, connect, disconnect, sendMessage }}>
      {children}
    </SocketContext.Provider>
  );
};

// Custom hook to access the socket context
export const useSocket = () => {
  const context = useContext(SocketContext);
  if (context === undefined) {
    throw new Error("useSocket must be used within a SocketProvider");
  }
  return context;
};
