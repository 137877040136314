import ReactGA from "react-ga4";

export const initializeGA = () => {
  const GA_MEASUREMENT_ID = process.env.NEXT_PUBLIC_GOOGLE_ANALYTICS_MEASUREMENT_ID;
  if (!ReactGA.isInitialized) {
    ReactGA.initialize([{ trackingId: GA_MEASUREMENT_ID }]);
  }
  // Old GA.
  //ReactGA.initialize(process.env.NEXT_PUBLIC_GOOGLE_ANALYTICS_ID);
  //ReactGA.pageview(window.location.pathname + window.location.search);
};

export const trackGACreateUser = () => {
  ReactGA.event({
    category: "User",
    action: "Created an Account",
  });
};

interface GAPurchaseTrackingProps {
  purchaseType: string;
  itemLabel: string;
  valueInDollars: number;
}
export const trackGAPurchase = ({
  purchaseType,
  itemLabel,
  valueInDollars,
}: GAPurchaseTrackingProps) => {
  // Google Analytics.
  // Tracks value in dollars (not cents).
  // console.log("REACTGA EVENT", purchaseType, itemLabel, valueInDollars);
  ReactGA.event({
    category: "User",
    action: purchaseType,
    label: itemLabel,
    value: valueInDollars,
  });
};

export const trackGACollectEmail = (email: string, trackingEvent: string) => {
  const WINDOW = window as any; // Needed for typescript to allow setting the enhanced_conversion_data on window
  if (WINDOW !== undefined) {
    const GA_CLIENT = WINDOW.gtag;
    WINDOW.enhanced_conversion_data = {
      email,
    };

    if (GA_CLIENT != null) {
      GA_CLIENT("event", "conversion", { send_to: trackingEvent });
    }
  }
};
