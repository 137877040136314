import React, { useEffect, useRef, KeyboardEvent } from "react";

import classNames from "classnames";

import { CloseIcon } from "studio-design-system";

import { ModalPreviousIcon } from "~/components/common/Icons/ModalPreviousIcon";
import { NextGalleryIcon, PreviousGalleryIcon } from "~/components/school/common/Icons";
import { usePreventBodyScroll } from "~/hooks/usePreventBodyScroll";

import * as styles from "./OverlayModal.css";

interface OverlayModalProps {
  isOpen: boolean;
  onClose: () => void;
  onPrevious?: () => void;
  children?: JSX.Element;
  dataTestId?: string;
  containerClass?: string;
  closeButtonClass?: string;
  closeButtonColor?: string;
  overlayClass?: string;
  contentClass?: string;
  contentContainerClass?: string;
  hideOuterScroll?: boolean;
  slideUpAnimation?: boolean;
  isBlockedUntilSubmit?: boolean;
  preserveOnOutsideClick?: boolean;
  outsideHeaderTitle?: string;
  onGalleryNext?: () => void;
  onGalleryPrevious?: () => void;
}

export const OverlayModal = ({
  isOpen,
  onClose,
  onPrevious,
  children,
  dataTestId = "",
  contentClass,
  overlayClass,
  closeButtonClass = "",
  closeButtonColor = "#757575",
  containerClass,
  contentContainerClass,
  hideOuterScroll = false,
  slideUpAnimation = false,
  isBlockedUntilSubmit = false,
  preserveOnOutsideClick = false,
  outsideHeaderTitle = "",
  onGalleryNext,
  onGalleryPrevious,
}: OverlayModalProps): React.ReactElement => {
  usePreventBodyScroll({ isOpen, hideOuterScroll });
  const contentRef = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    if (isOpen) {
      const container = contentRef.current;
      container?.focus();

      if (slideUpAnimation) {
        container?.classList.add("slideUp");
      }

      return () => {
        container?.classList.remove("slideUp");
      };
    }
  }, [isOpen, slideUpAnimation]);

  const onKeyDownEsc = (e: KeyboardEvent) => {
    if (e.key === "Escape") {
      e.preventDefault();
      onClose();
    }
  };

  return (
    <div
      style={{ visibility: isOpen ? "visible" : "hidden" }}
      className={classNames(styles.container, containerClass)}
      aria-hidden={!isOpen}
      data-testid={dataTestId}
    >
      {slideUpAnimation && <style>{styles.slideUpStyle}</style>}
      <div
        className={classNames(styles.backgroundOverlay, overlayClass)}
        onClick={isBlockedUntilSubmit || preserveOnOutsideClick ? undefined : onClose}
      />

      <div className={classNames(styles.contentContainer, contentContainerClass)}>
        {outsideHeaderTitle && (
          <div>
            <h1 className={styles.outsideHeaderTitle}>{outsideHeaderTitle} </h1>
          </div>
        )}

        <div
          className={classNames(styles.modalContainer, contentClass)}
          tabIndex={0}
          ref={contentRef}
          onKeyDown={onKeyDownEsc}
          style={{
            visibility: isOpen ? "visible" : "hidden",
            opacity: isOpen ? "1" : "0", // Set Opacity to 0 right away, to avoid a flicker of some the children staying in screen while the hide transition finishes
          }}
        >
          {onGalleryPrevious && (
            <button
              onClick={onGalleryPrevious}
              aria-label="Previous gallery"
              className={styles.galleryPrevious}
            >
              <PreviousGalleryIcon />
            </button>
          )}

          {onGalleryNext && (
            <button
              onClick={onGalleryNext}
              aria-label="Next gallery"
              className={styles.galleryNext}
            >
              <NextGalleryIcon />
            </button>
          )}

          {onPrevious && (
            <button
              onClick={onPrevious}
              aria-label="Previous pop-up"
              className={styles.previousStep}
            >
              <ModalPreviousIcon />
            </button>
          )}

          {!isBlockedUntilSubmit && (
            <button
              onClick={onClose}
              aria-label="Close pop-up"
              className={classNames(styles.closeButton, closeButtonClass)}
            >
              <CloseIcon fill={closeButtonColor} width={17} height={18} />
            </button>
          )}
          {children}
        </div>
      </div>
    </div>
  );
};
