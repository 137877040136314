// pre-enrollment
export const SF_MUSIC_LANDING_PAGE = "music landing page | page visit";

// checkout
export const SF_CHECKOUT_PAGE_VISIT = "checkout | page visit";
export const SF_CHECKOUT_PAYMENT_SUBMIT_SUCCESS = "checkout | payment submit success";

// welcome
export const SF_MUSIC_INTRODUCTION_PAGE_VISIT = "music welcome | page visit";
export const SF_CURRICULUM_OVERVIEW_MODAL_VIEWED = "curriculum overview | modal viewed";
export const SF_CURRICULUM_PREVIEW_CONFIRM_CLICKED = "curriculum preview | confirm clicked";

// experience
export const SF_MUSIC_LIBRARY_PAGE_VISIT = "music library | page visit";
export const SF_DASHBOARD_PAGE_VISIT = "music dashboard | page visit";
export const SF_HOME_PAGE_VISIT = "music home | page visit";
export const SF_CURRICULUM_PAGE_VISIT = "music curriculum | page visit";
export const SF_PEER_FEED_PAGE_VISIT = "music peer feed | page visit";
export const SF_PROFILE_PAGE_VISIT = "music profile | page visit";
export const SF_COACH_PAGE_VISIT = "music coach | page visit";

// curriculum
export const SF_CURRICULUM_LESSON_PAGE_VISIT = "curriculum lesson | page visit";
export const SF_CURRICULUM_VOCAL_PAGE_VISIT = "curriculum vocal | page visit";
export const SF_CURRICULUM_LESSON_VIDEO_STARTED = "curriculum lesson | video started";
export const SF_CURRICULUM_LESSON_VIDEO_COMPLETED = "curriculum lesson | video completed";
export const SF_CURRICULUM_SUPPLEMENT_PAGE_VISIT = "curriculum supplement | page visit";
export const SF_CURRICULUM_PROJECT_PAGE_VISIT = "curriculum project | project viewed";
export const SF_CURRICULUM_PROJECT_SUBMITTED = "curriculum project | project submitted";
