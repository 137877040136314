import { useEffect, useRef, useState } from "react";



import ReactMarkdown from "react-markdown";
import { PluggableList } from "react-markdown/lib/react-markdown";
import rehypeRaw from "rehype-raw";

import { AnimatedSpinner, CloseIcon } from "studio-design-system";

import { getCurriculumSummaryForUser } from "~/api/aiCoachApi";
import { UserWithConfirmedAttendance } from "~/api/sfSessionEnroll";
import { OverlayModal } from "~/components/common/OverlayModal";
import { useCurrentSession } from "~/hooks/currentSessionProvider/useCurrentSession";
import { useIsMobileScreen } from "~/hooks/ui/useIsMobileScreen";

import { RichInstructorDescription } from "../../common/CurriculumModule";
import { CurriculumIcon } from "../../common/Icons";
import * as styles from "./CurriculumOverviewModal.css";

export const CurriculumOverviewModal = ({
  isOpen,
  peer,
  onClose,
}: {
  isOpen: boolean;
  peer?: UserWithConfirmedAttendance;
  onClose: () => void;
}) => {
  const { currentSession } = useCurrentSession();
  const [overviewState, setOverviewState] = useState<any>({});

  useEffect(() => {
    if (!peer || !peer.userId) {
      return;
    }

    getCurriculumSummaryForUser(peer.userId, currentSession?.sessionSlug).then((response) => {
      console.log("getCurriculumOverviewForUser response ", response.response);
      setOverviewState(response.response);
    });
  }, [peer]);

  const ref = useRef<HTMLDivElement>(null);
  const isMobile = useIsMobileScreen();

  return (
    <OverlayModal contentClass={styles.modalContent} isOpen={isOpen} onClose={onClose}>
      {peer && (
        <div className={styles.modalContentContainer} ref={ref}>
          <div className={styles.modalTitleContainer}>
            <div className={styles.titleContent}>
              <CurriculumIcon />
              <div className={styles.titlesContainer}>
                <h2 className={styles.modalTitle}>
                  {`${peer.firstName} ${peer.lastName}'s Curriculum`}
                </h2>
                <h3 className={styles.modalSubtitle}>
                  {isMobile ? "" : "- "}
                  {currentSession?.title}
                </h3>
              </div>
            </div>
            <button className={styles.closeButton} onClick={onClose}>
              <CloseIcon width={24} height={24} />
            </button>
          </div>
          {overviewState ? (
            <div className={styles.scrollContainer}>
              <RichInstructorDescription
                instructorInfoMap={overviewState.instructorInfoMap}
                text={overviewState.message}
                parentRef={ref}
              />
              <div className={styles.containerPaddingLeft}>
                {overviewState?.yourInstructors && (
                  <>
                    <div className={styles.dividerLine} />
                    <h3 className={styles.sectionTitle}>Instructors</h3>
                    <RichInstructorDescription
                      shouldShowIcon={false}
                      instructorInfoMap={overviewState.instructorInfoMap}
                      text={overviewState.yourInstructors}
                      parentRef={ref}
                    />
                  </>
                )}

                {overviewState?.focusAreas && (
                  <>
                    <div className={styles.dividerLine} />
                    <h3 className={styles.sectionTitle}>Focus areas</h3>
                    <p className={styles.markdownContent}>{overviewState.focusAreas.headline}</p>
                    <div className={styles.areasContainer}>
                      {overviewState.focusAreas.areas.map(
                        (
                          item: {
                            title: string;
                            description: string;
                          },
                          index: number,
                        ) => (
                          <div key={index} className={styles.area}>
                            <RichInstructorDescription
                              appendsAtStart={
                                <h3 className={styles.areaTitle}>
                                  {index + 1}. {item.title}
                                </h3>
                              }
                              shouldShowIcon={false}
                              instructorInfoMap={overviewState.instructorInfoMap}
                              text={item.description}
                              parentRef={ref}
                            />
                          </div>
                        ),
                      )}
                    </div>
                  </>
                )}

                {overviewState?.schedule && (
                  <>
                    <div className={styles.dividerLine} />
                    <h3 className={styles.sectionTitle}>Schedule</h3>
                    <ReactMarkdown
                      rehypePlugins={[rehypeRaw as PluggableList[0]]}
                      components={{
                        p: ({ node, ...props }) => (
                          <p {...props} className={styles.markdownContent} />
                        ),
                        li: ({ node, ...props }) => (
                          <li {...props} className={styles.markdownContent} />
                        ),
                      }}
                    >
                      {overviewState.schedule}
                    </ReactMarkdown>
                  </>
                )}

                {overviewState?.matchingSummary && (
                  <>
                    <div className={styles.dividerLine} />
                    <h3 className={styles.sectionTitle}>Peer Group Matching</h3>
                    <RichInstructorDescription
                      shouldShowIcon={false}
                      instructorInfoMap={overviewState.instructorInfoMap}
                      text={overviewState.matchingSummary}
                      parentRef={ref}
                    />
                  </>
                )}
              </div>
            </div>
          ) : (
            <div className={styles.loadingContainer}>
              <AnimatedSpinner className={styles.animatedSpinner} />
            </div>
          )}
        </div>
      )}
    </OverlayModal>
  );
};