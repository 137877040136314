import { FeaturedMedia } from "../hooks/currentSessionProvider/CurrentSessionProvider";
import { fetchApi } from "./fetchStudioApi";
import { UserWithConfirmedAttendance } from "./sfSessionEnroll";

export interface Reaction {
  reaction: string;
  users: {
    firstName: string;
    lastName: string;
    userId: string;
  }[];
}

export interface Post {
  id: string;
  curriculumItemId: string;
  dueDate: string;
  peerGroupId: string;
  youtubeLink?: string;
  user: UserWithConfirmedAttendance;
  title: string;
  description: string;
  createdAt: string;
  updatedAt: string;
  numComments: number;
  next3CommenterProfilePhotoURLs: string[];
  reactions: Reaction[];
  lastEvaluatedKey?: {
    id: string;
    createdAt: string;
    postId: string;
  };
  comments: {
    id: string;
    postId: string;
    user: UserWithConfirmedAttendance;
    comment: string;
    likeCount: number;
    createdAt: string;
    reactions: Reaction[];
    isEditable?: boolean;
  }[];
  curriculumItemTeaser?: {
    id: string;
    title: string;
  };
  isFinalProject: boolean;
  media: {
    id: string;
    type: string;
    url: string;
    metadata?: FeaturedMedia["metadata"] | null;
  }[];
  color?: string;
}

export const getUsersForUserPeerGroupWithSessionSlug = async (
  sessionSlug?: string,
): Promise<UserWithConfirmedAttendance[]> => {
  let urlPath = `/getUsersForUserPeerGroupWithSessionSlug`;

  if (sessionSlug) {
    urlPath += `?session_slug=${sessionSlug}`;
  }

  let response = await fetchApi({
    authRequired: true,
    path: urlPath,
    method: "GET",
  });
  return response.response;
};

export const pullPostPage = async (
  sessionSlug?: string,
  lastEvaluatedKey?: Post,
): Promise<Post[]> => {
  let urlPath = `/pullPostPage`;

  if (sessionSlug) {
    urlPath += `?session_slug=${sessionSlug}`;
  }

  if (lastEvaluatedKey) {
    urlPath += `&lastEvaluatedKey=${encodeURIComponent(
      JSON.stringify({
        id: lastEvaluatedKey.id,
        peerGroupId: lastEvaluatedKey.peerGroupId,
        updatedAt: lastEvaluatedKey.updatedAt,
      }),
    )}`;
  }

  let response = await fetchApi({
    authRequired: true,
    path: urlPath,
    method: "GET",
  });
  return response.response;
};

export const addReaction = async (reaction: string) => {
  const requestBody = JSON.stringify({
    reaction: reaction,
  });

  console.log(`ADDING REACTION ${reaction}`);

  let response = await fetchApi({
    authRequired: true,
    path: `/addReaction`,
    body: requestBody,
    method: "POST",
    isJsonPayload: true,
  });
  return response;
};

export const removeReaction = async (reaction: string) => {
  const requestBody = JSON.stringify({
    reaction: reaction,
  });

  let response = await fetchApi({
    authRequired: true,
    path: `/removeReaction`,
    body: requestBody,
    method: "POST",
    isJsonPayload: true,
  });
  return response;
};

export const addComment = async (
  postId: string,
  comment: string,
  commentId: string,
  isEdit?: boolean,
) => {
  const requestBody = JSON.stringify({
    postId: postId,
    comment: comment,
    commentId: commentId,
    ...(isEdit && { isEdit: isEdit }),
  });

  let response = await fetchApi({
    authRequired: true,
    path: `/addComment`,
    body: requestBody,
    method: "POST",
    isJsonPayload: true,
  });

  console.log("GOT RESPONSE for comment", response);
  return response;
};

export const pullComments = async (postId: string, lastKey?: string): Promise<Post["comments"]> => {
  let urlPath = `/pullComments?post_id=${postId}`;

  if (lastKey) {
    urlPath += `&last_evaluated_key=${lastKey}`;
  }

  let response = await fetchApi({
    authRequired: true,
    path: urlPath,
    method: "GET",
  });
  return response.response;
};

export const deleteSFComment = async (comment_id: string) => {
  const requestBody = JSON.stringify({
    comment_id,
  });

  let response = await fetchApi({
    authRequired: true,
    path: `/deleteSFComment`,
    body: requestBody,
    method: "POST",
    isJsonPayload: true,
  });
  return response;
};

// class FlagContentRequest(pydantic.BaseModel):
//     flagged_user_id: str
//     content_id: str
//     content_type: str
//     reason: str
//     other_message: Optional[str] = None

export const reportPost = async (post: Post, reason: string, otherMessage?: string) => {
  const requestBody = JSON.stringify({
    flaggedUserId: post.user.userId,
    contentId: post.id,
    contentType: "post",
    reason: reason,
    ...(otherMessage && { otherMessage: otherMessage }),
  });

  let response = await fetchApi({
    authRequired: true,
    path: `/moderation/json`,
    body: requestBody,
    method: "POST",
    isJsonPayload: true,
  });

  return response;
};
