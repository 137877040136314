import React, { useCallback, useEffect, useMemo, useRef, useState } from "react";

import { useRouter } from "next/router";

import { CollectionData } from "data/collections";
import { ProductData, PurchaseableItem } from "data/product/products";

import {
  CART_PAGE_ITEM_DELETED,
  CART_EXPERIMENT_VIEWED,
  CART_PAGE_ITEM_ADDED,
  CART_PAGE_ALL_ITEMS_ADDED,
} from "~/analytics/Amplitude/amplitudeConstants";
import { addUTMPropertiesToEvent } from "~/analytics/Amplitude/amplitudeUtils";
import { useAnalytics } from "~/analytics/useAnalytics";
import { getCartForUser, updateCart } from "~/api/checkoutApi";
import { getStudioCreditForUser } from "~/api/checkoutApi";
import { useAuth } from "~/hooks/auth/useAuth";
import { usePriceOfferInfoCheckout } from "~/hooks/usePriceOfferInfo";

interface CartProps {
  children: React.ReactNode;
}

interface CartState {
  cartItems: PurchaseableItem[];
  buyNowProduct?: PurchaseableItem;
  addToCart: (product: PurchaseableItem) => void;
  addToCartAndRemoveAnythingElse: (product: PurchaseableItem) => void;
  addAllToCart: (products: PurchaseableItem[]) => void;
  removeFromCart: (id: string) => void;
  emptyCart: (callbackFunc?: () => void) => void;
  setBuyNowProduct: (product: PurchaseableItem | undefined) => void;
  isItemInCart: (product: PurchaseableItem) => boolean;
  getCartPathWithQuery: () => string;
  studioCreditAppliedToCart: number;
}

export const CartContext = React.createContext<CartState>({
  cartItems: [],
  buyNowProduct: undefined,
  addToCart: async () => ({ type: "error", error: new Error("not initialized") }),
  addToCartAndRemoveAnythingElse: async () => ({ type: "error", error: new Error("not initialized") }),
  addAllToCart: async () => ({ type: "error", error: new Error("not initialized") }),
  removeFromCart: async () => ({ type: "error", error: new Error("not initialized") }),
  emptyCart: async (callbackFunc?: () => void) => ({ type: "error", error: new Error("not initialized") }),
  setBuyNowProduct: async (product) => ({ type: "error", error: new Error("not initialized") }),
  isItemInCart: (product: ProductData | CollectionData | undefined) => false,
  getCartPathWithQuery: () => "/cart",
  studioCreditAppliedToCart: 0,
});

export const CartProvider = ({ children }: CartProps) => {
  const { trackAmplitudeEvent } = useAnalytics();
  const [cartItems, setCartItems] = React.useState<PurchaseableItem[]>([]);
  const [prevNumCartItems, setPrevNumCartItems] = React.useState<number>(0);
  const [buyNowProduct, setBuyNow] = React.useState<PurchaseableItem | undefined>(undefined);
  const { user, loadingCurrentUser } = useAuth();
  const [hasLoggedIn, setHasLoggedIn] = useState(false);
  const [fetchedBackendCart, setFetchedBackendCart] = useState(false);
  const [hasSetCookiesCart, setHasSetCookiesCart] = useState(false);
  const [studioCreditAppliedToCart, setStudioCreditAppliedToCart] = React.useState<number>(0);
  const { shouldUserSeeOfferCalculation, getProductPrice } = usePriceOfferInfoCheckout();
  const [studioCreditsAvailable, setStudioCreditsAvailable] = useState(-1);
  const [hasLoadedCart, setHasLoadedCart] = useState(false);
  const router = useRouter();
  // Add a new state to track when to trigger the callback
  const [shouldCallEmptyCartCallback, setShouldCallEmptyCartCallback] = useState(false);

  const getAndSetStudioCredit = async () => {
    let studioCreditsAvailable = 0;
    const hasSubscriptionItem = doesCartContainSubscriptionItem();
    const result = await getStudioCreditForUser();
    if (result) {
      const monthlyCreditInfo = result.monthlyCreditInfo;
      console.log("in here credit info: ", monthlyCreditInfo);
      if (monthlyCreditInfo) {
        studioCreditsAvailable = monthlyCreditInfo["monthlyCredit"];
        studioCreditsAvailable = studioCreditsAvailable * 100;
      }
    }
    setStudioCreditsAvailable(studioCreditsAvailable);
  };

  const getCartPathWithQuery = () => {
    const queryParams = router.query;
    // Convert query parameters to a string
    const queryString = Object.keys(queryParams)
      .map((key) => `${key}=${queryParams[key]}`)
      .join("&");

    const cartPathWithQuery = `/cart?${queryString}`;
    return cartPathWithQuery;
  };

  useEffect(() => {
    trackAmplitudeEvent(CART_EXPERIMENT_VIEWED, {
      ...addUTMPropertiesToEvent(),
      variant: "control",
      experimentName: "shopping cart",
    });
    console.log("LOGGING CART provider ", loadingCurrentUser, user);

    // also check that there isn't a subscription item in the cart
    if (hasLoadedCart && studioCreditsAvailable === -1 && !loadingCurrentUser) {
      getAndSetStudioCredit();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loadingCurrentUser, hasLoadedCart]);

  useEffect(() => {
    const totalProductPrice = cartItems.reduce((acc, item) => {
      const shouldUserSeeOffer = shouldUserSeeOfferCalculation(item.priceInfo);
      const isPurchaseableItemAlwaysOnSale = "availableUntil" in item;
      const shouldHaveOffer = shouldUserSeeOffer;
      return acc + getProductPrice(item.priceInfo, shouldHaveOffer);
    }, 0);
    const studioCreditsAppliedToPurchase =
      totalProductPrice - studioCreditsAvailable < 0 ? totalProductPrice : studioCreditsAvailable;
    setStudioCreditAppliedToCart(studioCreditsAppliedToPurchase);
  }, [cartItems, getProductPrice, shouldUserSeeOfferCalculation, studioCreditsAvailable]);

  // Keep track of when user has logged in and clear cart when they log out.
  useEffect(() => {
    if (user && !loadingCurrentUser) {
      setHasLoggedIn(true);
    }
    if (!user && hasLoggedIn) {
      setFetchedBackendCart(false);
      setHasLoggedIn(false);
      console.log("REMOVING CART ITEMS");
      localStorage.removeItem("studio_cartitems");
    }
    return () => { };
  }, [user, loadingCurrentUser, hasLoggedIn]);

  const refreshItemPrices = () => {
    let collectionsInCart = cartItems.filter(
      (item) => "productsInfoIncluded" in item,
    ) as CollectionData[];

    let itemsIdsInCollections: string[] = [];
    for (let collection of collectionsInCart) {
      console.log(
        "going through collection info ",
        collection.productsInfoIncluded.length,
        collection,
      );
      itemsIdsInCollections.concat(collection.productsIncluded);
    }

    let newCartItems: PurchaseableItem[] = [];
    console.log("THE ITEM IDS IN COLLECTIONS ARE ", itemsIdsInCollections);
    for (let item of cartItems) {
      if (item.id in itemsIdsInCollections) {
        console.log("ITEM IS IN THE COLLECTION ALREADY! ");
        //item.priceInfo.price = 0;
      } else {
        console.log("ITEM IS NOT IN THE COLLECTION! ");
        newCartItems.push(item);
      }
    }

    setCartItems(newCartItems);
  };

  // Keep backend in sync when you update cartItems, but only once you've fetched backend cart once.
  useEffect(() => {
    // console.log("cartItems changed ", cartItems);

    // if (prevNumCartItems !== cartItems.length) {
    //   refreshItemPrices();
    // }
    // setPrevNumCartItems(cartItems.length);
    if (hasSetCookiesCart) {
      // console.log("setting cart items ! ", cartItems);
      localStorage.setItem("studio_cartitems", JSON.stringify(cartItems));
    }

    setTimeout(() => {
      // console.log("now checking ", loadingCurrentUser, fetchedBackendCart, user);
      if (user && !loadingCurrentUser && fetchedBackendCart) {
        // console.log("now updating cart items ", cartItems);
        updateCart(cartItems);
      }
    }, 1500);

    return () => { };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cartItems]);

  // Initialize the cart correctly from frontend or backend
  useEffect(() => {
    const cookiesCart = localStorage.getItem("studio_cartitems");
    const hasCookiesCart = cookiesCart && cookiesCart != null && cookiesCart.length > 0;
    const cookiesCartProducts: PurchaseableItem[] = hasCookiesCart
      ? JSON.parse(cookiesCart || "")
      : [];

    if (user && !loadingCurrentUser) {
      getCartForUser().then((userCartData) => {
        setFetchedBackendCart(true);
        const backendCart = userCartData.productsInCart;

        if (!backendCart && hasCookiesCart) {
          setCartItems(cookiesCartProducts);
        } else if (!backendCart && !hasCookiesCart) {
          setCartItems([]);
        } else if (backendCart && !hasCookiesCart) {
          setCartItems(backendCart);
        } else if (backendCart && hasCookiesCart) {
          const backendIds = new Set(backendCart.map((product) => product.id));
          setCartItems(
            cookiesCartProducts
              .filter((product) => !backendIds.has(product.id))
              .concat(backendCart),
          );
        }
      });
    } else {
      setCartItems(cookiesCartProducts);
    }
    setHasSetCookiesCart(true);

    setHasLoadedCart(true);
    return () => { };
  }, [user, loadingCurrentUser, setCartItems]);

  const addToCart = useCallback(
    async (product: PurchaseableItem) => {
      // remove any subscription items if we're adding another regular purcheasable item
      setCartItems([...cartItems.filter((x) => x.id !== product.id && !isSubscriptionItem(x)), product]);

      trackAmplitudeEvent(CART_PAGE_ITEM_ADDED, {
        ...addUTMPropertiesToEvent(),
        productId: product.id,
        productTitle: product.title,
        creatorName: product.creatorName,
      });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [cartItems, setCartItems],
  );

  const isSubscriptionItem = (product: PurchaseableItem) => {
    return (product.subscriptionSlug && product.subscriptionSlug.length > 0);
  };

  const doesCartContainSubscriptionItem = () => {
    console.log("CHECKING CART ITEMS ", cartItems);
    return cartItems.some(isSubscriptionItem);
  };

  const addToCartAndRemoveAnythingElse = useCallback(
    async (product: PurchaseableItem) => {
      setCartItems([product]);
      console.log("added to cart and removed anything else ", product);
      trackAmplitudeEvent(CART_PAGE_ITEM_ADDED, {
        ...addUTMPropertiesToEvent(),
        productId: product.id,
        productTitle: product.title,
        creatorName: product.creatorName,
      });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [cartItems, setCartItems],
  );

  const addAllToCart = useCallback(
    async (products: PurchaseableItem[]) => {
      setCartItems([...cartItems, ...products]);

      trackAmplitudeEvent(CART_PAGE_ALL_ITEMS_ADDED, {
        ...addUTMPropertiesToEvent(),
      });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [cartItems, setCartItems],
  );

  const removeFromCart = useCallback(
    async (id: string) => {
      setCartItems(cartItems.filter((x) => x.id !== id));
      trackAmplitudeEvent(CART_PAGE_ITEM_DELETED, {
        ...addUTMPropertiesToEvent(),
      });
    },
    [cartItems, trackAmplitudeEvent],
  );
  const callbackFuncRef = useRef<(() => void) | null>(null);

  const emptyCart = useCallback(async (callbackFunc?: () => void) => {
    console.log("EMPTYING CART ! ");
    setCartItems([]);
    callbackFuncRef.current = callbackFunc ?? null; // Update the ref to the current callback
    setShouldCallEmptyCartCallback(true);
    console.log("CART ITEMS SHOULD NOW BE ? ", cartItems);
  }, [setCartItems]);

  // Use useEffect to watch for changes in shouldCallEmptyCartCallback
  useEffect(() => {
    if (shouldCallEmptyCartCallback) {
      // Call the current callback function if it exists
      if (callbackFuncRef.current) {
        callbackFuncRef.current();
        console.log("calling the call back func! ");
        setShouldCallEmptyCartCallback(false); // Reset the flag after calling the callback
      }
    }
  }, [shouldCallEmptyCartCallback]);

  const setBuyNowProduct = useCallback(
    async (product: PurchaseableItem | undefined) => {
      setBuyNow(product);
    },
    [setBuyNow],
  );

  const isItemInCart = (product: PurchaseableItem) => {
    return cartItems.filter((prod) => prod.id === product.id).length > 0;
  };

  const contextValue = useMemo(
    () => ({
      cartItems,
      buyNowProduct,
      addToCart,
      addToCartAndRemoveAnythingElse,
      addAllToCart,
      removeFromCart,
      emptyCart,
      setBuyNowProduct,
      isItemInCart,
      getCartPathWithQuery,
      studioCreditAppliedToCart,
    }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [
      cartItems,
      buyNowProduct,
      addToCart,
      addToCartAndRemoveAnythingElse,
      addAllToCart,
      removeFromCart,
      emptyCart,
      setBuyNowProduct,
    ],
  );

  return <CartContext.Provider value={contextValue}>{children}</CartContext.Provider>;
};
