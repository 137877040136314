import { deletePost } from "~/api/sfCurriculumApi";
import { Post } from "~/api/sfPeersApi";
import { OverlayModal } from "~/components/common/OverlayModal";
import { Button } from "~/components/school/common/Button/Button";

import * as styles from "./DeletePostModal.css";

export interface SubmitProjectModalProps {
  post?: Post | null;
  isOpen: boolean;
  onClose: () => void;
  onConfirm?: () => void;
}

export const DeletePostModal = ({ post, isOpen, onClose, onConfirm }: SubmitProjectModalProps) => {
  const handleDelete = async () => {
    if (!post) return;
    await deletePost(post?.id).then(() => {
      onConfirm && onConfirm();
      onClose();
    });
  };
  
  return (
    <OverlayModal contentClass={styles.modalContent} isOpen={isOpen} onClose={onClose}>
      <div>
        <h3 className={styles.modalTitle}>Are you sure you want to delete this?</h3>
        <p className={styles.description}>
          You are about to remove your post which will delete your data from the Peer Group and Your
          Profile. This action cannot be undone. Are you sure you want to delete your post?
        </p>
        <div className={styles.buttonContainer}>
          <Button variant="tertiary" label="Yes, please" onClick={handleDelete} />
          <Button variant="tertiary" label="Don’t delete" onClick={onClose} />
        </div>
      </div>
    </OverlayModal>
  );
};
