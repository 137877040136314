import React, { useState, useEffect, createContext, useMemo, useCallback, useRef } from "react";

import { useRouter } from "next/router";

import { SF_HOME_PAGE_VISIT } from "~/analytics/Posthog/events";
import { usePostHogAnalytics } from "~/analytics/Posthog/usePostHogAnalytics";
import {
  CurriculumModule,
  CurriculumItem,
  pullCurriculumForSessionAndUser,
  FeedbackItem,
  pullCurriculumModuleForToday,
  setCurriculumItemCompletedOn,
} from "~/api/sfCurriculumApi";
import { Post } from "~/api/sfPeersApi";
import { getUsersForUserPeerGroupWithSessionSlug, pullPostPage } from "~/api/sfPeersApi";
import {
  UserWithConfirmedAttendance,
  getUserWithConfirmedAttendance,
  pullSessionsForUser,
} from "~/api/sfSessionEnroll";
import { getGradientColor } from "~/components/school/utils";
import { useAdminUserId } from "~/hooks/admin/useAdminUserId";

import { useAuth } from "../auth/useAuth";

export interface FeaturedMedia {
  id: string;
  url: string;
  type: string;
  metadata?: {
    durationInSeconds?: string;
    waveform?: string;
    artURL?: string;
    title?: string;
  };
}

export interface UseCurrentSessionProps {
  children: React.ReactNode;
}

export interface Session {
  sessionSlug: string;
  title: string;
  startDate: string;
  endDate: string;
  school: string;
  featuredMedia: FeaturedMedia;
  featuredInstructors: {
    instructorSlug: string;
    instructorDisplayName: string;
    instructorPhotoURL: string;
    instructorShortBio: string;
  }[];
  preSessionChecklist: PreSessionChecklist;
  projects: {
    completedProjectPosts: Post[];
    totalProjectCount: number;
  };
}

export interface PreSessionChecklist {
  coachText: string;
  hasCurriculum: boolean;
  hasCompletedProfile: boolean;
  // hasDownloadedApp: boolean;
  hasReviewedTools: boolean;
  isLocked: boolean;
}
interface CurrentSessionState {
  isLoading: string | undefined;
  isPeerFeedLoading: boolean;
  hasUpdatedCurrentUser: boolean;
  userSessions: Session[] | undefined;
  setAllUserSessions: React.Dispatch<React.SetStateAction<Session[] | undefined>>;
  confirmedUser: UserWithConfirmedAttendance | undefined;
  setConfirmedUser: React.Dispatch<React.SetStateAction<UserWithConfirmedAttendance | undefined>>;
  setCurrentSession: React.Dispatch<React.SetStateAction<Session | undefined>>;
  currentSession: Session | undefined;
  currentCurriculum: CurriculumModule[] | undefined;
  setCurrentCurriculumItem: React.Dispatch<React.SetStateAction<CurriculumItem | undefined>>;
  currentCurriculumItem: CurriculumItem | undefined;
  currentCurriculumFeedbackItem: FeedbackItem;
  // refetchCurriculum: () => Promise<void>;
  setCurrentCurriculumItemCompletedOn: (isComplete: boolean) => void;
  setCurrentCurriculum: React.Dispatch<React.SetStateAction<CurriculumModule[] | undefined>>;
  setup: () => Promise<void>;
  initialConfirmedUser: UserWithConfirmedAttendance | undefined;
  moduleForToday?: CurriculumModule;
  peerFeedPosts: Post[];
  setPeerFeedPosts: React.Dispatch<React.SetStateAction<Post[]>>;
  usersForPeerGroup: UserWithConfirmedAttendance[];
}

export const CurrentSessionContext = createContext<CurrentSessionState>({
  isLoading: undefined,
  isPeerFeedLoading: true,
  hasUpdatedCurrentUser: false,
  userSessions: undefined,
  setAllUserSessions: () => {},
  confirmedUser: undefined,
  initialConfirmedUser: undefined,
  setCurrentSession: () => {},
  currentSession: undefined,
  currentCurriculum: undefined,
  setCurrentCurriculum: () => {},
  currentCurriculumItem: undefined,
  setCurrentCurriculumItem: () => {},
  setCurrentCurriculumItemCompletedOn: () => {},
  currentCurriculumFeedbackItem: {
    prevFeedbackId: null,
    nextFeedbackId: null,
    postId: "",
  },
  peerFeedPosts: [],
  setPeerFeedPosts: () => {},
  usersForPeerGroup: [],
  setConfirmedUser: () => {},
  setup: async () => {},
});

export const CurrentSessionProvider = ({ children }: UseCurrentSessionProps) => {
  const { query } = useRouter();
  const initialConfirmedUser = useRef<UserWithConfirmedAttendance>();

  const sessionSlug = query.sessionSlug as string;
  const curriculumId = query.curriculumId as string;
  const feedbackId = query.feedbackId as string;
  const [isLoading, setIsLoading] = useState<string | undefined>(); // sessionSlug or undefined

  const [confirmedUser, setConfirmedUser] = useState<UserWithConfirmedAttendance>();
  const [currentSession, setCurrentSession] = useState<Session | undefined>(undefined);
  const [userSessions, setAllUserSessions] = useState<Session[] | undefined>();
  const [currentCurriculum, setCurrentCurriculum] = useState<CurriculumModule[] | undefined>(
    undefined,
  );
  const [currentCurriculumItem, setCurrentCurriculumItem] = useState<CurriculumItem>();
  const [currentCurriculumFeedbackItem, setCurrentCurriculumFeedbackItem] = useState<FeedbackItem>({
    prevFeedbackId: null,
    nextFeedbackId: null,
    postId: "",
  });

  const [moduleForToday, setModuleForToday] = useState<CurriculumModule>();
  const [usersForPeerGroup, setUsersForPeerGroup] = useState<UserWithConfirmedAttendance[]>([]);
  const [peerFeedPosts, setPeerFeedPosts] = useState<Post[]>([]);
  const [isPeerFeedLoading, setIsPeerFeedLoading] = useState(true);
  const hasUpdatedCurrentUser =
    JSON.stringify(initialConfirmedUser.current) !== JSON.stringify(confirmedUser);
  const router = useRouter();
  const { adminUserIdToUse, adminUserToUse } = useAdminUserId();

  const clearCurrentSessionData = useCallback(() => {
    console.log("CLEARING CURRENT SESSION DATA");
    setConfirmedUser(undefined);
    setCurrentSession(undefined);
    setAllUserSessions(undefined);
    setCurrentCurriculum(undefined);
    setIsPeerFeedLoading(true);
    setCurrentCurriculumItem(undefined);
    setCurrentCurriculumFeedbackItem({
      prevFeedbackId: null,
      nextFeedbackId: null,
      postId: "",
    });
    setModuleForToday(undefined);
    setPeerFeedPosts([]);
    setUsersForPeerGroup([]);
  }, []);

  const [hasAlreadyRedirectedUserToOnboarding, setHasAlreadyRedirectedUserToOnboarding] =
    useState(false);

  const setupWithUserAndSessions = useCallback(
    async (confirmedUser: UserWithConfirmedAttendance, userSessions: Session[]) => {
      console.log("trying now with adminUserIdToUse ", adminUserIdToUse);
      console.log("SETUP WITH USER AND SESSIONS", confirmedUser, userSessions);

      if (userSessions?.length === 0 || !userSessions) {
        console.error("No sessions found for user: ", confirmedUser);
        return;
      }

      console.log("NO ROUTER", router.pathname, router.asPath, router.basePath);
      // Select the session based on the sessionSlug or default to the first session
      const selectedSession =
        userSessions.find((session) => session.sessionSlug === sessionSlug) || userSessions[0];

      if (!selectedSession) {
        console.error("No session found for sessionSlug: ", sessionSlug);
        return;
      }

      // // chose the newest session, means no session slug was provided
      // // push the newest session to the router
      // if (selectedSession.sessionSlug !== sessionSlug) {
      //   console.log("No session slug provided, going with newest.");
      //   router.push(
      //     {
      //       pathname: `/school/music/sessions/${userSessions[0].sessionSlug}`,
      //     },
      //     undefined,
      //     { shallow: true },
      //   );
      // }

      setCurrentSession(selectedSession);

      console.log("SETUP CURRENT SESSION", selectedSession);

      const hasSessionStarted = new Date(selectedSession.startDate) < new Date();

      console.log("the current route is ", router.pathname, hasAlreadyRedirectedUserToOnboarding);
      // If the user doesn't have a curriculum for this session, redirect them to the onboarding page
      if (
        !hasAlreadyRedirectedUserToOnboarding &&
        confirmedUser.hasDismissedIntro === true && // make sure we only show onboarding if user has dismissed intro
        router.query.showWelcome !== "true" &&
        !router.pathname.includes("/school/music/onboarding") &&
        !router.pathname.includes("/school/music/sessions/")
      ) {
        console.log(
          "REDIRECTING USER",

          selectedSession?.preSessionChecklist.hasCurriculum ? "has curriculum" : "no curriculum",
          confirmedUser.hasDismissedOverview
            ? "has dismissed overview"
            : "hasn't dismissed overview",
          hasSessionStarted ? "session has started" : "session hasn't started",
        );

        // if preSessionChecklist has curriculum, but user hasn't dismissed intro, redirect to overview
        if (
          selectedSession.preSessionChecklist.hasCurriculum &&
          !confirmedUser.hasDismissedOverview
        ) {
          router.push(`/school/music/curriculumOverview`);
        }
        // make sure we don't show onboarding if session has started
        else if (
          selectedSession.preSessionChecklist.hasCurriculum === false &&
          !hasSessionStarted
        ) {
          console.log("REDIRECTING USER TO ONBOARDING from", router.pathname);
          router.push(`/school/music/onboarding`);
        }

        // user has curriculum, and has dismissed overview, we don't redirect

        setHasAlreadyRedirectedUserToOnboarding(true);
      }

      console.log("ABOUT TO LOAD EVERYTHING FOR", selectedSession.sessionSlug);

      if (isLoading === selectedSession.sessionSlug) {
        console.log("ALREADY LOADING, CANCEL SETUP");
        return;
      }

      // Load peer feed posts and users for peer group and curriculum in parallel
      try {
        const peerFeedPostsPromise = pullPostPage(selectedSession.sessionSlug).then((response) => {
          setPeerFeedPosts(response);
          console.log("SETUP PEER FEED POSTS", response);
        });
        const usersForPeerGroupPromise = getUsersForUserPeerGroupWithSessionSlug(
          selectedSession.sessionSlug,
        ).then((response) => {
          setUsersForPeerGroup(response);
          console.log("SETUP USERS FOR PEER GROUP", response);
        });
        const confirmedUserId = confirmedUser.userId;
        const userIdToUse = adminUserIdToUse ? adminUserIdToUse : confirmedUserId;
        console.log("userIdToUse is:  ", userIdToUse, " admin was ", adminUserIdToUse);
        const curriculumPromise = pullCurriculumForSessionAndUser(
          selectedSession.sessionSlug,
          userIdToUse,
        ).then((response) => {
          setCurrentCurriculum(response);
          console.log("SETUP CURRICULUM RESPONSE w userId", userIdToUse, response);
        });

        // Run promises in parallel and set state as they resolve
        setIsLoading(selectedSession.sessionSlug);
        setIsPeerFeedLoading(false);
        await Promise.all([peerFeedPostsPromise, usersForPeerGroupPromise, curriculumPromise]).then(
          () => {
            console.log("SETUP COMPLETE");
          },
        );
      } catch (error) {
        console.error(
          "Error loading peer feed posts or users for peer group or curriculum: ",
          error,
        );
      }

      setIsLoading(undefined);
    },
    [sessionSlug, userSessions, confirmedUser, adminUserIdToUse, adminUserToUse],
  );

  const setup = useCallback(async () => {
    console.log("SETUP W adminUserToUse ", adminUserToUse);

    // if (currentSession && currentSession.sessionSlug === sessionSlug) {
    //   console.log("ABOUT TO CANCEL REDUNDANT SETUP, ALREADY HAVE CURRENT SESSION", currentSession);
    //   return;
    // }

    // If userSessions or confirmedUser are undefined or empty, fetch them in parallel
    if (!userSessions?.length || !confirmedUser) {
      try {
        getUserWithConfirmedAttendance().then(async (userData) => {
          return getGradientColor(userData?.profilePhoto).then((color) => {
            const confirmedUserLoaded = {
              ...userData,
              gradientColor: color,
            } as UserWithConfirmedAttendance;
            initialConfirmedUser.current = confirmedUserLoaded;
          });
        });

        const userAttendancePromise = getUserWithConfirmedAttendance().then(async (userData) => {
          return getGradientColor(userData?.profilePhoto).then((color) => {
            const confirmedUserLoaded = {
              ...userData,
              gradientColor: color,
            } as UserWithConfirmedAttendance;
            setConfirmedUser(confirmedUserLoaded);

            initialConfirmedUser.current = confirmedUserLoaded;

            console.log("SETUP CONFIRMED USER", confirmedUserLoaded);

            return confirmedUserLoaded;
          });
        });

        const curriculumModulePromise = pullCurriculumModuleForToday().then((moduleForToday) => {
          setModuleForToday(moduleForToday);
          return moduleForToday;
        });

        const sessionsPromise = pullSessionsForUser().then((sessions) => {
          setAllUserSessions(sessions);
          return sessions;
        });

        const [confirmedUserLoaded, sessionsLoaded, moduleForToday] = await Promise.all([
          userAttendancePromise,
          sessionsPromise,
          curriculumModulePromise,
        ]);

        console.log("ABOUT TO SETUP MORE, CONFIRMED USER LOADED", confirmedUserLoaded);
        setupWithUserAndSessions(confirmedUserLoaded, sessionsLoaded);
      } catch (error) {
        console.error("Error fetching user or session data: ", error);
        return;
      }
    } else {
      console.log("ABOUT TO SETUP MORE, CONFIRMED USER WAS ALREADY LOADED", confirmedUser);
      setupWithUserAndSessions(confirmedUser, userSessions);
    }
  }, [confirmedUser, userSessions, setupWithUserAndSessions, adminUserToUse]);

  const { capturePostHogEvent } = usePostHogAnalytics();

  useEffect(() => {
    // added a check for sessionSlug to prevent multiple setups being called
    if (router.asPath.startsWith("/school/music") && !sessionSlug) {
      setup(); // set up data
      capturePostHogEvent(SF_HOME_PAGE_VISIT);

      // check for prohibited routes
      // no curriculum
      if (
        !currentSession?.preSessionChecklist.hasCurriculum &&
        !router.asPath.includes("onboarding")
      ) {
        if (router.asPath.includes(`sessions/${currentSession?.sessionSlug}/`)) {
          if (
            router.asPath.includes("profile") &&
            currentSession?.preSessionChecklist.hasCompletedProfile
          ) {
            return; // don't redirect if user has completed profile
          } else {
            router.back();
          }
        }
      }
    }
  }, [router.asPath, sessionSlug]);

  useEffect(() => {
    if (sessionSlug) {
      setup();
    }
  }, [sessionSlug, adminUserIdToUse]);

  const { user } = useAuth();

  // clear data on logout
  useEffect(() => {
    if (user === null) {
      clearCurrentSessionData();
    }
  }, [user]);

  useEffect(() => {
    if (!curriculumId || !currentCurriculum) return;

    let innerItem: CurriculumItem | undefined = undefined;
    let correspondingModule = [] as CurriculumModule[];

    // if (query.todaysTask) {
    //   correspondingModule = [moduleForToday] as typeof correspondingModule;
    // } else

    if (
      currentCurriculum.filter((module) => module.items?.some((item) => item.id === curriculumId))
    ) {
      correspondingModule = currentCurriculum;
    } else {
      console.error("No module found for curriculumId: ", curriculumId);
      return;
    }

    for (const curriculum of correspondingModule) {
      if (curriculum.items) {
        for (let i = 0; i < curriculum.items.length; i++) {
          const item = curriculum.items[i];

          const nextId = (items: CurriculumItem[]) => {
            const hasNextItem = i < items.length - 1;
            const nextItem = items[i + 1];

            if (hasNextItem) {
              if (nextItem.actionType === "feedback") {
                if (nextItem.peerFeedbackAssignments?.length) {
                  return `${nextItem.id}/feedback/${nextItem.peerFeedbackAssignments[0].postId}`;
                }
              } else {
                return nextItem.id ?? null;
              }
            } else {
              return null;
            }
          };

          if (item.id === curriculumId) {
            innerItem = {
              ...item,
              nextId: nextId(curriculum.items),
              prevId: i > 0 ? curriculum.items[i - 1].id : null,
            };
            break;
          }
        }
      }

      if (innerItem) {
        break;
      }
    }

    setCurrentCurriculumItem(innerItem);
  }, [curriculumId, currentCurriculum]);

  // calculate the next and previous feedback items
  useEffect(() => {
    console.log("CALCULATING FEEDBACK ITEMS", feedbackId, currentCurriculumItem);

    if (!currentCurriculumItem || !feedbackId || feedbackId === "undefined") {
      console.error("CALCULATING No feedbackId or currentCurriculumItem found");
      return;
    }

    let innerItem: FeedbackItem = {
      prevFeedbackId: null,
      nextFeedbackId: null,
      postId: "",
    };

    for (let i = 0; i < currentCurriculumItem.peerFeedbackAssignments?.length || 0; i++) {
      const item = currentCurriculumItem.peerFeedbackAssignments[i];

      console.log("CALCULATING Id match", item.postId, feedbackId);

      // find the post
      if (item.postId === feedbackId) {
        innerItem = {
          ...item,
          nextFeedbackId:
            i < currentCurriculumItem.peerFeedbackAssignments.length - 1
              ? `${currentCurriculumItem.id}/feedback/${
                  currentCurriculumItem.peerFeedbackAssignments[i + 1].postId
                }`
              : null,
          prevFeedbackId:
            i > 0
              ? `${currentCurriculumItem.id}/feedback/${
                  currentCurriculumItem.peerFeedbackAssignments[i - 1].postId
                }`
              : null,
        };

        console.log("FOUND INNER ITEM", innerItem);

        break;
      }
    }

    setCurrentCurriculumFeedbackItem(innerItem);
  }, [feedbackId, currentCurriculumItem?.peerFeedbackAssignments]);

  const setCurrentCurriculumItemCompletedOn = useCallback(
    (isComplete: boolean) => {
      // sets the current curriculum item, curriculum, and stores in DB
      if (!currentCurriculumItem) {
        console.error("No currentCurriculumItem found");
        return;
      }

      if (
        (isComplete === true && currentCurriculumItem.completedOn) ||
        (isComplete === false && !currentCurriculumItem.completedOn)
      ) {
        // don't update redundant data
        return;
      }

      console.log(
        "SETTING CURRICULUM ITEM COMPLETED ON",
        isComplete,
        currentCurriculumItem.completedOn,
      );

      // update in currentCurriculum as well
      setCurrentCurriculum((prev) => {
        if (!prev) return prev;
        return prev.map((module) => {
          if (module.items) {
            return {
              ...module,
              items: module.items.map((item) => {
                if (item.id === currentCurriculumItem.id) {
                  return {
                    ...item,
                    completedOn: isComplete ? new Date().toISOString() : undefined,
                  };
                }
                return item;
              }),
            };
          }
          return module;
        });
      });

      // update in DB
      setCurriculumItemCompletedOn(currentCurriculumItem.id);
    },
    [currentCurriculumItem],
  );

  // const refetchCurriculum = useCallback(async () => {
  //   console.log("refetching curriculum ", currentSession);
  //   if (!currentSession?.sessionSlug) return;

  //   console.log("REFETCHING CURRICULUM");

  //   pullCurriculumForSessionAndUser(currentSession?.sessionSlug, confirmedUser?.userId).then(
  //     (response) => {
  //       setCurrentCurriculum(response);
  //     },
  //   );
  // }, [currentSession, confirmedUser]);

  const contextValue = useMemo(
    () => ({
      hasUpdatedCurrentUser,
      isLoading,
      isPeerFeedLoading,
      userSessions,
      setAllUserSessions,
      confirmedUser,
      setConfirmedUser,
      currentSession,
      setCurrentSession,
      initialConfirmedUser: initialConfirmedUser.current,
      currentCurriculum,
      setCurrentCurriculumItem,
      setCurrentCurriculumItemCompletedOn,
      setCurrentCurriculum,
      currentCurriculumItem,
      currentCurriculumFeedbackItem,
      moduleForToday,
      peerFeedPosts,
      setPeerFeedPosts,
      usersForPeerGroup,
      setup,
    }),
    [
      hasUpdatedCurrentUser,
      currentSession,
      isPeerFeedLoading,
      setCurrentSession,
      userSessions,
      setAllUserSessions,
      confirmedUser,
      setConfirmedUser,
      initialConfirmedUser,
      currentCurriculum,
      setCurrentCurriculumItemCompletedOn,
      setCurrentCurriculum,
      currentCurriculumItem,
      setCurrentCurriculumItem,
      currentCurriculumFeedbackItem,
      isLoading,

      moduleForToday,
      peerFeedPosts,
      setPeerFeedPosts,
      usersForPeerGroup,
      setup,
    ],
  );

  return (
    <CurrentSessionContext.Provider value={contextValue}>{children}</CurrentSessionContext.Provider>
  );
};
