import React, { useState } from "react";

import Router from "next/router";
import { useRouter } from "next/router";
import { useForm, SubmitHandler, Controller } from "react-hook-form";
import Linkify from "react-linkify";

import { Button, LargeInput } from "studio-design-system";

import { LOGIN_FAILURE, LOGIN_SUCCESSFUL } from "~/analytics/Amplitude/amplitudeConstants";
import { addUTMPropertiesToEvent } from "~/analytics/Amplitude/amplitudeUtils";
import { useAnalytics } from "~/analytics/useAnalytics";
import { CampaignValues } from "~/components/homepage/HeroSection";
import { useCart } from "~/hooks/cart/useCart";
import { getDoesUserHaveActiveClasses } from "~/util/user-class-info-utils";

import { useAllAuthFunctions, useAuth } from "../../../hooks/auth/useAuth";
import { useAllAuthModalFunctions } from "../../../hooks/auth/useAuthModal";
import { emailValidRegex } from "../../../util/validateEmail";
import * as baseStyles from "../FormLayout.css";

interface IFormInput {
  email: string;
  password: string;
}

export const TEST_LABELS = {
  FIELDS: {
    EMAIL: "log-in-email",
    PASSWORD: "log-in-password",
  },
  BUTTONS: {
    PRIMARY: "log-in-button",
  },
};

interface Props {
  onCreateAccount: () => void;
  onResetPassword: () => void;
  initialEmail?: string;
  title?: string;
  subTitle?: string;
  primaryButtonText?: string;
}
export const LogInForm = ({
  initialEmail = "",
  title = "Log In",
  subTitle,
  primaryButtonText = "Log In",
  onCreateAccount,
  onResetPassword,
}: Props): React.ReactElement => {
  const { signIn } = useAllAuthFunctions();
  const { closeAuthModal } = useAllAuthModalFunctions();
  const { cartItems } = useCart();
  const [submitErrorMessage, setSubmitErrorMessage] = useState<string | undefined>();
  const { trackAmplitudeEvent } = useAnalytics();
  const campaignValue = localStorage.getItem("aaCampaign");
  const router = useRouter();
  const { user } = useAuth();
  const {
    register,
    control,
    formState: { errors, isSubmitting },
    handleSubmit,
  } = useForm<IFormInput>({
    defaultValues: {
      email: initialEmail,
    },
  });

  const onSubmit: SubmitHandler<IFormInput> = async (data) => {
    const { email, password } = data;
    if (submitErrorMessage) {
      setSubmitErrorMessage("");
    }
    const result = await signIn({ email, password });
    if (result.type === "success") {
      trackAmplitudeEvent(LOGIN_SUCCESSFUL, addUTMPropertiesToEvent());

      if (router.query && router.query.redirect) {
        let redirectPage = router.query.redirect as string;
        const ref = localStorage.getItem("instructorRef");
        const code = localStorage.getItem("code");
        // if (ref) {
        //   redirectPage += `&ref=${ref}`;
        // }
        // if (code) {
        //   redirectPage += `&code=${code}`;
        // }
        await router.push(redirectPage);
      } else {
        if (getDoesUserHaveActiveClasses(user)) {
          await router.push("/v2/dashboard");
          return;
        }

        if (router.pathname === "/cart" && cartItems && cartItems.length > 0) {
          const queryParams = router.query;
          // Convert query parameters to a string
          const queryString = Object.keys(queryParams)
            .map((key) => `${key}=${queryParams[key]}`)
            .join("&");

          const checkoutPathWithQuery = `/checkout?${queryString}`;
          await router.push(checkoutPathWithQuery);
        } else if (result.value.currentSessionSlug) {
          console.log("router.push('/school/music')");
          await router.push("/school/music");
        } else {
          console.log("router.push('/') LOGIN");
          await router.push("/"); // only go home if user doesn't have a session
        }
      }
      closeAuthModal();
    } else {
      const { message } = result;
      setSubmitErrorMessage(message || "Error logging in");
      trackAmplitudeEvent(LOGIN_FAILURE, { errorMessages: [message] });
    }
  };

  return (
    <div className={baseStyles.container}>
      <div className={baseStyles.topSection}>
        <div className={baseStyles.titleRow}>
          <h3 className={baseStyles.title}>{title}</h3>
          <div className={baseStyles.altActionContainer}>
            New here?
            <span onClick={onCreateAccount} className={baseStyles.altActionSpaceOnLeft}>
              Create Account
            </span>
          </div>
        </div>
        {subTitle && <div className={baseStyles.subTitle}>{subTitle}</div>}
      </div>

      <div className={baseStyles.formControls}>
        <Controller
          control={control}
          rules={{
            pattern: {
              value: emailValidRegex,
              message:
                "This email address looks invalid. If we’re wrong, please contact support@studio.com.",
            },
            required: { value: true, message: "You must provide an email." },
          }}
          render={({ field: { onChange, value } }) => (
            <LargeInput
              testLabel={TEST_LABELS.FIELDS.EMAIL}
              type="email"
              placeholder="Email"
              errorMessage={errors.email?.message}
              shouldAutoFocus
              autoComplete="email"
              onChange={onChange}
              value={value}
              className={`${baseStyles.inputWrapper} ${baseStyles.inputWithoutMargin}`}
            />
          )}
          name="email"
        />

        <Controller
          control={control}
          rules={{
            required: { value: true, message: "You must provide a password." },
            minLength: {
              value: 8,
              message: "Passwords must be at least 8 characters long.",
            },
          }}
          render={({ field: { onChange, value } }) => (
            <LargeInput
              testLabel={TEST_LABELS.FIELDS.PASSWORD}
              placeholder="Password"
              type="password"
              onKeyDown={(e) => e.key === "Enter" && handleSubmit(onSubmit)()}
              errorMessage={errors.password?.message}
              autoComplete="current-password"
              onChange={onChange}
              value={value}
              className={`${baseStyles.inputWrapper} ${baseStyles.inputWithoutMargin}`}
            />
          )}
          name="password"
        />
      </div>

      {submitErrorMessage && (
        <div className={baseStyles.generalFormError}>
          <Linkify>{submitErrorMessage}</Linkify>
        </div>
      )}
      {campaignValue === CampaignValues.Alumni && (
        <div className={baseStyles.messageContainer}>
          <div>👋</div>
          <div className={baseStyles.messageText}>
            If you’ve taken a Studio or Monthly class before, you can log in here using the same
            account.
          </div>
        </div>
      )}

      {campaignValue === CampaignValues.Account && (
        <div className={baseStyles.messageContainer}>
          <div>👋</div>
          <div className={baseStyles.messageText}>
            You’ve already created an account with this email, but if you’ve forgotten your password
            you can always reset it.
          </div>
        </div>
      )}

      <div>
        <Button
          data-testid={TEST_LABELS.BUTTONS.PRIMARY}
          data-cy={TEST_LABELS.BUTTONS.PRIMARY}
          isLoading={isSubmitting}
          onClick={handleSubmit(onSubmit)}
          className={baseStyles.primaryButton}
        >
          {primaryButtonText}
        </Button>
      </div>

      <div className={baseStyles.bottomAltActionContainer} onClick={onResetPassword}>
        <span className={baseStyles.altAction}>Forgot Password</span>
      </div>
    </div>
  );
};
