import amplitude from "amplitude-js";

import { StudioUser } from "~/api/userApi";

import { API_GATEWAY_AMPLITUDE_PROXY } from "./amplitudeConstants";

export const initAmplitude = () => {
  const amplitudeInstance = amplitude.getInstance();
  if(amplitudeInstance.getDeviceId() === undefined){
    amplitudeInstance.init(process.env.NEXT_PUBLIC_AMPLITUDE_API_KEY as string, undefined, {
      apiEndpoint: API_GATEWAY_AMPLITUDE_PROXY,
      headers: {
        "Cross-Origin-Resource-Policy": "",
      },
    });
  } 
};

export const trackEventWithAmplitude = <T extends any>(
  trackingEventName: string,
  trackingEventProperties?: T,
) => {
  // Send event to Amplitude
  if (trackingEventProperties) {
    amplitude.getInstance().logEvent(trackingEventName, trackingEventProperties);
  } else {
    amplitude.getInstance().logEvent(trackingEventName);
  }
};

export const setAmplitudeUserProperty = <T extends {}>(
  userPropertyName: string,
  userPropertyValues: T,
) => {
  // Identify with Amplitude instance
  const identify = new amplitude.Identify();
  identify.setOnce(userPropertyName, userPropertyValues);
  amplitude.getInstance().identify(identify);
};

export const setUserForAmplitude = ({
  userId,
  email,
  firstName,
  lastName,
  membershipStatus,
  locationInfo,
}: StudioUser) => {
  // Use identify to chain together multiple properties to set
  // before sending to Amplitude
  const identify = new amplitude.Identify();

  identify.set("userId", userId);
  identify.set("email", email);
  identify.set("firstName", firstName);
  identify.set("lastName", lastName);
  identify.set("membershipStatus", membershipStatus || "inactive");

  if (locationInfo) {
    const { city, countryCode } = locationInfo;
    city && identify.set("city", city);
    countryCode && identify.set("countryCode", countryCode);
  }

  amplitude.getInstance().identify(identify);
};

export const addUTMPropertiesToEvent = <T extends {}>(eventProperties?: T) => {
  const utm_source = localStorage.getItem("utm_source");
  const utm_medium = localStorage.getItem("utm_medium");
  const utm_term = localStorage.getItem("utm_term");
  const utm_campaign = localStorage.getItem("utm_campaign");
  const utm_content = localStorage.getItem("utm_content");
  const slug = localStorage.getItem("slug");
  // Need to use snake case for utm property names to match existing event properties in Amplitude
  // TODO: add isFreeTrialPromo once the feature is ready
  return { ...eventProperties, utm_source, utm_medium, utm_term, utm_campaign, utm_content, slug };
};
