import { CollectionData } from "data/collections";

import { fetchApi } from "./fetchStudioApi";

export const getAllProductCollections = async (): Promise<CollectionData[]> => {
  let response = await fetchApi({
    path: "/getAllProductCollections",
    method: "GET",
  });

  return response.collections.filter(
    (collection: CollectionData) => collection.programType !== "school",
  );
};

export const getProductCollectionWithSlug = async (slug: string): Promise<CollectionData> => {
  let response = await fetchApi({
    path: `/getProductCollectionWithSlug?slug=${slug}`,
    method: "GET",
  });
  return response.collection;
};
