import React, { useEffect } from "react";

import posthog from "posthog-js";

import { ProductData, PurchaseableItem } from "data/product/products";
import { is } from "date-fns/locale";
import Cookies from "universal-cookie";
import { v4 as uuidv4 } from "uuid";

import {
  PRODUCT_LANDING_PAGE_LOGIN,
  PRODUCT_LANDING_PAGE_VISIT,
  CREATE_ACCOUNT_FORM_SUBMIT_SUCCESS,
  CART_PAGE_ITEM_ADDED,
  AUTH_MODAL_ENTER_EMAIL,
} from "~/analytics/Amplitude/amplitudeConstants";
import {
  TRACK_NON_WAITLIST_EMAIL_CAPTURE_GA,
  TRACK_PURCHASE_GA,
  TRACK_WAITLIST_EMAIL_CAPTURE_GA,
} from "~/analytics/GoogleAnalytics/googleAnalyticsConstants";
import { useAuth } from "~/hooks/auth/useAuth";
import { getOptimizelyUserIdentifier } from "~/util/optimizelyUtils";

import { USER_PROPERTY_LEAD_SOURCE_INFO } from "./Amplitude/amplitudeConstants";
import {
  addUTMPropertiesToEvent,
  initAmplitude,
  setUserForAmplitude,
  trackEventWithAmplitude,
  setAmplitudeUserProperty,
} from "./Amplitude/amplitudeUtils";
import { identifyDripUser } from "./Drip/dripUtils";
import {
  initializeFacebookPixel,
  trackFBCollectEmail,
  trackFBCreateUser,
} from "./FacebookPixel/facebookPixelUtils";
import {
  initializeGA,
  trackGACollectEmail,
  trackGACreateUser,
  trackGAPurchase,
} from "./GoogleAnalytics/gaUtils";
import { trackTiktokCreateAccount } from "./TiktokPixel/tiktokPixelUtils";

export enum PurchaseType {
  Product = "Purchased Product",
  Class = "Purchased Class",
  Subscription = "Subscription Purchase",
}

declare global {
  interface Window {
    fbq: any;
    enhanced_conversion_data?: {
      [key: string]: string;
    };
    gtag: any;
    _dcq?: {
      //@ts-ignore - This array can take in multiple different values, so its easier to keep the definition as an array that takes in any value
      push: (Arr: any[]) => void;
    };
  }
}

/**
 * Get or create a unique identifier for this user's device to use with optimizely.
 */
export function getUserIdentifierByDevice() {
  const cookies = new Cookies();
  let userIdentifierByDevice = cookies.get("userIdentifierByDevice");
  // If a user identifier is not stored in device yet, create one and store it.
  if (!userIdentifierByDevice) {
    userIdentifierByDevice = uuidv4();
    cookies.set("userIdentifierByDevice", userIdentifierByDevice);
  }
  return userIdentifierByDevice;
}

export const useAnalytics = () => {
  // In order react to useAuth() changes, we need our own provider which is
  // mounted at root
  const { user } = useAuth();
  const [userIdentified, setUserIdentified] = React.useState(user);
  const trackEmailCollection = (
    classInterestedIn: string,
    extraTags: string[],
    email: string,
    productData?: PurchaseableItem,
  ) => {
    let isWaitlistEmail = false;
    // if extraTags contain a waitlist tag, don't track the email collection
    if (extraTags.length >= 0) {
      // at this point, extraTags is a String, so we just check for the substring of "waitlist"
      isWaitlistEmail = extraTags.includes("waitlist");
      isWaitlistEmail =
        isWaitlistEmail ||
        extraTags.includes("plugins-resource-page") ||
        extraTags.includes("sf-music");
    }

    const googleAdsTrackingEvent = isWaitlistEmail
      ? TRACK_WAITLIST_EMAIL_CAPTURE_GA
      : TRACK_NON_WAITLIST_EMAIL_CAPTURE_GA;
    const facebookAdsTrackingEvent = isWaitlistEmail ? "Lead" : "InitiateCheckout";

    trackGACollectEmail(email, googleAdsTrackingEvent);
    if (productData) {
      trackFBCollectEmail(email, facebookAdsTrackingEvent, classInterestedIn);
    }

    const productId = productData ? productData.id : classInterestedIn;
    trackAmplitudeEvent(facebookAdsTrackingEvent, {
      productId: productId,
      productTitle: productData?.title,
      creatorName: productData?.creatorName,
    });
    getUserIdentifierByDevice();
  };

  const trackPurchase = (
    itemLabel: string,
    valueInCents: number,
    purchaseType: PurchaseType,
    product: PurchaseableItem,
  ) => {
    // console.log("in the analytics purchase function");
    const valueInDollars = valueInCents / 100;
    // console.log("tracking purchase value in dollars ", valueInDollars, itemLabel);
    if (typeof window !== "undefined") {
      // Facebook Pixel.
      // Tracks value in dollars (not cents).
      if (typeof window.fbq === "function") {
        window.fbq("track", "Purchase", {
          currency: "USD",
          value: valueInDollars,
          content_ids: [itemLabel],
          content_name: product.title,
        });
      }

      // Send the instructor's name and the product title as the item name.
      const itemName = product.creatorName
        ? product.creatorName + " " + product.title
        : product.title;

      // Google Ads Pixel
      trackGAPurchase({
        purchaseType,
        itemLabel,
        valueInDollars,
      });

      // Tracks value in dollars (not cents).
      if (typeof window.gtag === "function") {
        const uniqueTransactionId = uuidv4();

        if (user) {
          window.enhanced_conversion_data = {
            email: user.email,
            first_name: user.firstName,
            last_name: user.lastName,
          };
        }

        // console.log("GTAG event ", uniqueTransactionId);

        // Google Ads pixel conversion event
        window.gtag("event", "conversion", {
          send_to: TRACK_PURCHASE_GA,
          value: valueInDollars,
          currency: "USD",
          transaction_id: uniqueTransactionId,
        });
        // console.log(" PURCHASE event ");

        // Google Analytics 4 Purchase event
        window.gtag("event", "purchase", {
          transaction_id: uniqueTransactionId,
          value: valueInDollars,
          currency: "USD",
          items: [
            {
              item_id: itemLabel,
              item_name: itemName,
              price: valueInDollars,
              quantity: 1,
            },
          ],
        });
      }

      // TikTok Pixel
      // todo - implement TikTok
      // sendPurchaseEventToTiktok(itemLabel, valueInDollars);

      // Drip
      if (typeof window._dcq != null) {
        window._dcq?.push(["track", "Purchase " + itemLabel, { value: valueInCents }]);
      }

      // console.log("TRACKED PURCHASE in Analytics!");
    }
  };

  // client-side init
  useEffect(() => {
    initAmplitude();
    initializeFacebookPixel();
    initializeGA();
  }, []);

  // whenever user changes, update analytics services
  useEffect(() => {
    if (user && user.userId !== userIdentified?.userId) {
      // console.log("NEWEST USER IS ", user);
      setUserForAmplitude(user);
      identifyDripUser(user);
      // console.log("POSTHOG INSTANCE IS ", posthog);
      posthog.identify(user.userId, {
        email: user.email,
        firstName: user.firstName,
        lastName: user.lastName,
        username: user.username,
        signUpDate: user.signUpDate,
        miniBio: user.miniBio,
        membershipStatus: user.membershipStatus,
      });
      // console.log("just identified ???? ");

      setUserIdentified(user);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user]);

  const trackCreateAccount = () => {
    trackFBCreateUser();
    trackGACreateUser();
    trackTiktokCreateAccount();
    getUserIdentifierByDevice();
    setAmplitudeUserProperty(USER_PROPERTY_LEAD_SOURCE_INFO, addUTMPropertiesToEvent());
  };

  const trackMetaFBCatalogEvent = <T extends Record<string, any>>(
    trackingEventName: string,
    trackingEventProperties?: T,
  ) => {
    // console.log("tracking meta fb catalog event ", trackingEventName);
    if (typeof window !== "undefined") {
      // Facebook Pixel.
      // Tracks value in dollars (not cents).
      if (typeof window.fbq === "function") {
        let fbTrackEventName = "";
        if (trackingEventName === PRODUCT_LANDING_PAGE_VISIT) {
          fbTrackEventName = "ViewContent";
        }
        if (trackingEventName === CREATE_ACCOUNT_FORM_SUBMIT_SUCCESS) {
          fbTrackEventName = "CompleteRegistration";
        }
        if (trackingEventName === AUTH_MODAL_ENTER_EMAIL) {
          fbTrackEventName = "Lead";
        }
        if (trackingEventName === CART_PAGE_ITEM_ADDED) {
          fbTrackEventName = "AddToCart";
        }
        if (trackingEventName === "InitiateCheckout") {
          fbTrackEventName = "InitiateCheckout";
        }
        if (fbTrackEventName !== "") {
          let extraParameters: any = {};
          if (trackingEventProperties?.productPrice) {
            const valueInDollars = trackingEventProperties?.productPrice / 100;
            extraParameters["value"] = valueInDollars;
            extraParameters["currency"] = "USD";
          }

          if (trackingEventProperties?.productId) {
            extraParameters["content_ids"] = [trackingEventProperties?.productId];
          }

          if (trackingEventProperties?.productTitle) {
            extraParameters["content_name"] = trackingEventProperties?.productTitle;
          }

          // console.log("ACTUALLY TRACKING FB EVENT ", fbTrackEventName, extraParameters);

          window.fbq("track", fbTrackEventName, extraParameters);
        }
      }
    }
  };

  const trackAmplitudeEvent = <T extends Record<string, any>>(
    trackingEventName: string,
    trackingEventProperties?: T,
  ) => {
    const source = typeof window !== undefined && localStorage.getItem("source");
    const trackingEventObject = source
      ? { ...(trackingEventProperties || {}), source }
      : trackingEventProperties;

    posthog.capture(trackingEventName, trackingEventObject);
    // console.log("capturing posthog event ", trackingEventName, trackingEventObject);

    // track FB Meta catalog event
    trackMetaFBCatalogEvent(trackingEventName, trackingEventObject);

    // We don't use amplitude anymore - only Posthog.
    // return trackEventWithAmplitude(trackingEventName, {
    //   ...trackingEventObject,
    //   ...addUTMPropertiesToEvent(),
    // });
  };

  return { trackCreateAccount, trackAmplitudeEvent, trackEmailCollection, trackPurchase };
};
