import { useEffect, useRef, useState } from "react";

import classNames from "classnames";

import DropdownIcon from "~/components/common/MarketingHeaderWithAuth/DropdownIcon";

import * as styles from "./Dropdown.css";

interface DropdownProps {
  label: string;
  className?: string;
  placeholder: string;
  options: { label: string; value: string }[];
  selected: { label: string; value: string };
  setSelected: React.Dispatch<React.SetStateAction<{ label: string; value: string }>>;
}

export const Dropdown = (props: DropdownProps) => {
  const dropdownRef = useRef<HTMLDivElement>(null);
  const [dropdownState, setDropdownState] = useState({
    showInput: false,
    showOptions: false,
  });

  const { options, selected, setSelected, label } = props;

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target as Node)) {
        setDropdownState((prevState) => ({
          ...prevState,
          showOptions: false,
        }));
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => document.removeEventListener("mousedown", handleClickOutside);
  }, [dropdownRef]);

  return (
    <div className={classNames(props.className, styles.container)} ref={dropdownRef}>
      <label className={styles.label}>{label}</label>
      <div
        className={classNames(
          dropdownState.showOptions && styles.activeSelectedOptionContainer,
          styles.selectedOptionContainer,
        )}
        onClick={() => {
          setDropdownState({
            showInput: false,
            showOptions: true,
          });
        }}
      >
        <div className={classNames(selected.label && styles.selectedOption, styles.option)}>
          {selected.label ? selected.label : props.placeholder}
        </div>
        <DropdownIcon color="#757575" />
      </div>
      {dropdownState.showOptions && (
        <div className={styles.optionsContainer}>
          {options.map((option, id) => (
            <div
              key={id}
              className={classNames(
                styles.hoverableOption,
                styles.option,
                selected === option && styles.selectedOption,
              )}
              onClick={() => {
                setSelected(option);
                setDropdownState({ showInput: false, showOptions: false });
              }}
            >
              {option.label}
            </div>
          ))}
          <div
            className={classNames(
              styles.hoverableOption,
              styles.option,
              selected.label === "Other" && styles.selectedOption,
            )}
            onClick={() => {
              setSelected({ label: "Other", value: "" });
              setDropdownState({ showInput: true, showOptions: false });
            }}
          >
            Other
          </div>
        </div>
      )}
      {dropdownState.showInput && (
        <div className={classNames(styles.inputContainer, styles.container)}>
          <label className={styles.label}>Please describe</label>
          <textarea
            rows={4}
            className={styles.textarea}
            placeholder="What did this person do?"
            onChange={(e) => setSelected({ label: "Other", value: e.target.value })}
          />
        </div>
      )}
    </div>
  );
};
