import React, { useState } from "react";

import { useRouter } from "next/router";

import { DashboardRoute } from "data/routes/routes";
import { Button, LargeInput, TermsAndPrivacyDisclaimer } from "studio-design-system";

import { useAnalytics } from "~/analytics/useAnalytics";
import { collectEmail } from "~/api/userApi";
import * as baseStyles from "~/components/auth/FormLayout.css";
import { useAllAuthModalFunctions } from "~/hooks/auth/useAuthModal";
import { GENERIC_ERROR_MESSAGE } from "~/util/commonErrors";

export const JoinWaitlistForm = () => {
  const [isWaiting, setIsWaiting] = useState(false);
  const { closeAuthModal } = useAllAuthModalFunctions();
  const router = useRouter();
  const { classId } = router.query;
  const [email, setEmail] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [isEmailAdded, setIsEmailAdded] = useState(false);
  const { trackEmailCollection } = useAnalytics();

  const onJoinWaitlist = async () => {
    setIsWaiting(true);
    if (email.length === 0) {
      setIsWaiting(false);
      setErrorMessage("Please input an email");
      return;
    }
    const classInterestedIn = classId ? `${classId}` : "general";
    const extraTags: string[] = [];
    trackEmailCollection(classInterestedIn, extraTags, email);
    const response = await collectEmail({ email, classInterestedIn });
    if (response.error) {
      setErrorMessage(GENERIC_ERROR_MESSAGE);
      return;
    }
    setIsEmailAdded(true);
    setIsWaiting(false);
  };
  return (
    <div className={baseStyles.container}>
      <div className={baseStyles.topSection}>
        <h3 className={baseStyles.title}>
          {isEmailAdded ? "You've been added! 🎉" : "Join Waitlist"}
        </h3>
        <div className={baseStyles.subTitle}>
          {isEmailAdded
            ? "Keep an eye on your emails so you can enroll in the next class before enrollment closes. For now, you can explore more classes."
            : "Be the first to know when class opens for enrollment."}
        </div>
      </div>

      {!isEmailAdded && (
        <>
          <div className={baseStyles.sectionRow}>
            <LargeInput
              type="email"
              placeholder="Email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              onKeyDown={(e) => {
                e.key === "Enter" && onJoinWaitlist();
              }}
              errorMessage={errorMessage}
              shouldAutoFocus
              autoComplete="email"
            />
          </div>
          <div className={baseStyles.termsAgreementRow}>
            <TermsAndPrivacyDisclaimer />
          </div>
        </>
      )}
      {!isEmailAdded ? (
        <div className={baseStyles.buttonSection}>
          <div>
            <Button
              className={baseStyles.primaryButton}
              onClick={onJoinWaitlist}
              isLoading={isWaiting}
            >
              {isEmailAdded ? <div>Joined</div> : "Join Waitlist"}
            </Button>
          </div>
        </div>
      ) : (
        <Button
          onClick={() => {
            setIsWaiting(true);
            closeAuthModal();
            router.push(DashboardRoute.Classes);
          }}
        >
          Explore Classes
        </Button>
      )}
    </div>
  );
};
