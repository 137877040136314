import React, { ReactElement } from "react";

import {
  SchoolModalExtraProps,
  SchoolModalType,
} from "~/hooks/schoolCoreProvider/SchoolModalProvider";

import { CurriculumOverviewModal } from "./CurriculumOverviewModal";
import { DeletePostModal } from "./DeletePostModal";
import { GalleryMediaModal } from "./GalleryMediaModal";
import { ReportPostModal } from "./ReportPostModal";
import { SubmitProjectModal, SubmitProjectModalProps } from "./SubmitProjectModal";

interface Props {
  isOpen: boolean;
  onClose: () => void;
  modalVariant: SchoolModalType;
  schoolModalProps: SchoolModalExtraProps;
}

export const SchoolFormOrchestrator = ({
  isOpen,
  onClose,
  modalVariant,
  schoolModalProps,
}: {
  isOpen: boolean;
  onClose: () => void;
  modalVariant: SchoolModalType;
  schoolModalProps: SchoolModalExtraProps;
}): ReactElement => {
  const projectModalVariant = schoolModalProps.post?.isFinalProject
    ? "finalProject"
    : (modalVariant as SubmitProjectModalProps["variant"]);

  const getModalComponent = () => {
    switch (modalVariant) {
      case SchoolModalType.Report:
        return <ReportPostModal {...schoolModalProps} onClose={onClose} isOpen={isOpen} />;
      case SchoolModalType.Delete:
        return (
          <DeletePostModal
            {...schoolModalProps}
            onConfirm={schoolModalProps.onConfirm as () => void}
            onClose={onClose}
            isOpen={isOpen}
          />
        );
      case SchoolModalType.Overview:
        return <CurriculumOverviewModal {...schoolModalProps} onClose={onClose} isOpen={isOpen} />;
      case SchoolModalType.Gallery:
        return <GalleryMediaModal {...schoolModalProps} onClose={onClose} isOpen={isOpen} />;
      case SchoolModalType.Edit:
      case SchoolModalType.Project:
      case SchoolModalType.Update:
        return (
          <SubmitProjectModal
            {...schoolModalProps}
            onConfirm={schoolModalProps.onConfirm as SubmitProjectModalProps["onConfirm"]}
            onClose={onClose}
            isOpen={isOpen}
            variant={projectModalVariant}
          />
        );
      default:
        return <></>;
    }
  };

  return getModalComponent();
};

export const SchoolModal = ({ isOpen, onClose, modalVariant, schoolModalProps }: Props) => {
  return (
    <SchoolFormOrchestrator
      isOpen={isOpen}
      onClose={onClose}
      modalVariant={modalVariant}
      schoolModalProps={schoolModalProps}
    />
  );
};
