import React from "react";

export const ArrowRightIcon = () => (
  <svg width="13" height="13" viewBox="0 0 13 13" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M1.1237 7.29262H9.96661L6.10328 11.156C5.79453 11.4647 5.79453 11.9714 6.10328 12.2801C6.41203 12.5889 6.91078 12.5889 7.21953 12.2801L12.4366 7.06304C12.7454 6.75429 12.7454 6.25554 12.4366 5.94679L7.22745 0.721785C7.07954 0.573544 6.87873 0.490234 6.66932 0.490234C6.45991 0.490234 6.25911 0.573544 6.1112 0.721785C5.80245 1.03054 5.80245 1.52929 6.1112 1.83804L9.96661 5.70929H1.1237C0.688281 5.70929 0.332031 6.06554 0.332031 6.50095C0.332031 6.93637 0.688281 7.29262 1.1237 7.29262Z"
      fill="#757575"
    />
  </svg>
);
