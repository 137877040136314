import { OverlayModal } from "../../common/OverlayModal";
import { AuthFormOrchestrator } from "../AuthFormOrchestrator";
import { AuthForm } from "../authTypes";
import * as styles from "./AuthModal.css";

interface Props extends Exclude<React.ComponentProps<typeof OverlayModal>, "contentClass"> {
  entryAuthForm: AuthForm;
}

export const AuthModal = ({ entryAuthForm, ...overlayModalProps }: Props) => {
  return (
    <OverlayModal contentClass={styles.content} {...overlayModalProps}>
      {
        // only render AuthFormOrchestrator when the modal is open,
        // to take advantage of component dismounting to clear form states,
        // and performance benefits since AuthModal will always be mounted in our app
        overlayModalProps.isOpen ? <AuthFormOrchestrator entryForm={entryAuthForm} /> : <></>
      }
    </OverlayModal>
  );
};
