import { CollectionData } from "data/collections";
import { PriceData, ProductData, PurchaseableItem } from "data/product/products";
import { format } from "date-fns";

export const interpolateStringVariables = (
  label: string,
  map: Record<string, string | number>,
): string => {
  const re = /\{\{(.*?)\}\}/g;

  return label.replace(re, (match: string): string => {
    const group = match.slice(2, match.length - 2).trim();
    return getValue(map, group);
  });
};

type DictionaryValue = string | number;
type Dictionary = Record<string, DictionaryValue>;

type Pair = [DictionaryValue, Dictionary];

const isStringOrNumber = (value: DictionaryValue) =>
  typeof value === "string" || typeof value === "number";

const getValue = (map: Dictionary, key: string): string => {
  const value = map[key];

  const stack: Pair[] = Object.keys(map).map((k) => [k, map]);

  while (stack.length > 0) {
    const pair = stack.pop();
    if (pair === undefined) {
      return "";
    }
    const [currentKey, currentMap] = pair;
    const currentValue = currentMap[currentKey];
    if (key === currentKey) {
      if (isStringOrNumber(currentValue)) {
        return currentValue.toString();
      }
      return "";
    } else {
      if (typeof currentValue !== "string" && typeof currentValue !== "number") {
        Object.keys(currentValue).forEach((key) => stack.push([key, currentValue]));
      }
    }
  }

  if (isStringOrNumber(value)) return value.toString();
  return "";
};

export const convertParamsValueToStringOrUndefined = (
  value?: string | string[],
): string | undefined => {
  if (!value) {
    return undefined;
  }
  if (Array.isArray(value)) {
    return value[0];
  }
  return value;
};

export const toHHMMSS = (s: number, duration: boolean = false) => {
  var hours =
    Math.floor(s / 3600) < 10 ? ("00" + Math.floor(s / 3600)).slice(-2) : Math.floor(s / 3600);
  var minutes = ("00" + Math.floor((s % 3600) / 60)).slice(-2);
  var seconds = ("00" + ((s % 3600) % 60)).slice(-2);
  if (duration) {
    return (
      (hours == "00" ? "" : hours + "h ") +
      (minutes == "00" ? "" : minutes + "m ") +
      (seconds == "00" ? "" : seconds + "s")
    );
  } else {
    return (
      (hours == "00" ? "" : hours + ":") +
      (minutes == "00" ? "" : minutes + ":") +
      (seconds == "00" ? "" : seconds + "")
    );
  }
};

export const isDescWrapping = (element: any, tolerance: number = 2) => {
  if (element) {
    return element.clientHeight + tolerance < element.scrollHeight;
  }
  return false;
};

export function formatMoney(amount: number, isAmountInCents = true, decimals = 2) {
  if (!amount) {
    amount = 0;
  }
  if (isAmountInCents) {
    amount = parseFloat((amount / 100).toFixed(decimals));
  }

  let dollarStr = amount.toLocaleString("en-US", {
    style: "currency",
    currency: "USD",
    maximumFractionDigits: decimals,
  });
  return dollarStr;
}

export function formatMoneyWithoutDollarStr(amount: number, isAmountInCents = true) {
  if (!amount) {
    amount = 0;
  }
  if (isAmountInCents) {
    amount = parseFloat((amount / 100).toFixed(2));
  }

  return amount.toLocaleString("en-US", { style: "currency", currency: "USD" }).replace("$", "");
}

export function parseStringToNumber(input: number | string) {
  // Check if the input is already a number
  if (typeof input === "number") {
    // If it's already a number, return it as is
    return input;
  } else if (typeof input === "string") {
    // If it's a string, try to parse it into a number
    const parsedNumber = parseFloat(input);

    // Check if the parsing was successful
    if (!isNaN(parsedNumber)) {
      return parsedNumber;
    } else {
      // If parsing failed, return NaN or handle the error as needed
      return NaN;
    }
  } else {
    // Handle other data types if necessary
    return NaN;
  }
}

export const groupedPrice = (
  key: "price" | "crossedPrice" | "difference",
  items: PurchaseableItem[],
  shouldShowOfferFunc: (priceInfo: PriceData) => boolean,
  removeDollarSign: true | false = false,
) => {
  const price = items?.reduce((acc: number, item: PurchaseableItem) => {
    if (key == "crossedPrice") {
      if (item?.isCollection) {
        return acc + parseStringToNumber(item.totalValuePriceInCents) || 0;
      }
      return acc + parseStringToNumber(item.priceInfo[key]) || 0;
    } else if (key == "difference") {
      return (
        acc +
        (parseStringToNumber(item.priceInfo.crossedPrice) -
          parseStringToNumber(item.priceInfo.price))
      );
    } else {
      const isPurchaseableItemAlwaysOnSale = "availableUntil" in item;
      const shouldUserSeeOffer = shouldShowOfferFunc(item.priceInfo);

      const price = shouldUserSeeOffer ? item.priceInfo.price : item.priceInfo.crossedPrice;
      return acc + parseStringToNumber(price) || 0;
    }
  }, 0);

  const priceWithoutDollarSign = formatMoney(price)?.replace("$", "");

  return removeDollarSign ? priceWithoutDollarSign : formatMoney(price);
};

export function formatFriendlyDate(dateString: string) {
  const date = new Date(dateString);
  return format(date, "MMM d, yyyy");
}

export function formatDateOnlyYear(dateString: string) {
  const date = new Date(dateString);
  return format(date, "yyyy");
}

export function formatDateOnlyMonthAndYear(dateString: string) {
  const date = new Date(dateString);
  return format(date, "MMMM yyyy");
}

export function formatDateOnlyMonthAndDay(dateString: string) {
  const date = new Date(dateString);
  return format(date, "MMMM d");
}

export function formatDateAndTime(dateString: string) {
  const date = new Date(dateString);
  const now = new Date();
  const currentYear = now.getFullYear();
  const dateYear = date.getFullYear();

  const formatOptions: Intl.DateTimeFormatOptions = {
    month: "short",
    day: "numeric",
    hour: "numeric",
    minute: "numeric",
    hour12: true,
  };

  if (dateYear === currentYear) {
    return date.toLocaleString("en-US", formatOptions);
  } else {
    return date.toLocaleString("en-US", { ...formatOptions, year: "numeric" });
  }
}

export function convertSecondsToFriendlyDuration(totalSecondsLength: number) {
  if (!totalSecondsLength) {
    return "0h 0m";
  }

  let secsPerHour = 60 * 60;
  let hoursLength = Math.floor(totalSecondsLength / secsPerHour);
  totalSecondsLength = totalSecondsLength - hoursLength * secsPerHour;
  let minutes = Math.floor(totalSecondsLength / 60);

  if (hoursLength == 0) {
    return minutes + "m";
  }

  if (minutes == 0) {
    return hoursLength + "h";
  }

  return hoursLength + "h " + minutes + "m";
}

export function addZeroToSingleDigitNumber(num: number) {
  if (num < 10) {
    return "0" + num;
  }
  return num;
}

export function convertSecondsToVideoPlayerDuration(totalSecondsLength: number) {
  if (!totalSecondsLength) {
    return "0:00";
  }

  let secsPerHour = 60 * 60;
  let secsPerMinute = 60;
  let hoursLength = Math.floor(totalSecondsLength / secsPerHour);
  totalSecondsLength = totalSecondsLength - hoursLength * secsPerHour;
  let minutesLength = Math.floor(totalSecondsLength / secsPerMinute);
  totalSecondsLength = totalSecondsLength - minutesLength * secsPerMinute;

  if (hoursLength > 0) {
    return (
      hoursLength +
      ":" +
      addZeroToSingleDigitNumber(minutesLength) +
      ":" +
      addZeroToSingleDigitNumber(totalSecondsLength)
    );
  }

  return minutesLength + ":" + addZeroToSingleDigitNumber(totalSecondsLength);
}

export function displayNumberWithCommas(num: number | undefined) {
  if (!num) {
    return "";
  }
  const numWithCommas = num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  return numWithCommas;
}

export function convertSecondsToMarketingDuration(totalSecondsLength: number) {
  if (totalSecondsLength <= 60) {
    return "Less than 1 minute";
  }

  if (totalSecondsLength <= 3600) {
    return `${Math.floor(totalSecondsLength / 60)} minutes`;
  }

  let hoursEstimate = Math.floor(totalSecondsLength / 3600);

  if (hoursEstimate === 1) {
    return "1+ hour";
  }
  return `${hoursEstimate}+ hours`;
}

export function convertSecondsToMinutes(totalSecondsLength?: number) {
  if (!totalSecondsLength) {
    return 0;
  }
  const minutes = Math.floor(totalSecondsLength / 60);
  return minutes;
}

export function convertSecondsToHoursAndMinutes(totalSecondsLength: number) {
  const hour = Math.floor(totalSecondsLength / 3600);
  const minute = Math.floor((totalSecondsLength % 3600) / 60);

  return `${hour}h ${minute}m`;
}

export function getProductCheckoutPath(product: ProductData) {
  return "/checkout/" + getProductSlug(product);
}

export function getPathForProduct(product: ProductData | CollectionData) {
  if ("classSlug" in product) {
    const productSlug = product.classSlug ? product.classSlug : getProductSlug(product);
    const storefrontSlug = product.storefrontSlug;
    return `/classes/${storefrontSlug}/${productSlug}`;
  } else {
    return `/collections/${product.slug}`;
  }
}
export function getPathForSchool(schoolProduct: ProductData) {
  return "/schools/" + schoolProduct.slug;
}

export function getProductWatchPath(product: ProductData) {
  const productSlug = getProductSlug(product);
  // if (product.classSlug && product.storefrontSlug) {
  //   return `/watch/${product.storefrontSlug}/${product.classSlug}`;
  // }
  return `/watch/${productSlug}`;
}

export function getProductSlug(product: ProductData) {
  const productIdWithoutDashes = product?.id?.replaceAll("-", "");
  const productTitle = transformProductTitleToUrlFriendly(product?.title || "");
  const productSlug = `${productTitle}-${productIdWithoutDashes}`;
  return productSlug;
}

function transformProductTitleToUrlFriendly(productTitle: string): string {
  productTitle = productTitle.replace(/[^a-zA-Z0-9\s]/g, "");
  productTitle = productTitle.trim();
  productTitle = productTitle.replace(/\s/g, "-");
  return productTitle;
}

export function getProductIdFromSlug(slug: string) {
  let split = slug.split("-");
  let uuidWithoutDashes = split[split.length - 1];

  // If the uuid is 32 characters long, it doesn't have dashes
  if (uuidWithoutDashes.length == 32) {
    return addDashesToUUID(uuidWithoutDashes);
  }

  return slug;
}

export function addDashesToUUID(uuid: string) {
  return uuid.replace(/(.{8})(.{4})(.{4})(.{4})(.{12})/, "$1-$2-$3-$4-$5");
}

export function isUUID(str: string) {
  const uuidRegex = /^[0-9a-f]{8}-[0-9a-f]{4}-[1-5][0-9a-f]{3}-[89ab][0-9a-f]{3}-[0-9a-f]{12}$/i;
  return uuidRegex.test(str);
}

export function markdownTextAddNewlines(text: string) {
  const convertedText = text.replaceAll(`\n\n`, `   \n&nbsp;  \n&nbsp;`);

  // console.log("converted text ", convertedText);
  return convertedText;
}

export function reverseString(text: string) {
  return text.split("").reverse().join("");
}

export function addBreaksToText(text: string) {
  let convertedText = text.replaceAll(`</p>`, `</p>`);
  convertedText = convertedText.replaceAll(`</ul>`, `</ul>`);
  convertedText = convertedText.split("").reverse().join("");
  convertedText = convertedText.replace(reverseString(`<p><br/>`), reverseString(`</p>`));
  convertedText = reverseString(convertedText);

  return convertedText;
}

export function addLinksToText(text: string) {
  let convertedText = text;

  // check if there are any links
  if (!text.includes("[")) {
    return convertedText;
  }

  let regExp = /\(([^)]+)\)/g;
  let matches = text.match(regExp);
  if (matches) {
    for (let i = 0; i < matches.length; i++) {
      let str = matches[i];
      let url = str.substring(1, str.length - 1);
      convertedText = convertedText.replace("[", `<a href='${url}' target='__blank'>`);
      convertedText = convertedText.replace("]", "</a>");
      convertedText = convertedText.replace(str, "");
    }
  }
  return convertedText;
}

export function boldText(text: string) {
  let convertedText = text;

  // check if there are any bolded text
  if (!text.includes("**")) {
    return convertedText;
  }

  //while (text.includes("**")) {
  convertedText.replace("**", "<strong>");
  convertedText.replace("**", "</strong>");
  //}
  return convertedText;
}

export function magicConversion(text: string) {
  if (!text) {
    return "";
  }
  text = text.replace("\n - ", "</p><p></p><ul><li>");
  let hasList = text.includes("\n -");
  let newText = text;
  if (hasList) {
    text = text.replaceAll("\n -", "+++\n -");
    let key = reverseString(`+++`);
    text = reverseString(text); // reversed
    let all_texts = text.split(key);
    let first_one = all_texts[0]; // reversed
    first_one = reverseString(first_one);
    first_one = first_one.replace(`\n\n`, `</li></ul><p>`); // good

    let all_but_first = all_texts.slice(1); // reversed
    let all_but_first_text = all_but_first.join();
    all_but_first_text = reverseString(all_but_first_text); // good

    newText = all_but_first_text + first_one; // good
    newText = newText.replaceAll("\n -", "</li><li>");
  }

  newText = newText.replaceAll(`\n\n`, `</p><p>`);

  newText = addBreaksToText(newText);
  newText = newText.replaceAll("<br/><p></p><br/>", "<br/>");
  newText = newText.replaceAll(",</li>", "</li>");
  newText = addLinksToText(newText);

  newText = boldText(newText);
  return "<p>" + newText;
}

export const shouldShowRelativeDate = (dateString: string) => {
  let relativeDate = convertToRelativeDate(dateString);

  // If it's multiple years, it feels too stale so don't show it.
  if (relativeDate.includes("years")) {
    return false;
  }
  return true;
};

export const convertToRelativeDate = (dateString: string) => {
  var msPerMinute = 60 * 1000;
  var msPerHour = msPerMinute * 60;
  var msPerDay = msPerHour * 24;
  var msPerMonth = msPerDay * 30;
  var msPerYear = msPerDay * 365;
  var now = new Date();
  var prevDate = new Date(dateString);
  var elapsed = now.getTime() - prevDate.getTime();
  if (elapsed < msPerMinute) {
    return Math.round(elapsed / 1000) + " seconds ago";
  } else if (elapsed < msPerHour) {
    let minsAgo = Math.round(elapsed / msPerMinute);
    return minsAgo === 1 ? minsAgo + " minute ago" : minsAgo + " minutes ago";
  } else if (elapsed < msPerDay) {
    let timeAgo = Math.round(elapsed / msPerHour);
    return timeAgo === 1 ? timeAgo + " hour ago" : timeAgo + " hours ago";
  } else if (elapsed < msPerMonth) {
    let daysAgo = Math.round(elapsed / msPerDay);
    return daysAgo === 1 ? daysAgo + " day ago" : daysAgo + " days ago";
  } else if (elapsed < msPerYear) {
    let monthsAgo = Math.round(elapsed / msPerMonth);
    return monthsAgo === 1 ? monthsAgo + " month ago" : monthsAgo + " months ago";
  } else {
    let yearsAgo = Math.round(elapsed / msPerYear);
    return yearsAgo === 1 ? yearsAgo + " year ago" : yearsAgo + " years ago";
  }
};

export const formatSpotifyLink = (link: string) => {
  let embeddableLink = link.replace(
    "https://open.spotify.com/playlist/",
    "https://open.spotify.com/embed/playlist/",
  );
  embeddableLink = embeddableLink.replace(
    "https://open.spotify.com/track/",
    "https://open.spotify.com/embed/track/",
  );

  embeddableLink = embeddableLink.replace(
    "https://open.spotify.com/album/",
    "https://open.spotify.com/embed/album/",
  );

  embeddableLink = embeddableLink.replace(
    "https://open.spotify.com/artist/",
    "https://open.spotify.com/embed/artist/",
  );

  return embeddableLink;
};

export const formatYouTubeLink = (link: string) => {
  let embeddableLink = link.replace("watch?v=", "embed/");
  embeddableLink = embeddableLink.replace("youtu.be/", "youtube.com/embed/");
  embeddableLink = embeddableLink.replace("youtube.com/shorts/", "youtube.com/embed/");
  embeddableLink = embeddableLink.replace("http://", "https://");
  embeddableLink = embeddableLink.split("&")[0];
  if (!embeddableLink.startsWith("https://")) {
    embeddableLink = "https://" + embeddableLink;
  }
  return embeddableLink;
};

export const formatTikTokLink = (link: string) => {
  let embeddableLink = link.replace("/video/", "/embed/v2/");

  if (!embeddableLink.startsWith("https://")) {
    embeddableLink = "https://" + embeddableLink;
  }

  //const videoId = link.match(/(?<=tiktok\.com\/@[\w\d._-]+\/video\/)[\w\d._-]+/)?.[0];

  const matchResult = link.match(/tiktok\.com\/@([\w\d._-]+)\/video\/([\w\d._-]+)/);
  const videoId = matchResult ? matchResult[2] : null;
  return videoId ? `https://www.tiktok.com/embed/v2/${videoId}` : "";
  //return "https://www.tiktok.com/embed/v2/" + videoId;
};

export const isSpotifyTrackLink = (link: string) => {
  return link.includes("open.spotify.com/track/");
};

export const extractWistiaIdFromWistiaMediaLink = (url: string) => {
  // Define a regular expression pattern to match the video ID
  const pattern = /\/medias\/([^/]+)/;

  // Use the match method to find the pattern in the URL
  const match = url.match(pattern);

  // If a match is found, return the captured group (video ID)
  if (match) {
    return match[1];
  } else {
    // If no match is found, return empty string
    return "";
  }
};

export async function generateReferralCode(email: string) {
  const encoder = new TextEncoder();
  const data = encoder.encode(email);
  const hashBuffer = await crypto.subtle.digest("SHA-256", data);
  const hashBlob = new Blob([hashBuffer], { type: "application/octet-stream" });
  const reader = new FileReader();

  return new Promise((resolve, reject) => {
    reader.onloadend = () => {
      const result = reader.result;
      const base64String = (result as string).split(",")[1];
      resolve(base64String.substring(0, 7));
    };
    reader.onerror = reject;
    reader.readAsDataURL(hashBlob);
  });
}

export function isValidImageUrl(url: string): boolean {
  // Regex to check if string is a valid URL
  const urlPattern = new RegExp(
    "^(https?:\\/\\/)?" + // protocol
      "((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|" + // domain name and extension
      "((\\d{1,3}\\.){3}\\d{1,3}))" + // OR ip (v4) address
      "(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*" + // port and path
      "(\\?[;&a-z\\d%_.~+=-]*)?" + // query string
      "(\\#[-a-z\\d_]*)?$",
    "i",
  ); // fragment locator

  // Check if URL is valid
  if (!urlPattern.test(url)) {
    return false;
  }

  // Check for image extension
  const imagePattern = /\.(jpeg|jpg|gif|png|webp)$/i;
  return imagePattern.test(url);
}

export function replaceInstructorCodesWithDisplayInText(
  inputText: string,
  instructorInfoMap: any,
): string {
  if (!inputText) {
    return "";
  }

  console.log("going to replace the text ", instructorInfoMap);
  // Regular expression to find patterns like [[slug]]
  const slugPattern = /\[\[(.*?)\]\]/g;

  // Replace each found slug with its corresponding instructor display name
  return inputText.replace(slugPattern, (match, slug) => {
    const instructorInfo = instructorInfoMap[slug];
    if (instructorInfo) {
      return instructorInfo.instructorDisplayName;
    }

    // If there is no matching entry in the map, keep the original slug
    return match;
  });
}
