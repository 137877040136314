import { SFSchoolData, SFSessionData } from "data/SFSchools/SFSchools";

import { Session } from "~/hooks/currentSessionProvider";

import { FeaturedMedia } from "../hooks/currentSessionProvider/CurrentSessionProvider";
import { fetchApi } from "./fetchStudioApi";

export interface UserWithConfirmedAttendance {
  gradientColor?: string;
  userId: string;
  username: string;
  profilePhoto: string;
  firstName: string;
  lastName: string;
  location: string;
  featuredMedia: FeaturedMedia;
  miniBio: string;
  headline: string;
  socialInfo: { [key: string]: string };
  genres: string[];
  influences: string[];
  locationInfo: {
    city: string;
  };
  hasDismissedIntro?: boolean;
  hasDismissedOverview?: boolean;
  prePopulation?: {
    headline?: string;
    influences?: string[];
    genres?: string[];
  };
}

export const pullSessionsForUser = async (): Promise<Session[]> => {
  let response = await fetchApi({
    authRequired: true,
    path: "/pullSessionsForUser",
    method: "GET",
  });

  return response.response;
};

export const getUserWithConfirmedAttendance = async (
  userId?: string,
): Promise<UserWithConfirmedAttendance> => {
  let path = `/getUserWithConfirmedAttendance`;
  if (userId) {
    path += `?userId=${userId}`;
  }

  let response = await fetchApi({
    authRequired: true,
    path: path,
    method: "GET",
  });
  return response.response;
};

export const getUsersForUserPeerGroupWithSessionId = async (sessionSlug: string): Promise<any> => {
  let response = await fetchApi({
    path: `/getUsersForUserPeerGroupWithSessionId?session_slug=${sessionSlug}`,
    method: "GET",
    authRequired: true,
  });
  return response;
};

export const doesUserHaveMemberLibraryAccess = async (membershipSlug: string): Promise<any> => {
  let response = await fetchApi({
    authRequired: true,
    path: `/doesUserHaveMemberLibraryAccess?membership_slug=${membershipSlug}`,
    method: "GET",
  });
  return response;
};

export const getSchoolInfoWithSlug = async (slug: string): Promise<any> => {
  console.log("getting school info API ", slug);
  let response = await fetchApi({
    path: `/getSchoolInfoWithSlug?slug=${slug}`,
    method: "GET",
  });
  console.log("SCHOOLINFO response is ", response);
  return response.response;
};

export const getNextEnrollableSessionForSchool = async (slug: string): Promise<SFSessionData> => {
  let response = await fetchApi({
    path: `/getNextEnrollableSessionForSchool?slug=${slug}`,
    method: "GET",
  });
  console.log("getNextEnrollableSessionForSchool RESPONSE: ", response);
  return response.response;
};

export const setHasDismissedIntro = async (): Promise<any> => {
  let response = await fetchApi({
    authRequired: true,
    path: `/userHasDismissedIntro`,
    method: "POST",
  });

  console.log("response from setHasDismissedIntro ", response);

  return response;
};

export const setHasDismissedOverview = async (): Promise<any> => {
  let response = await fetchApi({
    authRequired: true,
    path: `/userHasDismissedOverview`,
    method: "POST",
  });

  console.log("response from setHasDismissedOverview ", response);

  return response;
};
