import React, { ReactElement, useState } from "react";

import {
  AUTH_MODAL_ENTER_EMAIL,
  AUTH_MODAL_SWITCH_TO_CREATE_ACCOUNT,
  AUTH_MODAL_SWITCH_TO_LOGIN,
} from "~/analytics/Amplitude/amplitudeConstants";
import { addUTMPropertiesToEvent } from "~/analytics/Amplitude/amplitudeUtils";
import { useAnalytics } from "~/analytics/useAnalytics";
import { JoinWaitlistForm } from "~/components/auth/JoinWaitlistForm";

import { ContinueWithEmailForm } from "../ContinueWithEmailForm";
import { CreateAccountForm } from "../CreateAccountForm";
import { LogInForm } from "../LogInForm";
import { ResetPasswordForm } from "../ResetPasswordForm";
import { AuthForm } from "../authTypes";

interface Props {
  entryForm: AuthForm;
  loginTitles?: {
    title?: string;
    subTitle?: string;
  };
}

/**
 * This component manages an entire auth flow experience from start to finish.
 * Its primary purpose is to connect all of the auth flow plumbing. You can
 * always make it to where you need to be regardless of your auth situation.
 *
 * Example:
 * ContinueWithEmail -> LogIn -> ResetPassword -> LogIn -> CreateAccount -> LogIn
 */
export const AuthFormOrchestrator = ({ entryForm, loginTitles }: Props): ReactElement => {
  const [activeAuthForm, setActiveAuthForm] = useState<AuthForm>(entryForm);
  const [initialEmail, setInitialEmail] = useState<string>();
  const { trackAmplitudeEvent } = useAnalytics();

  return (
    {
      [AuthForm.Email]: (
        <ContinueWithEmailForm
          onContinue={(email: string) => {
            setInitialEmail(email);
            setActiveAuthForm(AuthForm.CreateAccount);
            trackAmplitudeEvent(AUTH_MODAL_ENTER_EMAIL, addUTMPropertiesToEvent());
          }}
          onLogin={(email?: string) => {
            setInitialEmail(email);
            setActiveAuthForm(AuthForm.Login);
          }}
        />
      ),
      [AuthForm.CreateAccount]: (
        <CreateAccountForm
          initialEmail={initialEmail}
          onLogin={() => {
            setActiveAuthForm(AuthForm.Login);
            trackAmplitudeEvent(AUTH_MODAL_SWITCH_TO_LOGIN);
          }}
        />
      ),
      [AuthForm.Login]: (
        <LogInForm
          title={loginTitles?.title}
          subTitle={loginTitles?.subTitle}
          initialEmail={initialEmail}
          onCreateAccount={() => {
            setActiveAuthForm(AuthForm.CreateAccount);
            trackAmplitudeEvent(AUTH_MODAL_SWITCH_TO_CREATE_ACCOUNT);
          }}
          onResetPassword={() => setActiveAuthForm(AuthForm.ResetPassword)}
        />
      ),
      [AuthForm.ResetPassword]: (
        <ResetPasswordForm
          initialEmail={initialEmail}
          onLogin={() => setActiveAuthForm(AuthForm.Login)}
        />
      ),
      [AuthForm.JoinWaitlist]: <JoinWaitlistForm />,
    }?.[activeAuthForm] || <></>
  );
};
