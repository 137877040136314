import { useContext } from "react";

import { AuthContext } from "./AuthProvider";

// full context provider
// not needed for most components
export const useAllAuthFunctions = () => {
  return useContext(AuthContext);
};

// useAuth contains a subset of auth properties
// likely to be widely used throughout the app
export const useAuth = () => {
  const { user, loadingCurrentUser } = useContext(AuthContext);
  return { user, loadingCurrentUser };
};

export const useSignOut = () => {
  return useContext(AuthContext).signOut;
};

export const useUser = () => {
  return useContext(AuthContext).user;
};
