import { useEffect } from "react";

export const usePreventBodyScroll = ({
  isOpen,
  hideOuterScroll,
}: {
  isOpen: boolean;
  hideOuterScroll: boolean;
}) => {
  useEffect(() => {
    if (isOpen && hideOuterScroll) {
      document.body.style.overflow = "hidden";
      // hack for iOS Safari
      document.body.style.position = "fixed";
      document.body.style.width = "100%";
      document.body.addEventListener("scroll", (e) => {
        e.preventDefault();
      });

      return () => {
        document.body.style.overflow = "auto";
        document.body.style.position = "initial";
        document.body.removeEventListener("scroll", (e) => {
          e.preventDefault();
        });
      };
    }
  }, [hideOuterScroll, isOpen]);
};
