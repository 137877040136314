import { ExperienceId } from "data/experiences/experiences";

import { Result } from "../util/resultType";
import { ApiStatus, fetchApiAuthenticated, fetchApiPublic, fetchApi } from "./fetchStudioApi";

// TODO: build out this model more as we uncover more.
// this should be maintained to reflect all possible server responses.
// if you encounter properties returned from server which are not on this model, please add it!
export interface ExperienceEnrollmentInfo {
  miniBio?: string;
  intention?: string;
  experienceLevel?: number;
  classBatchName: string;
  classId: ExperienceId;
  classTitle: string;
  classStartDate: string;
  classEndDate: string;
  contentCompletedTags: {
    [key: string]: string;
  };
  enrollDate: string; // this is in UTC unfortunately
  enrollmentType: EnrollmentType;
  enrollmentId: string;
  peerGroupId: string;
  projectSubmissionTags: {
    [key: string]: string | { [key: string]: string };
  };
  // Only exists on the auth user fetched by /getAuthUserWithSaleInfo
  saleInfo?: {
    amount: number;
    cardLast4: string;
    monthlyCreditsApplied: number;
    purchaseMethod: string;
  };
  streamToken?: string;
  classMediaType?: string;
  hasOrderedDavidBlaineCards?: boolean;
  feedbackInfoByTag?: {
    [contentTag: string]: {
      hasSeenPeerFeedbackCardForProject: boolean;
    };
  };
}

export enum EnrollmentType {
  Unknown = "unknown",
  Challenge = "challenge",
  // TODO: transition the string value from "class_30_day" to "class_paid"
  // https://www.notion.so/monthly/Update-all-references-to-class_30_day-enum-to-class-838c1ff7d20f4a91858c0a7777d80b1c
  ClassPaid = "class_30_day",
}

export interface PriceOfferInfo {
  expirationDateSeen: number; // number since it's milliseconds since epoch in UTC
  lastSeenCurrentOffer: number; // number since it's milliseconds since epoch in UTC
}

export interface CreatorInfo {
  isCreator?: boolean;
  creatorName?: string;
  showEarningsDashboard?: boolean;
  canCreateClasses?: boolean;
  wistiaProjectHash?: string;
  wistiaProjectId?: number;
  wistiaProjectName?: string;
  wistiaToken?: string;
  stripeExpressAccountId?: string;
  stripeExpressDetailsSubmitted?: boolean;
}

export interface StudioUser {
  classesEnrolled: Record<string, ExperienceEnrollmentInfo>;
  email: string;
  firstName: string;
  lastName: string;
  gifts: {};
  monthlyCreditInfo: {
    monthlyCredit: number;
  };
  profilePhoto?: string;
  userId: string;
  username: string;
  membershipStatus?: string;
  locationInfo?: {
    city?: string;
    countryCode?: string;
  };
  stripeCustomerId?: string;
  offersSeenInfo?: Record<string, PriceOfferInfo>;
  userProductPurchases?: [];
  hasStorefront?: boolean;
  signUpDate?: string;
  miniBio?: string;
  creatorInfo?: CreatorInfo;
  currentSessionSlug?: string; // this is the current session slug the user is in, for Music School only
  hasDismissedIntro?: boolean;
}

interface FetchAuthenticatedUserResponse {
  message: string;
  status: string;
  user: StudioUser;
}

export const fetchAuthenticatedUser = async (): Promise<Result<StudioUser>> => {
  try {
    const result = (await fetchApiAuthenticated({
      path: "/getAuthUser",
      method: "POST",
      body: new FormData(),
    })) as FetchAuthenticatedUserResponse;
    if (result.status === ApiStatus.SUCCESS) {
      return {
        type: "success",
        value: result.user,
      };
    } else {
      return {
        type: "error",
        error: new Error(`getUserAuth returned status ${result.status}`),
      };
    }
  } catch (error) {
    return {
      type: "error",
      error: error as Error,
      message: "Error getting user",
    };
  }
};

export const fetchUserById = async (userId: string) => {
  const path = `/getUser?userId=${userId}`;
  const response = await fetchApiPublic({ path, method: "GET" });

  console.log("fetchuserbyid response", response);
  return response.user;
};

export const saveLastSeenOfferInfo = async (
  offerKey: string,
  expDateSeen: number,
  offerLastSeen: number,
) => {
  try {
    // If there isn't even an exp date seen, don't send it
    if (!expDateSeen) {
      return;
    }

    const now = new Date().getTime();
    let lastSentOfferInfoString = localStorage.getItem("lastSentOfferInfo");
    const timeToWaitUntilSendingAgain = 1000 * 60 * 5; // 5 mins
    if (lastSentOfferInfoString) {
      let lastSentOfferInfo = parseInt(lastSentOfferInfoString);
      if (now - lastSentOfferInfo < timeToWaitUntilSendingAgain) {
        //console.log("not sending save last seen offer info because we sent it recently");
        return;
      }
    }

    console.log("sending save last seen offer info ");
    localStorage.setItem("lastSentOfferInfo", now.toString());
    const response = await fetchApi({
      path: "/saveLastSeenOfferInfo",
      method: "POST",
      body: JSON.stringify({
        offer_key: offerKey,
        exp_date_seen: expDateSeen,
        offer_last_seen: offerLastSeen,
      }),
      isJsonPayload: true,
      authRequired: true,
    });
    return { type: ApiStatus.SUCCESS, value: response };
  } catch (error) {
    console.warn("save last seen offer info error", error);
    return { type: ApiStatus.ERROR, error };
  }
};

export const confirmEmail = async (email: string) => {
  try {
    const data = new FormData();
    data.append("email", email);
    await fetchApiPublic({
      path: "/confirmEmail",
      method: "POST",
      body: data,
      isJsonPayload: false,
    });
    return { type: ApiStatus.SUCCESS, value: null };
  } catch (error) {
    console.warn("Confirm email error", error);
    return { type: ApiStatus.ERROR, error };
  }
};

export const collectEmail = async (args: {
  email: string;
  classInterestedIn?: string;
  extraTags?: any;
}) => {
  const { email, classInterestedIn, extraTags } = args;
  try {
    const data = new FormData();
    data.append("email", email);
    console.log("GOING TO SEND THE FOLLOWING ", classInterestedIn);
    if (classInterestedIn) {
      data.append("classInterestedIn", classInterestedIn);
    }
    if (extraTags) {
      data.append("extraTags", JSON.stringify(extraTags));
    }
    const response = await fetchApiPublic({
      path: "/collectEmail",
      method: "POST",
      body: data,
      isJsonPayload: false,
    });
    return { type: ApiStatus.SUCCESS, value: response };
  } catch (error) {
    console.warn("Confirm email error", error);
    return { type: ApiStatus.ERROR, error };
  }
};

export const fetchIsAdmin = async () => {
  try {
    const result: boolean = await fetchApi({
      path: "/isAdmin",
      method: "GET",
      authRequired: true,
    }).then((res) => res.isAdmin);
    return result;
  } catch (error) {
    return false;
  }
};

export const checkIsAdmin = async () => {
  try {
    const result: boolean = await fetchApi({
      path: "/checkIsAdmin",
      method: "GET",
      authRequired: true,
    }).then((res) => res.isAdmin);
    return result;
  } catch (error) {
    return false;
  }
};

export const getMembershipsInfoForUser = async () => {
  try {
    const result: boolean = await fetchApi({
      path: "/getMembershipsInfoForUser",
      method: "GET",
      authRequired: true,
    }).then((res) => res);
    return result;
  } catch (error) {
    return false;
  }
};

export const getProgramsInfoForUser = async (slug: string) => {
  try {
    const result: Record<string, any> = await fetchApi({
      path: "/getProgramInfoForUserAndSlug?slug=" + slug,
      method: "GET",
      authRequired: true,
    }).then((res) => res);
    return result;
  } catch (error) {
    return false;
  }
};

export const saveProgramInfoForUserAndSlug = async (
  slug: string,
  programInfo: Record<string, any>,
) => {
  try {
    const response = await fetchApi({
      path: "/saveProgramInfoForUserAndSlug",
      method: "POST",
      body: JSON.stringify({
        slug: slug,
        program_info: programInfo,
      }),
      isJsonPayload: true,
      authRequired: true,
    });

    console.log("RESPONSE IS ", response);
    return { type: ApiStatus.SUCCESS, value: response };
  } catch (error) {
    return { type: ApiStatus.ERROR, error };
  }
};
