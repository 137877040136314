import { ApiStatus, fetchApiAuthenticated } from "~/api/fetchStudioApi";
import { Result } from "~/util/resultType";

export enum MembershipStatus {
  active = "active",
  inactive = "inactive",
  suspended = "suspended",
}

export enum MembershipTier {
  Standard_V2 = "standard-v2",
  Standard_V1 = "standard-v1",
  Premium_V1 = "premium-v1",
  Tier1 = "tier1",
  Executive = "executive",
}

export interface MembershipInfo {
  tokenCount: number;
  status: MembershipStatus;
  tier: MembershipTier;
  enrollmentDatetime: string;
  periodEndDatetime: string;
  renewing: boolean;
  isInTrial: boolean;
  trialDays?: number;
}

interface FetchMembershipInfoResponse {
  status: string;
  subscription: MembershipInfo;
}

export const fetchUserMembershipInfo = async (): Promise<Result<MembershipInfo>> => {
  try {
    const result = (await fetchApiAuthenticated({
      path: "/subscription",
      method: "GET",
    })) as FetchMembershipInfoResponse;
    if (result.status === ApiStatus.SUCCESS) {
      return {
        type: "success",
        value: result.subscription,
      };
    } else {
      return {
        type: "error",
        error: new Error(`fetchMembershipInfo returned status ${result.status}`),
      };
    }
  } catch (error) {
    return {
      type: "error",
      error: error as Error,
      message: "Error getting membership info for user",
    };
  }
};
