import React, { ReactElement, useState } from "react";

import { useRouter } from "next/router";

import { Button, LargeInput, TermsAndPrivacyDisclaimer } from "studio-design-system";

import { useAnalytics } from "~/analytics/useAnalytics";
import { getProductCollectionWithSlug } from "~/api/collectionsApi";
import { getProductData } from "~/api/productApi";
import { collectEmail } from "~/api/userApi";
import { GENERIC_ERROR_MESSAGE } from "~/util/commonErrors";
import { getProductIdFromSlug } from "~/util/stringUtils";

import { isEmailValidSimple } from "../../../util/validateEmail";
import * as baseStyles from "../FormLayout.css";

const EMAIL_VALIDITY_SCORE_SHOW_CHECK_BELOW = 0.25;

interface Props {
  experience?: string;
  onContinue: (email: string) => void;
  onLogin: (email?: string) => void;
}
export const ContinueWithEmailForm = ({ onContinue, onLogin }: Props): ReactElement => {
  const [isWaiting, setIsWaiting] = useState(false);
  const [isErrorMessageShown, setIsErrorMessageShown] = useState(false);
  const [email, setEmail] = useState("");
  const router = useRouter();
  const { classId, productIdSlug, storefrontSlug, collectionSlug } = router.query;
  const [errorMessage, setErrorMessage] = useState("");
  const [hasSeenValidationWarning, setHasSeenValidationWarning] = useState(false);

  const { trackEmailCollection } = useAnalytics();

  const validateForm = () => {
    return email.length > 0 && isEmailValidSimple(email);
  };

  const setEmailValidationError = (message: string) => {
    setErrorMessage(message);
    setIsErrorMessageShown(true);
    setIsWaiting(false);
    setHasSeenValidationWarning(true);
  };

  const continueWithEmail = async () => {
    console.log("clicked continue with email");
    if (!validateForm()) {
      setIsErrorMessageShown(true);
      setErrorMessage("Invalid email");
      return;
    } else {
      setIsErrorMessageShown(false);
      setErrorMessage("");
    }

    console.log("passed validation");

    setIsWaiting(true);
    if (email.length === 0) {
      setIsWaiting(false);
      setErrorMessage("Please input an email");
      setIsErrorMessageShown(true);
      return;
    }

    //let interestedInId = productIdSlug ? getProductIdFromSlug(productIdSlug as string) : "";

    // TODO: update later to also send storefront slug to get the correct product id if we have multiple products with same slug
    let productData = undefined;
    let isCollection = false;

    console.log("about to load");
    if (storefrontSlug && productIdSlug) {
      productData = await getProductData(productIdSlug as string, storefrontSlug as string);
    } else if (productIdSlug && !storefrontSlug) {
      productData = await getProductData(productIdSlug as string);
    } else if (collectionSlug) {
      isCollection = true;
      productData = await getProductCollectionWithSlug(collectionSlug as string);
    }
    let productId = productData?.id;
    let interestedInId = productId;
    console.log("loaded product stuff ", productData);
    console.log("interestedInId: ", interestedInId, productIdSlug, productData);

    interestedInId = interestedInId ? `${interestedInId}` : "general";
    let extraTags = [];
    if (isCollection) {
      extraTags.push("isCollection");
    }
    if (productData) {
      trackEmailCollection(interestedInId, extraTags, email, productData);
    }

    const response = await collectEmail({ email, classInterestedIn: interestedInId });
    console.log("collected email ", response);
    if (response.error) {
      setErrorMessage(GENERIC_ERROR_MESSAGE);
      return;
    }
    const data = response.value;
    if (data && data.email_validation_info) {
      if (!hasSeenValidationWarning && data.email_validation_info.suggestion) {
        setEmailValidationError(
          `For your email, did you mean to use ${data.email_validation_info.suggestion}?`,
        );
      } else if (
        !hasSeenValidationWarning &&
        data.email_validation_info.score <= EMAIL_VALIDITY_SCORE_SHOW_CHECK_BELOW
      ) {
        setEmailValidationError("Make sure to check that your email is correct before continuing.");
      } else {
        onContinue(email);
      }
    } else {
      console.log("didn't go in if statement ", data);
    }
    onContinue(email);
  };

  return (
    <>
      <div className={baseStyles.container}>
        <div className={baseStyles.topSection}>
          <h3 className={baseStyles.title}>Get Started</h3>
        </div>

        <div className={baseStyles.sectionRow}>
          <LargeInput
            type="email"
            placeholder="Email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            onKeyDown={(e) => {
              e.key === "Enter" && continueWithEmail();
            }}
            errorMessage={isErrorMessageShown && errorMessage}
            shouldAutoFocus
            autoComplete="email"
          />
        </div>

        <div className={baseStyles.termsAgreementRow}>
          <TermsAndPrivacyDisclaimer />
        </div>

        <div className={baseStyles.buttonSection}>
          <div>
            <Button
              className={baseStyles.primaryButton}
              onClick={continueWithEmail}
              isLoading={isWaiting}
            >
              Continue with email
            </Button>
          </div>
          <div className={baseStyles.bottomAltActionContainer}>
            Already have an account?
            <span
              className={baseStyles.altActionSpaceOnLeft}
              onClick={() => {
                onLogin(email);
              }}
            >
              Log In
            </span>
          </div>
        </div>
      </div>
    </>
  );
};
