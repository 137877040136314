import { creator } from "~/components/common/CartPill/CartPill.css";

interface ReferralInfo {
  link: string;
  ref: string;
  utmSource: string;
}

const creatorReferralInfo: Record<string, ReferralInfo> = {
  "andrew-huang": {
    ref: "ANDREW",
    utmSource: "andrew",
    link: "andrew",
  },
  "blanks-music": {
    ref: "BLANKS",
    utmSource: "blanks",
    link: "blanks",
  },
  "casey-neistat": {
    ref: "CASEY",
    utmSource: "casey",
    link: "casey",
  },
  "charlie-puth": {
    ref: "CHARLIEPUTH",
    utmSource: "charlie",
    link: "charlie",
  },
  "christina-tosi": {
    ref: "CHRISTINATOSI",
    utmSource: "tosi",
    link: "tosi",
  },
  "claire-saffitz": {
    ref: "CLAIRE",
    utmSource: "claire",
    link: "claire",
  },
  "daria-callie": {
    ref: "DARIA",
    utmSource: "daria",
    link: "daria",
  },
  "david-blaine": {
    ref: "DAVIDBLAINE",
    utmSource: "davidblaine",
    link: "davidblaine",
  },
  "her-music": {
    ref: "HER",
    utmSource: "her",
    link: "her",
  },
  "idina-menzel": {
    ref: "IDINA",
    utmSource: "idina",
    link: "idina",
  },
  kevinparry: {
    ref: "KEVINPARRY",
    utmSource: "kevinparry",
    link: "kevinparry",
  },
  "kygo-music": {
    ref: "KYGO",
    utmSource: "kygo",
    link: "kygo",
  },
  "kyle-hanagami": {
    ref: "KYLE",
    utmSource: "kyle",
    link: "kyle",
  },
  "louis-bell": {
    ref: "LOUISBELL",
    utmSource: "louisbell",
    link: "louisbell",
  },
  "mark-rober": {
    ref: "MARKROBER",
    utmSource: "markrober",
    link: "markrober",
  },
  "pasek-and-paul": {
    ref: "PASEKANDPAUL",
    utmSource: "pasekandpaul",
    link: "pasekandpaul",
  },
  "ptx-official": {
    ref: "PENTATONIX",
    utmSource: "pentatonix",
    link: "pentatonix",
  },
  "ryan-tedder": {
    ref: "RYAN",
    utmSource: "ryan",
    link: "ryan",
  },
  "shane-mcanally": {
    ref: "SHANE",
    utmSource: "shane",
    link: "shane",
  },
  "shoe-surgeon": {
    ref: "SHOESURGEON",
    utmSource: "shoesurgeon",
    link: "shoesurgeon",
  },
  "simone-giertz": {
    ref: "SIMONE",
    utmSource: "simone",
    link: "simone",
  },
  "stevie-mackey": {
    ref: "STEVIE",
    utmSource: "stevie",
    link: "stevie",
  },
  "ten-hundred": {
    ref: "TENHUNDRED",
    utmSource: "tenhun",
    link: "tenhun",
  },
  "tainy-music": {
    ref: "TAINY",
    utmSource: "tainy",
    link: "tainy",
  },
};

export const getReferralLinkParams = (creatorSlug: string) => {
  if (creatorSlug in creatorReferralInfo) {
    const referralInfo = creatorReferralInfo[creatorSlug];
    return `?ref=${referralInfo.ref}&code=a&utm_source=${referralInfo.utmSource}&utm_medium=instructor`;
  } else {
    //TODO: This is mostly a placeholder — we need to figure out what we'll want for new MCP creators.
    return `?ref=${creatorSlug}&code=a&utm_source=${creatorSlug}&utm_medium=instructor`;
  }
};

export const getStorefrontReferralLink = (creatorSlug: string) => {
  if (creatorSlug in creatorReferralInfo) {
    return creatorReferralInfo[creatorSlug].link;
  } else {
    return creatorSlug + getReferralLinkParams(creatorSlug);
  }
};

export const isMarketingUrlAvailable = (url: string) => {
  if (url in creatorReferralInfo) {
    return false;
  }

  for (let key in creatorReferralInfo) {
    if (creatorReferralInfo.hasOwnProperty(key)) {
      if (creatorReferralInfo[key].link.includes(url)) {
        return false;
      }
    }
  }
  return true;
};
