import React, { ReactElement, useState } from "react";

import { useRouter } from "next/router";
import { useForm, SubmitHandler, Controller } from "react-hook-form";
import Linkify from "react-linkify";

import { Button, LargeInput, TermsAndPrivacyDisclaimer } from "studio-design-system";

import {
  CREATE_ACCOUNT_FORM_SUBMIT_FAILURE,
  CREATE_ACCOUNT_FORM_SUBMIT_SUCCESS,
} from "~/analytics/Amplitude/amplitudeConstants";
import { addUTMPropertiesToEvent, setUserForAmplitude } from "~/analytics/Amplitude/amplitudeUtils";
import { useAnalytics } from "~/analytics/useAnalytics";
import { useAllAuthFunctions } from "~/hooks/auth/useAuth";
import { useAllAuthModalFunctions } from "~/hooks/auth/useAuthModal";

import { emailValidRegex } from "../../../util/validateEmail";
import * as baseStyles from "../FormLayout.css";

export const NAME_MAX_LENGTH = 20;

interface IFormInput {
  email: string;
  firstName: string;
  lastName: string;
  password: string;
}

export const TEST_LABELS = {
  BUTTONS: {
    CREATE_ACCOUNT: "buttons.create_account",
    LOG_IN: "buttons.log_in",
  },
  FIELDS: {
    EMAIL: "fields.email",
    FIRST_NAME: "fields.first_name",
    LAST_NAME: "fields.last_name",
    PASSWORD: "fields.password",
  },
  CONTENT: {
    TERMS_AND_PRIVACY_DISCLAIMER: "content.terms_and_privacy_disclaimer",
  },
};

interface Props {
  initialEmail?: string;
  onLogin: () => void;
}

export const CreateAccountForm = ({ initialEmail = "", onLogin }: Props): ReactElement => {
  const { signUp } = useAllAuthFunctions();
  const { closeAuthModal } = useAllAuthModalFunctions();
  const { trackCreateAccount, trackAmplitudeEvent } = useAnalytics();
  const router = useRouter();
  const { classId, ref, code } = router.query;
  const [submitErrorMessage, setSubmitErrorMessage] = useState<string | undefined>();
  const campaignValue = localStorage.getItem("aaCampaign");
  const tierId = localStorage.getItem("tierId");
  const title = initialEmail ? "Nice! Almost done..." : "Create Account";

  const {
    formState: { errors, isSubmitting },
    control,
    handleSubmit,
  } = useForm<IFormInput>({
    defaultValues: {
      email: initialEmail,
    },
  });

  const onSubmit: SubmitHandler<IFormInput> = async (data: IFormInput) => {
    setSubmitErrorMessage("");
    const result = await signUp({
      email: data.email,
      password: data.password,
      firstName: data.firstName,
      lastName: data.lastName,
    });
    if (result.type === "success") {
      setUserForAmplitude(result.value);
      trackCreateAccount();
      trackAmplitudeEvent(CREATE_ACCOUNT_FORM_SUBMIT_SUCCESS, addUTMPropertiesToEvent());

      console.log("react query info is ", router.query);
      if (router.query && router.query.redirect) {
        let redirectPage = router.query.redirect as string;
        const ref = localStorage.getItem("ref");
        const code = localStorage.getItem("code");
        // if (ref) {
        //   redirectPage += `&ref=${ref}`;
        // }
        // if (code) {
        //   redirectPage += `&code=${code}`;
        // }

        console.log("redirectPage is ", redirectPage);

        await router.push(redirectPage);
        closeAuthModal();
        return;
      }

      // if (classId) {
      //   let checkoutUrl = `/checkout?classId=${classId}&planSelected=transactional&tierId=${tierId}`;
      //   const ref = localStorage.getItem("instructorRef");
      //   const code = localStorage.getItem("code");
      //   if (ref) {
      //     checkoutUrl += `&ref=${ref}`;
      //   }
      //   if (code) {
      //     checkoutUrl += `&code=${code}`;
      //   }
      //   await router.push(checkoutUrl);
      //   return;
      // } else {
      //   await router.push("/v2/checkout/standard-v1");
      // }
      closeAuthModal();
    } else {
      setSubmitErrorMessage(result.error.message || "Error");
      trackAmplitudeEvent(CREATE_ACCOUNT_FORM_SUBMIT_FAILURE, addUTMPropertiesToEvent());
    }
  };

  return (
    <div className={baseStyles.container}>
      <div className={baseStyles.topSection}>
        <div className={baseStyles.titlesColumn}>
          <h3 className={baseStyles.title}>{title}</h3>
          {initialEmail && (
            <div className={baseStyles.subTitle}>
              {"Write your name and a password and you'll be good to go."}
            </div>
          )}
        </div>
      </div>
      <div className={baseStyles.formControls}>
        <Controller
          control={control}
          rules={{
            pattern: {
              value: emailValidRegex,
              message:
                "This email address looks invalid. If we’re wrong, please contact support@studio.com.",
            },
            required: { value: true, message: "You must provide an email." },
          }}
          render={({ field: { onChange, value } }) => (
            <LargeInput
              testLabel={TEST_LABELS.FIELDS.EMAIL}
              type="email"
              placeholder="Email"
              errorMessage={errors.email?.message}
              shouldAutoFocus
              autoComplete="email"
              onChange={onChange}
              value={value}
              className={`${baseStyles.inputWrapper} ${baseStyles.inputWithoutMargin}`}
            />
          )}
          name="email"
        />

        <div className={baseStyles.sectionRow}>
          <Controller
            control={control}
            rules={{
              required: {
                value: true,
                message: "You must provide a first name.",
              },
              maxLength: {
                value: NAME_MAX_LENGTH,
                message: `First name can't be longer than ${NAME_MAX_LENGTH} characters long`,
              },
            }}
            render={({ field: { onChange, value } }) => (
              <LargeInput
                testLabel={TEST_LABELS.FIELDS.FIRST_NAME}
                placeholder="First name"
                errorMessage={errors.firstName?.message}
                autoComplete="given-name"
                shouldAutoFocus={!!initialEmail}
                onChange={onChange}
                value={value}
                className={`${baseStyles.inputWrapper} ${baseStyles.inputWithoutMargin}`}
              />
            )}
            name="firstName"
          />
          <Controller
            control={control}
            rules={{
              required: {
                value: true,
                message: "You must provide a last name.",
              },
              maxLength: {
                value: NAME_MAX_LENGTH,
                message: `Last name can't be longer than ${NAME_MAX_LENGTH} characters long`,
              },
            }}
            render={({ field: { onChange, value } }) => (
              <LargeInput
                testLabel={TEST_LABELS.FIELDS.LAST_NAME}
                placeholder="Last name"
                maxLength={NAME_MAX_LENGTH}
                errorMessage={errors.lastName?.message}
                autoComplete="family-name"
                onChange={onChange}
                value={value}
                className={`${baseStyles.inputWrapper} ${baseStyles.inputWithoutMargin}`}
              />
            )}
            name="lastName"
          />
        </div>
        <Controller
          control={control}
          rules={{
            required: {
              value: true,
              message: "You must provide a password.",
            },
          }}
          render={({ field: { onChange, value } }) => (
            <LargeInput
              testLabel={TEST_LABELS.FIELDS.PASSWORD}
              placeholder="Password"
              type="password"
              onKeyDown={(e) => e.key === "Enter" && handleSubmit(onSubmit)()}
              errorMessage={errors.password?.message}
              autoComplete="new-password"
              onChange={onChange}
              value={value}
              className={`${baseStyles.inputWrapper} ${baseStyles.inputWithoutMargin}`}
            />
          )}
          name="password"
        />
      </div>

      {submitErrorMessage && (
        <div className={baseStyles.generalFormError}>
          <Linkify>{submitErrorMessage}</Linkify>
        </div>
      )}

      <div
        className={baseStyles.termsAgreementRow}
        data-testid={TEST_LABELS.CONTENT.TERMS_AND_PRIVACY_DISCLAIMER}
      >
        <TermsAndPrivacyDisclaimer />
      </div>

      <div>
        <Button
          data-testid={TEST_LABELS.BUTTONS.CREATE_ACCOUNT}
          data-cy={TEST_LABELS.BUTTONS.CREATE_ACCOUNT}
          onClick={handleSubmit(onSubmit)}
          isLoading={isSubmitting}
          className={baseStyles.primaryButton}
        >
          Create Account
        </Button>
      </div>

      <div className={baseStyles.bottomAltActionContainer}>
        Already have an account?
        <span
          data-testid={TEST_LABELS.BUTTONS.LOG_IN}
          onClick={onLogin}
          className={baseStyles.altActionSpaceOnLeft}
        >
          Log In
        </span>
      </div>
    </div>
  );
};
