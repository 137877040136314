import { Auth } from "@aws-amplify/auth";
import * as Sentry from "@sentry/nextjs";
import { v4 as uuidv4 } from "uuid";

import { ApiStatus, fetchApiAuthenticated } from "~/api/fetchStudioApi";
import { fetchServerUp } from "~/api/health";
import { fetchAuthenticatedUser, StudioUser } from "~/api/userApi";
import { getUserFacingCognitoError } from "~/util/cognitoUtils";
import {
  CREATE_ACCOUNT_FAILURE_MESSAGE,
  GENERIC_ERROR_MESSAGE,
  NETWORK_FAILURE_ERROR_MESSAGE,
} from "~/util/commonErrors";
import { Result } from "~/util/resultType";

export const getCurrentAuthUser = async (): Promise<Result<StudioUser>> => {
  try {
    await Auth.currentAuthenticatedUser();
    const getUserResponse = await fetchAuthenticatedUser();
    if (getUserResponse.type === "success") {
      const user = getUserResponse.value;
      return { type: "success", value: user };
    } else {
      return {
        type: "error",
        error: getUserResponse.error,
        message: getUserResponse.message || GENERIC_ERROR_MESSAGE,
      };
    }
  } catch (error) {
    Sentry.captureException(error);
    return { type: "error", error: error as Error };
  }
};

export type AuthSignInArgs = {
  email: string;
  password: string;
};
export const authSignIn = async ({
  email,
  password,
}: AuthSignInArgs): Promise<Result<StudioUser>> => {
  const lowercaseEmail = email.toLowerCase();
  try {
    await Auth.signIn(lowercaseEmail, password);
    const getUserResponse = await fetchAuthenticatedUser();
    if (getUserResponse.type === "success") {
      const user = getUserResponse.value;
      return { type: "success", value: user };
    } else {
      const errorMessage = getUserFacingCognitoError(getUserResponse.error.message, "login");
      return {
        type: "error",
        error: getUserResponse.error,
        message: errorMessage,
      };
    }
  } catch (error) {
    Sentry.captureException(error);
    let message;
    if (error instanceof Error) {
      message = getUserFacingCognitoError(error.message, "login");
    } else {
      message =
        "There was an error signing in to your account. Please try again or contact us at support@studio.com";
    }
    return {
      type: "error",
      error: error as Error,
      message,
    };
  }
};

export const authSignOut = async (): Promise<Result<null>> => {
  try {
    await Auth.signOut();
    return { type: "success", value: null };
  } catch (error) {
    Sentry.captureException(error);
    return { type: "error", error: error as Error };
  }
};

export type AuthSignUpArgs = {
  firstName: string;
  lastName: string;
  email: string;
  password: string;
};
export const authSignUp = async ({
  firstName,
  lastName,
  email,
  password,
}: AuthSignUpArgs): Promise<Result<StudioUser>> => {
  try {
    const isServerUp = await fetchServerUp();
    if (!isServerUp) {
      return {
        type: "error",
        error: new Error(NETWORK_FAILURE_ERROR_MESSAGE),
        message: NETWORK_FAILURE_ERROR_MESSAGE,
      };
    }

    const lowercaseEmail = email.toLowerCase();

    const uuidUsername = uuidv4(); // random username, since 'email' is a valid login alias

    // use Cognito to signUp(), and then immediately call signIn() to get local tokens. stupid, I know.
    await Auth.signUp({
      username: uuidUsername,
      password,
      attributes: { email: lowercaseEmail },
    });
    const cognitoUser = await Auth.signIn(uuidUsername, password);

    const formData = new FormData();
    formData.append("userId", uuidUsername);
    formData.append("firstName", firstName);
    formData.append("lastName", lastName);
    formData.append("email", lowercaseEmail);
    const createStudioUserRes = await fetchApiAuthenticated({
      path: "/createUser",
      method: "POST",
      body: formData,
    });

    if (createStudioUserRes.status !== ApiStatus.SUCCESS) {
      // If backend creation failed, delete the Cognito entry so the user can try again
      const createStudioUserError = new Error(
        `Backend createUser for ${lowercaseEmail} failed, deleting cognito user`,
      );
      console.warn(createStudioUserError);
      Sentry.captureException(createStudioUserError);
      cognitoUser.deleteUser((error?: Error) => {
        if (error) {
          throw error;
        }
      });
      // Set email_verified to True for existing cognito user
      // This prevents the user with the email from being locked out
      // of logging in if they try to create a new account
      const formData = new FormData();
      formData.append("email", lowercaseEmail);
      await fetchApiAuthenticated({
        path: "/confirmEmail",
        method: "POST",
        body: formData,
      });

      return {
        type: "error",
        error: new Error(createStudioUserRes.status),
        message: createStudioUserRes.message || CREATE_ACCOUNT_FAILURE_MESSAGE,
      };
    }

    const getUserResult = await fetchAuthenticatedUser();
    if (getUserResult.type === "success") {
      return { type: "success", value: getUserResult.value };
    } else {
      Sentry.captureException(getUserResult.error);
      return { type: "error", error: getUserResult.error };
    }
  } catch (error) {
    console.log("create user error", error);
    Sentry.captureException(error);
    return {
      type: "error",
      error: error as Error,
      message: error instanceof Error ? error.message : "",
    };
  }
};

export const authDeleteUser = async (): Promise<Result<null>> => {
  try {
    const cognitoUser = await Auth.currentAuthenticatedUser();
    await cognitoUser.deleteUser;
    return { type: "success", value: null };
  } catch (error) {
    Sentry.captureException(error);
    return { type: "error", error: error as Error };
  }
};

export const authSendResetPasswordEmail = async (email: string): Promise<Result<null>> => {
  try {
    await Auth.forgotPassword(email);
    return { type: "success", value: null };
  } catch (error) {
    Sentry.captureException(error);
    return { type: "error", error: error as Error };
  }
};
