import React, { ReactElement, useState } from "react";

import { Auth } from "aws-amplify";

import { Button, CircleCheckIcon, LargeInput } from "studio-design-system";

import { ApiStatus } from "~/api/fetchStudioApi";
import { confirmEmail } from "~/api/userApi";

import * as baseStyles from "../FormLayout.css";
import * as styles from "./ResetPasswordForm.css";

export const INVALID_EMAIL_OR_PASSWORD_ERROR_MESSAGE = "Invalid email or password";
export const ERROR_SIGNING_IN_ERROR_MESSAGE =
  "There was an error signing in to your account. Please try again or contact us.";
export const USER_NOT_FOUND_ERROR_MESSAGE = "Could not find a user with that email...";
export const EMAIL_BLANK_ERROR_MESSAGE = "Please input an email.";
export const VERIFICATION_CODE_BLANK_ERROR_MESSAGE =
  "Please input the verification code from your email.";
export const VERIFICATION_CODE_INVALID_ERROR_MESSAGE =
  "Invalid verification code provided, please try again.";

export const validatePassword = (password: string) => {
  return password.length < 8 ? "Passwords must be at least 8 characters long." : "";
};

const PASSWORD_RESET_FORM_STATES = {
  EMAIL: "EMAIL",
  RESET_CODE: "CODE",
  NEW_PASSWORD: "NEWPASSWORD",
};

interface Props {
  initialEmail?: string;
  onLogin: () => void;
}

export const ResetPasswordForm = ({ initialEmail = "", onLogin }: Props): ReactElement => {
  const isAuthenticated = false;

  const [isWaiting, setIsWaiting] = useState(false);
  const [isCompleted, setIsCompleted] = useState(false);
  const [isErrorMessageShown, setIsErrorMessageShown] = useState(false);
  const [email, setEmail] = useState(initialEmail);
  const [password, setPassword] = useState("");
  const [verificationCode, setVerificationCode] = useState("");
  const [inPhase, setInPhase] = useState(PASSWORD_RESET_FORM_STATES.EMAIL);
  const [errorMessage, setErrorMessage] = useState(INVALID_EMAIL_OR_PASSWORD_ERROR_MESSAGE);

  const onPasswordChange = (value: string) => {
    setPassword(value);
  };

  const onVerificationCodeChange = (value: string) => {
    setVerificationCode(value);
  };

  const onEmailChange = (value: string) => {
    setEmail(value.toLowerCase());
  };

  const sendCodeToEmail = async () => {
    setIsWaiting(true);

    if (email.length === 0) {
      setIsWaiting(false);
      setIsErrorMessageShown(true);
      setErrorMessage(EMAIL_BLANK_ERROR_MESSAGE);
      return;
    }

    const confirmEmailResult = await confirmEmail(email);
    if (confirmEmailResult.type === ApiStatus.ERROR) {
      setErrorMessage(ERROR_SIGNING_IN_ERROR_MESSAGE);
      return;
    }

    try {
      await Auth.forgotPassword(email);
      setInPhase(PASSWORD_RESET_FORM_STATES.RESET_CODE);
      setIsErrorMessageShown(false);
      setIsWaiting(false);
    } catch (err: any) {
      setErrorMessage(
        err.code === "UserNotFoundException" ? USER_NOT_FOUND_ERROR_MESSAGE : err.message,
      );

      setIsErrorMessageShown(true);
      setIsWaiting(false);
    }
  };

  const inputVerificationCode = () => {
    if (!verificationCode) {
      setErrorMessage(VERIFICATION_CODE_BLANK_ERROR_MESSAGE);
      setIsErrorMessageShown(true);
    } else {
      setInPhase(PASSWORD_RESET_FORM_STATES.NEW_PASSWORD);
      setIsErrorMessageShown(false);
    }
  };

  const changeToNewPassword = async () => {
    const username = email;
    const newPassword = password;

    const passwordValidationError = validatePassword(newPassword);

    if (passwordValidationError) {
      setErrorMessage(passwordValidationError);
      setIsErrorMessageShown(true);
      return;
    }

    setIsWaiting(true);

    let isUserAuthenticated = true;
    try {
      await Auth.currentAuthenticatedUser();
    } catch (err) {
      isUserAuthenticated = false;
    }

    try {
      await Auth.forgotPasswordSubmit(username, verificationCode, newPassword);
      // Sign in with the new password and then sign out globally to
      // invalidate refresh tokens in other browsers. This is a bit hacky
      // but Amplify doesn't provide any alternatives
      await Auth.signIn(username, newPassword);
      await Auth.signOut({ global: true });
      if (isUserAuthenticated) {
        // sign the user back in if they were authenticated before. this
        // occurs when a user goes through the "forgot password" flow
        // in the account settings
        await Auth.signIn(username, newPassword);
      }
      setIsCompleted(true);
    } catch (err: any) {
      if (err.message === VERIFICATION_CODE_INVALID_ERROR_MESSAGE) {
        setInPhase(PASSWORD_RESET_FORM_STATES.RESET_CODE);
      }
      setErrorMessage(err.message);
      setIsErrorMessageShown(true);
    }
    setIsWaiting(false);
  };

  return (
    <>
      <div className={baseStyles.container}>
        {isCompleted ? (
          <>
            <CircleCheckIcon width={32} height={32} />
            <div className={styles.successText}>Your password has been changed!</div>
            <div>
              <Button onClick={onLogin} className={baseStyles.primaryButton}>
                Log in
              </Button>
            </div>
          </>
        ) : (
          <>
            <div className={baseStyles.topSection}>
              <div>
                <h3 className={baseStyles.title}>Reset Password</h3>
              </div>
            </div>
            <div className={baseStyles.sectionRow}>
              {inPhase === PASSWORD_RESET_FORM_STATES.EMAIL ? (
                <LargeInput
                  placeholder="Email"
                  type="email"
                  value={email}
                  onChange={(e) => onEmailChange(e.target.value)}
                  errorMessage={isErrorMessageShown && errorMessage}
                  autoComplete="email"
                  shouldAutoFocus
                />
              ) : inPhase === PASSWORD_RESET_FORM_STATES.NEW_PASSWORD ? (
                <LargeInput
                  type="password"
                  placeholder="New Password"
                  value={password}
                  onChange={(e) => onPasswordChange(e.target.value)}
                  errorMessage={isErrorMessageShown && errorMessage}
                  autoComplete="new-password"
                />
              ) : (
                inPhase === PASSWORD_RESET_FORM_STATES.RESET_CODE && (
                  <LargeInput
                    placeholder="Verification Code"
                    value={verificationCode}
                    errorMessage={isErrorMessageShown && errorMessage}
                    onChange={(e) => onVerificationCodeChange(e.target.value)}
                    autoComplete="one-time-code"
                  />
                )
              )}
            </div>

            <div>
              {inPhase === PASSWORD_RESET_FORM_STATES.EMAIL ? (
                <Button
                  onClick={sendCodeToEmail}
                  isLoading={isWaiting}
                  className={baseStyles.primaryButton}
                >
                  Send Reset Code
                </Button>
              ) : inPhase === PASSWORD_RESET_FORM_STATES.RESET_CODE ? (
                <Button
                  onClick={inputVerificationCode}
                  isLoading={isWaiting}
                  className={baseStyles.primaryButton}
                >
                  Enter Code
                </Button>
              ) : (
                inPhase === PASSWORD_RESET_FORM_STATES.NEW_PASSWORD && (
                  <Button
                    onClick={changeToNewPassword}
                    isLoading={isWaiting}
                    className={baseStyles.primaryButton}
                  >
                    Change Password
                  </Button>
                )
              )}
            </div>
            <div className={baseStyles.bottomAltActionContainer}>
              {!isAuthenticated && (
                <>
                  Remember your password?
                  <span onClick={onLogin} className={baseStyles.altActionSpaceOnLeft}>
                    Log In
                  </span>
                </>
              )}
            </div>
          </>
        )}
      </div>
    </>
  );
};
