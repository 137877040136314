import axios, { AxiosRequestConfig } from "axios";

import {
  ContentData,
  ProductData,
  PriceData,
  RatingData,
  ProductStatus,
} from "data/product/products";
import { StorefrontData } from "data/storefront";

import { isMarketingUrlAvailable } from "~/components/creator-dashboard/common/referralLinks";
import { CreatorDashboardData } from "~/components/creator-dashboard/common/types";
import {
  CLOUDINARY_CLOUD_NAME,
  CLOUDINARY_UPLOAD_PRESET,
  IMAGE_MAX_FILE_SIZE_BYTES,
  IMAGE_MAX_FILE_SIZE_MB,
} from "~/util/cloudinary-utils";

import { ApiStatus, fetchApi } from "./fetchStudioApi";
import { CreatorInfo } from "./userApi";

export interface StorefrontDraft extends Partial<StorefrontData> {
  draftId?: string;
  shouldCreateNewDraft?: boolean;
}
export interface ProductDraft extends Partial<ProductData> {
  status: ProductStatus;
  createdAt: string;
  lastUpdatedAt: string;
  draftId?: string;
  shouldCreateNewDraft?: boolean;
  contentDraft?: Partial<ContentData>[];
  priceDataDraft?: Partial<PriceData>;
}

const getAdminCreatorSlug = () => {
  return localStorage.getItem("adminCreatorSlug") || "";
};

export const fetchEarningsData: () => Promise<CreatorDashboardData> = async () => {
  console.log("Called fetchEarningsData");
  const result: Promise<CreatorDashboardData> = await fetchApi({
    path: "/getEarningsDashboardInfo",
    method: "GET",
    authRequired: true,
  }).then((res) => res.dashboardInfo);

  return result;
};

export const fetchEarningsDataAdmin: (
  creatorSlug: string,
) => Promise<CreatorDashboardData> = async (creatorSlug) => {
  const result: Promise<CreatorDashboardData> = await fetchApi({
    path: "/getAdminEarningsDashboardInfo",
    body: JSON.stringify({ storefront_slug: creatorSlug }),
    method: "POST",
    isJsonPayload: true,
    authRequired: true,
  }).then((res) => res.dashboardInfo);
  return result;
};

interface FetchCreatorResponse {
  message: string;
  status: string;
  creatorInfo: CreatorInfo;
}

export const adminGetCreatorInfo = async (adminCreatorSlug: string) => {
  const result = (await fetchApi({
    path: "/getCreatorInfo",
    method: "POST",
    body: JSON.stringify({ adminCreatorSlug: adminCreatorSlug }),
    authRequired: true,
    isJsonPayload: true,
  })) as FetchCreatorResponse;
  return result.creatorInfo;
};

export const postVideoToBackend = async (wistiaVideoId: string) => {
  console.log("postVideoToBackend with videoId: ", wistiaVideoId);
  const videoData: Promise<ContentData> = await fetchApi({
    path:
      "/addCreatorVideo?wistia_video_id=" +
      wistiaVideoId +
      "&admin_creator_slug=" +
      getAdminCreatorSlug(),
    method: "POST",
    authRequired: true,
  }).then((res) => res.videoInfo);
  return videoData;
};

export const uploadVideo = async (
  bearerToken: string,
  file: File,
  wistiaProjectId: string,
  setProgress: (number: number) => void,
  fileName?: string,
) => {
  const config: AxiosRequestConfig = {
    onUploadProgress: (progressEvent) => {
      const progress = (progressEvent.loaded / progressEvent.total) * 100;
      setProgress(progress);
    },
    headers: {
      authorization: `Bearer ${bearerToken}`,
    },
  };

  console.log("Bearer: ", bearerToken);
  console.log("project_id: ", wistiaProjectId);

  const formData = new FormData();

  const logRes = (res: any) => {
    console.log("Wistia response: ", res);
    console.log("Hashed Id: ", res.data.hashed_id);
    return res;
  };

  formData.append("file", file);
  formData.append("project_id", wistiaProjectId);
  formData.append("name", fileName ? fileName : "Untitled Lesson");

  const videoData: any = axios
    .post("https://upload.wistia.com", formData, config)
    .then((res) => {
      logRes(res);
      return res;
    })
    .catch((err: Error) => {
      throw new Error("Mapping to db failed, contact support.");
    });
  return videoData;
};

export enum CloudinaryTag {
  CreatorProfileImage = "creatorProfileImageUrl",
  ClassPosterImage = "classPosterImageUrl",
  ClassTrailerVideo = "classTrailerVideo",
  ClassPreviewVideo = "classPreviewVideo",
  ClassMediaExampleAudio = "classMediaExampleAudio",
}

interface UploadFileResult {
  id: string;
  status: ApiStatus;
  message: string;
  url: string;
  resource_type: string;
}

export const uploadFileToCloudinary = async ({
  file,
  userId,
  tag,
  setProgress,
}: {
  file: File;
  userId: string;
  tag: CloudinaryTag;
  setProgress?: (progress: number) => void;
}) => {

  const config: AxiosRequestConfig = {
    onUploadProgress: (progressEvent) => {
      const progress = (progressEvent.loaded / progressEvent.total) * 100;
      setProgress &&  setProgress(progress);
    },
  };

  if (file.size > IMAGE_MAX_FILE_SIZE_BYTES) {
    return {
      status: ApiStatus.ERROR,
      message: `The maximum file size for file uploads is ${IMAGE_MAX_FILE_SIZE_MB} MB. Please choose a smaller file and try again.`,
    } as UploadFileResult;
  }

  // Tests for an image MIME type (https://developer.mozilla.org/en-US/docs/Web/HTTP/Basics_of_HTTP/MIME_types/Common_types)
  // using the following RegEx:
  // image  --> find the literal word "image"
  // \/     --> followed by a forward slash
  if (
    (tag == CloudinaryTag.ClassPosterImage || tag == CloudinaryTag.CreatorProfileImage) &&
    !/image\//.test(file.type)
  ) {
    return {
      status: ApiStatus.ERROR,
      message: `Invalid photo file format (${file.type}). Please choose a photo and try again.`,
    } as UploadFileResult;
  }

  const url = `https://api.cloudinary.com/v1_1/${CLOUDINARY_CLOUD_NAME}/upload`;
  const data = new FormData();
  data.append("upload_preset", CLOUDINARY_UPLOAD_PRESET);
  data.append("file", file);
  data.append("angle", "exif");
  data.append("tags", `${userId},${tag}`);
  data.append("context", `userId=${userId},type=${tag}`);

  // const response = await fetchApi({
  //   path: url,
  //   method: "POST",
  //   body: data,
  // }).catch(function (error: any) {
  //   console.log('error-', error);
  //   alert("Error uploading media. Please try another format. ");
  // });

  const response = await axios
    .post(url, data, config)
    .then((res) => {
      console.log("File upload response:", res);
      return res.data;
    })
    .catch((error) => {
      console.log("File upload failed:", error);
    });

  if (!response?.secure_url) {
    console.error("Failed to upload to Cloudinary");
    const errorMsg =
      "There was an error uploading your file. Please try again or contact us at support@studio.com";
    return { status: ApiStatus.SUCCESS, message: errorMsg } as UploadFileResult;
  }
  return {
    status: ApiStatus.SUCCESS,
    message: "Success!",
    url: response.secure_url,
  } as UploadFileResult;
};

export const createProductDraft = async () => {
  const productDraftData: Promise<ProductDraft> = await fetchApi({
    path: "/createProductDraft",
    body: JSON.stringify({ adminCreatorSlug: getAdminCreatorSlug() }),
    method: "POST",
    isJsonPayload: true,
    authRequired: true,
  }).then((res) => res.productDraft);
  return productDraftData;
};

export const cleanNumbersFromStringToFloatsInProduct = (productDraft: ProductDraft) => {
  if (productDraft.content) {
    for (let content of productDraft.content || []) {
      if (typeof content.lengthInSeconds == "string") {
        content.lengthInSeconds = parseFloat(content.lengthInSeconds);
      }
    }
  }
};
export const updateProductDraft = async (draftUpdate: ProductDraft) => {
  console.log("updateProductDraft: ", JSON.stringify(draftUpdate));
  cleanNumbersFromStringToFloatsInProduct(draftUpdate);
  console.log("PRODUCT DRAFT AFTER CLEANUP: ", draftUpdate);
  const result = fetchApi({
    path: "/updateProductDraft",
    body: JSON.stringify({ ...draftUpdate, adminCreatorSlug: getAdminCreatorSlug() }),
    method: "POST",
    isJsonPayload: true,
    authRequired: true,
  }).then((res) => res);
  return result;
};

export const updateStorefrontDraft = async (draftUpdate: StorefrontDraft) => {
  const result = fetchApi({
    path: "/updateStorefrontDraft",
    body: JSON.stringify({ ...draftUpdate, adminCreatorSlug: getAdminCreatorSlug() }),
    method: "POST",
    isJsonPayload: true,
    authRequired: true,
  }).then((res) => res);
  return result;
};

export const getLatestProductDraft = async (productId: string) => {
  const latestProductDraft: Promise<ProductData> = fetchApi({
    path: "/getLatestProductDraft",
    body: JSON.stringify({ productId: productId, adminCreatorSlug: getAdminCreatorSlug() }),
    method: "POST",
    isJsonPayload: true,
    authRequired: true,
  }).then((res) => res.productDraft);
  return latestProductDraft;
};

export const getLatestStorefrontDraft = async () => {
  const latestStorefrontDraft: Promise<StorefrontDraft> = fetchApi({
    path: "/getLatestStorefrontDraft",
    method: "POST",
    body: JSON.stringify({ adminCreatorSlug: getAdminCreatorSlug() }),
    authRequired: true,
    isJsonPayload: true,
  }).then((res) => res.storefrontDraft);
  return latestStorefrontDraft;
};

export const getIsStorefrontAvailable = async (slug: string) => {
  const isStorefrontAvailable: Promise<boolean> = fetchApi({
    path: "/getIsStorefrontAvailable",
    body: JSON.stringify({ storefront_slug: slug, adminCreatorSlug: getAdminCreatorSlug() }),
    method: "POST",
    isJsonPayload: true,
    authRequired: true,
  }).then((res) => {
    const isMarketingLinkAvailable = isMarketingUrlAvailable(slug);
    return res.isAvailable && isMarketingLinkAvailable;
  });

  return isStorefrontAvailable;
};

export const getIsClassPageAvailable = async (slug: string) => {
  const isClassPageAvailable: Promise<boolean> = fetchApi({
    path: "/getIsClassPageAvailable",
    body: JSON.stringify({ class_slug: slug, adminCreatorSlug: getAdminCreatorSlug() }),
    method: "POST",
    isJsonPayload: true,
    authRequired: true,
  }).then((res) => res.isAvailable);
  return isClassPageAvailable;
};

export const getCreatorProductsAndDrafts = async () => {
  const productsAndDrafts: Promise<ProductDraft[]> = fetchApi({
    path: "/getCreatorProductsAndDrafts",
    body: JSON.stringify({ adminCreatorSlug: getAdminCreatorSlug() }),
    method: "POST",
    isJsonPayload: true,
    authRequired: true,
  }).then((res) => res.productsAndDrafts);
  return productsAndDrafts;
};

export const isCreatorWithDrafts = async () => {
  const hasDrafts: Promise<boolean> = fetchApi({
    path: "/isCreatorWithDrafts",
    method: "GET",
    authRequired: true,
  }).then((res) => res.hasCreatorDrafts);
  return hasDrafts;
};

export const deleteProductDraft = async (productId: string) => {
  const deletedDraft: Promise<ProductDraft> = fetchApi({
    path: "/deleteProductDraft",
    body: JSON.stringify({ productId: productId, adminCreatorSlug: getAdminCreatorSlug() }),
    method: "DELETE",
    isJsonPayload: true,
    authRequired: true,
  }).then((res) => res.deletedDraft);
  return deletedDraft;
};

export const getProductDraft = async (draftId: string) => {
  const productDraft: Promise<ProductDraft> = fetchApi({
    path: "/getProductDraft?draft_id=" + draftId,
    method: "GET",
  }).then((res) => res.productDraft);
  return productDraft;
};

export const getCanEditProduct = async (productId: string, adminCreatorSlug: string = "") => {
  const canEditProduct: Promise<boolean> = fetchApi({
    path: "/getCanEditProduct",
    method: "POST",
    isJsonPayload: true,
    body: JSON.stringify({ productId: productId, adminCreatorSlug: adminCreatorSlug }),
    authRequired: true,
  }).then((res) => res.canEdit);
  return canEditProduct;
};

export const getStripeConnectAccountUrl = async () => {
  const result: Promise<{ stripeAccountUrl: string }> = fetchApi({
    path: "/getStripeConnectAccountUrl",
    method: "POST",
    isJsonPayload: true,
    body: JSON.stringify({}),
    authRequired: true,
  }).then((res) => res);
  return result;
};

export const getStripeConnectAccountStatusInfo = async () => {
  const result: Promise<any> = fetchApi({
    path: "/getStripeConnectAccountStatusInfo",
    method: "POST",
    isJsonPayload: true,
    body: JSON.stringify({}),
    authRequired: true,
  }).then((res) => res);
  return result;
};

export const TEMPLATE_PRODUCT_DRAFT: ProductDraft = {
  id: "123",
  storefrontId: "",
  status: ProductStatus.DRAFT,
  title: "Add the title of your class in this section here",
  description:
    "Add the description of your class here. Make sure to lay out the promise of your class, the process you are going to show, and what students are going to learn. It’s meant to paint a picture while not being too overly detailed. Your description should only be 3-5 lines.",
  productImageURL: "",
  productPreviewURL: "", // trailer
  createdAt: "",
  lastUpdatedAt: "",
  creatorName: "",
  creatorProfileImageURL: "",
  storefrontSlug: "",
  totalVideoLengthInSeconds: 0,
  creatorBio: "",
  creatorOnStudioSinceDate: "",
  overviewSectionTitle: "[This is the main promise or transformation of your class]",
  overviewSectionDescription:
    "Enter an overview of your class here. You can repurpose a lot of your trailer script for this section, but make sure you write this section in 3rd person.",
  exampleMediaSectionTitle: "Follow [Your Name]’s proven process, step by step",
  exampleMediaSectionDescription:
    "In the class, [Your Name] breaks down [his/her/their] [songwriting/producing/etc] process into clear, actionable steps. Follow along with [Your Name]’s process step-by-step and [write/produce/create/etc] your own [songs/beats/etc]. You can also watch [Your Name]’s process in full first and then bring the ideas, tools, and techniques into your own projects.\n\n By following [Your Name]’s proven process, you’ll learn everything you need to [write songs/produce hits/etc] like this/these:",
  tags: [],
  content: [],
  whoIsThisForSection: [
    "Enter audience description here. E.g. Experienced songwriters and hobbyists looking to drastically level up",
    "Enter audience description here. E.g. Aspiring professionals looking to learn and practice the skills needed to write professional-level songs",
    "Enter audience description here. E.g. Professionals looking to hone their craft",
  ],
  skills: [
    "Enter a specific topic that students will learn in your class here",
    "Enter a specific topic that students will learn in your class here",
    "Enter a specific topic that students will learn in your class here",
    "Enter a specific topic that students will learn in your class here",
    "Enter a specific topic that students will learn in your class here",
    "Enter a specific topic that students will learn in your class here",
  ],
  classSlug: "",
  previewClipURL: "", // preview
  shouldCreateNewDraft: false,
  priceInfo: { price: 0, crossedPrice: 0, crossedPriceApplicableFrom: "", applicableFrom: "" },
  ratingInfo: { ratingScore: 0, numRatings: 0, numTakers: 0 },
  orderIndex: 0,
  legacyClass: "",
};

export const TEMPLATE_STOREFRONT_DRAFT: StorefrontData = {
  slug: "test-slug",
  title: "storefront-title",
  description:
    "This is an opportunity to share a longer bio about yourself. Feel free to share more details about your journey and your credentials.",
  shortBio:
    "Add your short bio here. It should be written in 3rd person, meant to give credibility to you as a teacher of this specific class. It should not exceed 2 lines.",
  createdAt: "",
  lastUpdatedAt: "",
  id: "",
  userId: "",
  welcomePosterImageURL: "",
  welcomeVideoURL: "",
  tags: [],
  products: [],
  creatorName: "Add your name",
  socialAccounts: {
    instagramAccount: "",
    tiktokAccount: "",
    twitterAccount: "",
    spotifyAccountLink: "",
    youtubeAccountLink: "",
  },
};
