import { StudioUser } from "~/api/userApi";

export const identifyDripUser = ({ email, firstName, lastName, username }: StudioUser) => {
  const DRIP_CLIENT = (window as any)?._dcq;

  if (DRIP_CLIENT != null) {
    DRIP_CLIENT.push([
      "identify",
      {
        email,
        first_name: firstName,
        last_name: lastName,
        username,
        tags: [],
        success: function () {
          // Call a method with the response object
          // Success callback is optional
          //console.log("identified w Drip ", response);
        },
      },
    ]);
  }
};
