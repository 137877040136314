import React, { useEffect, useRef, useState, ReactNode } from "react";

import classNames from "classnames";
import Image from "next/image";
import Link from "next/link";
import { ReactElement } from "react-markdown/lib/react-markdown";

import { If, MinusIcon } from "studio-design-system";
import { PlusIcon } from "studio-design-system";
import { useWindowSize } from "usehooks-ts";

import { CurriculumModule, CurriculumItem } from "~/api/sfCurriculumApi";
import { ArrowRightIcon } from "~/components/cart/Icons/ArrowRightIcon";
import { instructorLabel } from "~/components/common/CreatorInfo/CreatorInfo.css";
import { useCurrentSession } from "~/hooks/currentSessionProvider/useCurrentSession";
import { useSchoolCoreData } from "~/hooks/schoolCoreProvider/useSchoolCoreProvider";
import { useIsMobileScreen } from "~/hooks/ui/useIsMobileScreen";

import { dayInCurriculum, daysUntilDate, transformDate } from "../../utils";
import { Button } from "../Button/Button";
import {
  ChevronIcon,
  CompletedIcon,
  LockedIcon,
  PlayVideoIcon,
  StudioIcon,
  BoltIcon,
  FeedbackHeartIcon,
  CurriculumIcon,
} from "../Icons";
import { studioIconContainer } from "../Layout";
import { ProgressBar } from "../ProgressBar";
import * as styles from "./CurriculumModule.css";

export const ProjectItem = ({
  item,
  appendHrefQuery,
  isPreview,
}: {
  item: CurriculumItem;
  appendHrefQuery?: boolean;
  isPreview?: boolean;
}) => {
  const { width } = useWindowSize();
  const isBreakingPoint = width <= 1200;
  const { currentSession } = useCurrentSession();
  const isCompleted = Boolean(item.completedOn);

  const buttonLabel = isPreview
    ? dayInCurriculum(item.startDate, item.dueDate, currentSession?.startDate || "")
    : isCompleted
    ? "Completed"
    : item.isOptional
    ? "Optional"
    : daysUntilDate(item.dueDate, "Due");

  const projectHref =
    item.actionType === "feedback"
      ? `/school/music/sessions/${currentSession?.sessionSlug}/curriculum/${item.id}/feedback/${item.peerFeedbackAssignments?.[0]?.postId}`
      : `/school/music/sessions/${currentSession?.sessionSlug}/curriculum/${item.id}`;

  type ConditionalWrapperProps = {
    condition: boolean;
    wrapper: (children: ReactNode) => JSX.Element;
    children: ReactNode;
  };

  const ConditionalWrapper: React.FC<ConditionalWrapperProps> = ({
    condition,
    wrapper,
    children,
  }) => (condition ? wrapper(children) : <>{children}</>);

  return (
    <ConditionalWrapper
      condition={!isPreview}
      wrapper={(children) => (
        <Link
          href={{
            pathname: projectHref,
            // query: appendHrefQuery ? { todaysTask: true } : {},
          }}
          passHref
        >
          {children}
        </Link>
      )}
    >
      <div
        className={classNames(
          isCompleted && styles.completedProject,
          styles.projectCardContainer,
          isPreview && styles.defaultCursor,
        )}
      >
        <div className={styles.projectCardContentContainer}>
          <div className={styles.IconContainer}>
            {item.actionType === "project" ? <BoltIcon /> : <FeedbackHeartIcon />}
          </div>
          <div>
            <h2 className={styles.projectCardTitle}>{item.title}</h2>
            <p className={styles.descriptionCurriculumModule}>
              Submit by {transformDate(item.dueDate as string)}
            </p>
          </div>
        </div>
        {isBreakingPoint ? (
          <button>
            <ChevronIcon />
          </button>
        ) : (
          <Button
            variant={isCompleted ? "secondary" : "primary"}
            icon={!isPreview && <ChevronIcon />}
            label={buttonLabel}
            hasCheckIcon={Boolean(isCompleted)}
            className={isPreview ? styles.defaultCursor : ""}
          />
        )}
      </div>
    </ConditionalWrapper>
  );
};

export const WatchItem = ({
  item,
  appendHrefQuery,
  instructorInfoMap,
  isPreview,
}: {
  item: CurriculumItem;
  appendHrefQuery?: boolean;
  instructorInfoMap: CurriculumModule["instructorInfoMap"];
  isPreview?: boolean;
}) => {
  const { width } = useWindowSize();
  const isBreakingPoint = width <= 1200;
  const { currentSession } = useCurrentSession();
  const instructorInfoMapForItem = (() => {
    const matchingInstructors = {} as CurriculumModule["instructorInfoMap"];

    (item.instructorSlugs || []).forEach((slug) => {
      if (instructorInfoMap && instructorInfoMap.hasOwnProperty(slug)) {
        matchingInstructors[slug] = instructorInfoMap[slug];
      }
    });

    return matchingInstructors;
  })();

  // For watch items, we don't pass the end date, because we only want to show the start date, not a range.
  const buttonLabel = isPreview
    ? dayInCurriculum(item.startDate, item.dueDate, currentSession?.startDate || "")
    : Boolean(item.completedOn)
    ? "Completed"
    : item.isOptional
    ? "Optional"
    : daysUntilDate(item.dueDate, "Watch");

  type ConditionalWrapperProps = {
    condition: boolean;
    wrapper: (children: ReactNode) => JSX.Element;
    children: ReactNode;
  };

  const ConditionalWrapper: React.FC<ConditionalWrapperProps> = ({
    condition,
    wrapper,
    children,
  }) => (condition ? wrapper(children) : <>{children}</>);

  return (
    <ConditionalWrapper
      condition={!isPreview}
      wrapper={(children) => (
        <Link
          href={{
            pathname: `/school/music/sessions/${currentSession?.sessionSlug}/curriculum/${item.id}`,
            // query: appendHrefQuery ? { todaysTask: true } : {},
          }}
          // replace
          passHref
        >
          {children}
        </Link>
      )}
    >
      <div
        className={classNames(
          item.completedOn && styles.completedProject,
          !item.instructorSlugs && styles.noInstructor,
          styles.gridContainer,
          isPreview && styles.defaultCursor,
        )}
      >
        <div className={styles.videoContentContainer}>
          <PlayVideoIcon className={styles.hideIcon} />
          <Image
            alt={item.title}
            src={item.itemThumbnailURLs[0]}
            width={isBreakingPoint ? 50 : 82}
            height={49}
            objectFit="cover"
            style={{ borderRadius: "3px" }}
          />
          <div>
            {/* add ellipsis only to title, not length */}
            <h2 className={styles.watchItemTitleContainer}>
              <div className={styles.watchItemTitleWrapper}>
                <span className={styles.watchItemTitle}>{item.title}</span>
              </div>
              <span className={styles.watchItemVideoLength}>
                {item.content && item.content.length > 0 && `(${item.content[0].videoLength})`}
              </span>
            </h2>
            <div className={styles.mobileContent}>
              {item.instructorSlugs &&
                Object.entries(instructorInfoMapForItem).map(([key, value], index) => {
                  if (index === 0) {
                    return (
                      <div key={key} className={styles.mobileInstructorContainer}>
                        <Image
                          alt={value.instructorDisplayName}
                          objectFit="cover"
                          src={value.instructorPhotoURL}
                          width={15}
                          height={15}
                          style={{ borderRadius: "50%" }}
                        />
                        <p
                          className={classNames(
                            styles.descriptionDot,
                            styles.descriptionCurriculumModule,
                          )}
                        >
                          {value.instructorDisplayName}
                          {item.instructorSlugs.length > 1 && (
                            <span> + {item.instructorSlugs.length - 1}</span>
                          )}
                        </p>
                        <p className={styles.descriptionCurriculumModule}>{buttonLabel}</p>
                      </div>
                    );
                  }
                  return null;
                })}
            </div>
          </div>
        </div>
        {item.instructorSlugs &&
          Object.entries(instructorInfoMapForItem).map(([key, value], index) => {
            if (index === 0) {
              return (
                <div key={key} className={styles.instructorContainer}>
                  <Image
                    alt={value.instructorDisplayName}
                    objectFit="cover"
                    src={value.instructorPhotoURL}
                    width={24}
                    height={24}
                    style={{ borderRadius: "50%" }}
                  />
                  <h3 className={styles.instructorName}>
                    {value.instructorDisplayName}
                    {item.instructorSlugs.length > 1 && (
                      <span> + {item.instructorSlugs.length - 1}</span>
                    )}
                  </h3>
                </div>
              );
            }
            return null;
          })}
        {isBreakingPoint ? (
          <button>
            <ChevronIcon />
          </button>
        ) : (
          <Button
            variant="secondary"
            icon={!isPreview && <ChevronIcon />}
            label={buttonLabel}
            hasCheckIcon={Boolean(item.completedOn)}
            className={isPreview ? styles.defaultCursor : ""}
          />
        )}
      </div>
    </ConditionalWrapper>
  );
};

export const RichInstructorDescription = ({
  className,
  text,
  instructorInfoMap,
  truncatesAfter = null,
  shouldShowIcon = true,
  appends = undefined,
  appendsAtStart = undefined,
  parentRef = undefined,
  onlyText = false,
}: {
  className?: string;
  text: string;
  instructorInfoMap?: CurriculumModule["instructorInfoMap"];
  truncatesAfter?: number | null;
  shouldShowIcon?: boolean;
  appends?: ReactElement | undefined;
  appendsAtStart?: ReactElement | undefined;
  parentRef?: React.RefObject<HTMLDivElement>;
  onlyText?: boolean;
}) => {
  const modalRef = useRef<HTMLDivElement>(null);
  const instructorPillRef = useRef<HTMLDivElement>(null);

  const [hoveredInstructor, setHoveredInstructor] = useState({
    id: -1,
    slug: "",
    name: "",
    profileUrl: "",
    shortBio: "",
    isModalOpen: false,
  });

  const [textIsTruncated, setTextIsTruncated] = useState(true);

  useEffect(() => {
    const modal = modalRef.current;
    const instructor = instructorPillRef.current;

    if (!modal || !hoveredInstructor.isModalOpen || !instructor) {
      console.log("Modal ref not found");
      return;
    }

    console.log("modal", modal, "hoveredInstructor", hoveredInstructor);

    const topOfParent = parentRef?.current?.getBoundingClientRect().top || 200;
    const rightOfParent = parentRef?.current?.getBoundingClientRect().right || window.innerWidth;

    const modalRect = modal.getBoundingClientRect();

    // modal is above top of parent or screen
    console.log("modalRect.top", modalRect.top, "topOfParent", topOfParent);
    if (modalRect.top < topOfParent) {
      modal.style.top = `30px`;
      modal.style.bottom = `auto`;
      console.log("modalRect.top < topOfParent");
    } else {
      modal.style.bottom = `30px`;
      modal.style.top = `auto`;
      console.log("modalRect.top > topOfParent");
    }

    // modal is to the right of the parent or screen
    console.log("modalRect.right", modalRect.right, "rightOfParent", rightOfParent);
    const distanceToLeft = modalRect.left;
    const distanceToRight = rightOfParent - modalRect.right;
    console.log("distanceToRight", distanceToRight);

    if (modalRect.width > rightOfParent * 0.6) {
      const spaceLeftover = rightOfParent - modalRect.width;

      modal.style.left = `-${distanceToLeft - spaceLeftover / 2 - 2}px`;
      modal.style.right = `auto`;
      console.log("modalRect.width > 80% of rightOfParent");
    } else if (distanceToRight < 20) {
      modal.style.right = `0px`;
      modal.style.left = `auto`;
      console.log("modalRect.right > rightOfParent by", distanceToRight);
    } else {
      modal.style.left = `0px`;
      modal.style.right = `auto`;
      console.log("modalRect.right < rightOfParent");
    }
  }, [hoveredInstructor]);

  if (truncatesAfter && text.split(" ").length <= truncatesAfter) {
    truncatesAfter = null;
  }

  if (!instructorInfoMap) {
    return (
      <div className={classNames(className, styles.descriptionContainer)}>
        <div className={studioIconContainer}>
          <StudioIcon />
        </div>
        <div>
          <div className={styles.descriptionCurriculumModule}>{text}</div>
          {appends && <div className={styles.descriptionCurriculumModule}>{appends}</div>}
        </div>
      </div>
    );
  }

  const handleHover = (slug: string, id: number) => {
    if (!instructorInfoMap[slug]) return;

    setHoveredInstructor({
      id: id,
      slug: slug,
      name: instructorInfoMap[slug].instructorDisplayName,
      profileUrl: instructorInfoMap[slug].instructorPhotoURL,
      shortBio: instructorInfoMap[slug].instructorShortBio,
      isModalOpen: true,
    });
  };

  const handleLeave = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    setHoveredInstructor({
      id: -1,
      slug: "",
      name: "",
      profileUrl: "",
      shortBio: "",
      isModalOpen: false,
    });
  };

  const truncateAndEnrichText = () => {
    const regex = /\[\[(.*?)\]\]/g;

    const truncatedText =
      truncatesAfter !== null && textIsTruncated
        ? text.split(" ").slice(0, truncatesAfter).join(" ")
        : text;

    const res = truncatedText.split(regex).map((slug, index) => {
      if (index % 2 === 1) {
        // regex captures not only the substrings enclosed within double square brackets but also the substrings that occur between them. By checking if the index is odd, it filters out those in-between substrings and allows you to focus only on the captured content within double square brackets.
        let id = index;

        return (
          <div
            key={index}
            className={styles.hoverableName}
            onMouseEnter={() => handleHover(slug, id)}
            onMouseLeave={handleLeave}
            ref={instructorPillRef}
          >
            {instructorInfoMap && instructorInfoMap[slug] && (
              <>
                <Image
                  alt={instructorInfoMap[slug].instructorDisplayName}
                  objectFit="cover"
                  src={instructorInfoMap[slug].instructorPhotoURL}
                  width={14}
                  height={14}
                  style={{
                    borderRadius: "4px",
                    lineHeight: "0",
                  }}
                />
                <span className={styles.instructorDisplayName} onMouseLeave={handleLeave}>
                  {instructorInfoMap[slug].instructorDisplayName}{" "}
                </span>
              </>
            )}

            {hoveredInstructor.isModalOpen && hoveredInstructor.id === id && (
              <div className={styles.modalContainer} ref={modalRef}>
                <div className={styles.modalHeader}>
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      gap: "8px",
                    }}
                  >
                    <Image
                      alt={hoveredInstructor.name}
                      src={hoveredInstructor.profileUrl}
                      objectFit="cover"
                      width={30}
                      height={30}
                      style={{
                        borderRadius: "30px",
                      }}
                    />
                    <h2 className={styles.instructorModalName}>{hoveredInstructor.name} </h2>
                  </div>
                  <span className={instructorLabel}>Instructor</span>
                </div>
                <p className={styles.instructorModalBio}>{hoveredInstructor.shortBio}</p>
              </div>
            )}
          </div>
        );
      }
      return slug;
    });

    if (truncatesAfter === null) {
      return res;
    }

    return [
      res,
      <span
        key={"seemoreseeless"}
        className={styles.seeMoreLess}
        onClick={() => setTextIsTruncated((prev) => !prev)}
      >
        {textIsTruncated ? "See more" : "See less"}
      </span>,
    ];
  };

  if (!text) return null;

  if (onlyText) {
    return <div className={styles.descriptionCurriculumModule}>{truncateAndEnrichText()}</div>;
  }

  return (
    <div className={classNames(className, styles.descriptionContainer)}>
      {shouldShowIcon && (
        <div className={studioIconContainer}>
          <StudioIcon />
        </div>
      )}
      <div>
        <div className={styles.descriptionCurriculumModule}>
          <>
            {appendsAtStart} {truncateAndEnrichText()}
          </>
        </div>
        {appends && <div className={styles.descriptionCurriculumModule}>{appends}</div>}
      </div>
    </div>
  );
};

export const CurriculumModuleProgress = ({
  className,
  module,
  curriculumProgress,
  isProjectLocked,
  shouldExpand,
  hideProgress,
}: {
  className?: string;
  module: CurriculumModule;
  curriculumProgress: string;
  isProjectLocked: boolean;
  shouldExpand?: boolean;
  hideProgress?: boolean;
}) => {
  const latestCompletedDate = module.items?.reduce((acc, item) => {
    if (item.completedOn) {
      let completedOn = item.completedOn.split("T")[0];

      if (acc === "" || completedOn > acc) {
        return completedOn;
      }

      return acc;
    }
    
    return acc;
  }, "");

  return (
    <div className={styles.progressContainer}>
      {curriculumProgress === "100" ? (
        <div className={classNames(styles.paddingTop, styles.videoContentContainer)}>
          <CompletedIcon />
          <p className={styles.lockedDate}>Completed on {transformDate(latestCompletedDate)}</p>
        </div>
      ) : isProjectLocked ? (
        <div className={classNames(styles.paddingTop, styles.videoContentContainer)}>
          <LockedIcon />
          <p className={styles.lockedDate}>Locked until {transformDate(module.startDate)}</p>
        </div>
      ) : null}
      {shouldExpand && !hideProgress && curriculumProgress && (
        <ProgressBar
          progress={curriculumProgress}
          showPercentage
          containerClassName={styles.progressBar}
        />
      )}
      {/* {hideProgress && <div style={{ height: 20 }} />} */}
    </div>
  );
};

export const CurriculumModuleElement = ({
  curriculumModule,
  showAllItems,
  showMoreButton,
  hideProgress,
  hideExpandOnMobile,
  hideDividerOnMobile,
  startsSelected,
  isPreview,
}: {
  curriculumModule: CurriculumModule;
  showAllItems?: () => void;
  showMoreButton?: boolean;
  hideProgress?: boolean;
  hideExpandOnMobile?: boolean;
  hideDividerOnMobile?: boolean;
  startsSelected?: boolean;
  isPreview?: boolean;
}) => {
  const { coachFeedback } = useSchoolCoreData();
  const isModuleForToday = (curriculumModule.id === "today" && coachFeedback?.feedback) as boolean;

  const [moduleOpen, setModuleOpen] = useState(startsSelected || hideExpandOnMobile || false);
  const isProjectLocked = curriculumModule?.isLocked;

  useEffect(() => {
    if (startsSelected) {
      setModuleOpen(true);
    }
  }, [startsSelected]);

  const shouldExpandProgress = (moduleOpen && !isProjectLocked) || isModuleForToday;

  const isMobile = useIsMobileScreen();

  const toggleModuleOpen = () => {
    if (isProjectLocked || hideExpandOnMobile || isModuleForToday) return;

    setModuleOpen(!moduleOpen);
  };

  const moduleRef = useRef<HTMLDivElement>(null);

  const calculateCurriculumProgress = () => {
    let progress = 0;
    let totalItems = 0;
    let completedItems = 0;
    curriculumModule?.items?.forEach((item) => {
      if (item.isOptional) {
        return;
      } else {
        totalItems++;
        if (item.completedOn) {
          completedItems++;
        }
      }
      progress = (completedItems / totalItems) * 100;
    });
    return progress.toFixed(0);
  };

  return (
    <div
      className={classNames(
        showMoreButton && styles.todaysTaskItem,
        calculateCurriculumProgress() === "100" && styles.completedItem,
        styles.item,
      )}
      ref={moduleRef}
    >
      <div
        className={styles.itemCollapsedContainer}
        style={{
          opacity: calculateCurriculumProgress() === "100" ? 0.5 : 1,
        }}
        onClick={toggleModuleOpen}
      >
        <div className={styles.itemCollapsed}>
          <h2 className={classNames(styles.moduleTitle, !moduleOpen && styles.ellipsis)}>
            {curriculumModule?.title}
          </h2>
        </div>
        {!hideExpandOnMobile && !isModuleForToday && (
          <>
            <If condition={!moduleOpen}>
              <div
                style={{
                  zIndex: 2,
                }}
              >
                <PlusIcon
                  className={classNames(
                    isProjectLocked && styles.itemPlusDisabled,
                    styles.itemPlus,
                  )}
                  width={28}
                  height={28}
                />
              </div>
            </If>
            <If condition={moduleOpen}>
              <div
                style={{
                  zIndex: 2,
                }}
              >
                <MinusIcon className={styles.itemPlus} width={28} height={28} />
              </div>
            </If>
          </>
        )}
      </div>

      {!hideExpandOnMobile && curriculumModule && (
        <div
          className={styles.descriptionAndProgressContainer}
          onClick={isMobile ? undefined : toggleModuleOpen}
        >
          <RichInstructorDescription
            className={styles.descriptionCurriculumModule}
            text={isModuleForToday ? coachFeedback!.feedback : curriculumModule.description}
            instructorInfoMap={
              isModuleForToday
                ? coachFeedback!.featuredInstructorsMap
                : curriculumModule.instructorInfoMap
            }
          />

          <CurriculumModuleProgress
            isProjectLocked={isProjectLocked || false}
            curriculumProgress={calculateCurriculumProgress()}
            shouldExpand={shouldExpandProgress}
            hideProgress={hideProgress || isPreview}
            module={curriculumModule}
          />
        </div>
      )}

      <If condition={shouldExpandProgress}>
        <div className={classNames(hideExpandOnMobile && styles.expandedPadding, styles.openItem)}>
          {curriculumModule?.items?.map((project, index) => (
            <div key={index}>
              {!hideDividerOnMobile && <div className={styles.divider} />}
              <div
                key={index}
                className={classNames(
                  project.completedOn && styles.completedProject,
                  !project.instructorSlugs && styles.videoProject,
                  styles.project,
                )}
              >
                {project.actionType === "watch" || project.actionType === "vocal" ? (
                  <WatchItem
                    item={project}
                    appendHrefQuery={Boolean(showAllItems)}
                    instructorInfoMap={curriculumModule.instructorInfoMap}
                    isPreview={isPreview}
                  />
                ) : (
                  <ProjectItem
                    item={project}
                    appendHrefQuery={Boolean(showAllItems)}
                    isPreview={isPreview}
                  />
                )}
              </div>
            </div>
          ))}
          {showMoreButton && (
            <div className={styles.showAllItems} onClick={showAllItems}>
              <CurriculumIcon />
              <span className={styles.showAllItemsText}>
                See all upcoming tasks for the current session
              </span>
              <ArrowRightIcon />
            </div>
          )}
        </div>
      </If>
    </div>
  );
};
