import "../../../../node_modules/@vanilla-extract/webpack-plugin/vanilla.virtual.css?%7B%22fileName%22%3A%22src%2Fcomponents%2Fcommon%2FOverlayModal%2FOverlayModal.css.ts.vanilla.css%22%2C%22source%22%3A%22%23H4sIAAAAAAAAA62UzZLaMAzH7zyFe9gZOJgJ39R76a0v0EtPHZMoxMWxU1uGsB3eveOPlGTLsuxMuWBLivyT%2FpanP2a%2FTqeMY0Z%2BjwhptBUotGKkFC0UzyNCUDeMZH5lxL7CtN5pRF2njYSys59EgRUjsyx78tsXKlQBLSPzLPy8rRC2kfzMSCmh9YafzqIozzTXCkEhIzkoBONdXIq9ogKhtgOzEjX3nLRwhkfgbLqw3kdPsDsIpG%2FHXEbTrupZqLrUCmnJayHPjHxz9gCGfDUawR7IJ1E32iBX%2BNxFniA2YhPrCTaLZwmMHLkZU%2BrWHGV1nFy94gUYmW%2BbUK8UCmiVkvS%2FaMMXuZbaDB1LFTxXdQxIjuIIQSBokaLhypba1Iy4pgGTcxucNTd7oWgnV0ToNWAeGvCPJP6fFsJAHs%2FLtXS1uqPIQPde%2FsWra8V3VkuHcOOudB3p9rrhucCzV20brhzPD3ujnSrorRbVk3hbXV5RnrCVVjDAWQacdzLda%2FZfqFnsbktTFZ8j9E6bAgw1vBDOMpIU1w696LeAWKlzZwPW21GrO10M8znP4kFpRLtt7oz15TVadEJdJ3J4Aqv0EUzkuDZ%2BPQhaP4oRH4SPU6wfodi8R7GKUkQIOstWEaM3ImEpOcL3MV1lT5OPUW4fBEhi%2FD%2BCLzUUgpNx79ptF8umnQSgV49aN6oWZNmf1PRgBGfffumn2KQUqYebVMAgZptiujKvQZc%2FtX2oSlYGAAA%3D%22%7D"
export var backgroundOverlay = '_1qww0at3';
export var closeButton = '_1qww0at5';
export var container = '_1qww0at0';
export var contentContainer = '_1qww0at2';
export var galleryNext = '_1qww0at8';
export var galleryPrevious = '_1qww0at7';
export var modalContainer = '_1qww0at4';
export var outsideHeaderTitle = '_1qww0at1';
export var previousStep = '_1qww0at6';
export var slideUpStyle = '\n    .slideUp {\n       animation: showModal .3s ease-in-out; \n    }\n    \n    @keyframes showModal {\n      0% {\n        transform: translateY(100%);\n      }\n\n      100% {\n        transform: translateY(0);\n      }\n    }\n';