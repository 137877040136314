import React, { useCallback, useEffect, useMemo } from "react";

import { UserProductPurchaseData } from "data/product/products";

import { getAllUserProductPurchases } from "~/api/productApi";
import { useAuth } from "~/hooks/auth/useAuth";

interface PurchaseProps {
  children: React.ReactNode;
}

interface PurchaseState {
  loadingUserPurchases: boolean;
  userPurchases: UserProductPurchaseData[];
  handleRefetchUserPurchases: () => void;
  hasUserPurchasedProduct: (productId: string) => boolean;
}

export const PurchaseContext = React.createContext<PurchaseState>({
  userPurchases: [],
  loadingUserPurchases: false,
  handleRefetchUserPurchases: () => {},
  hasUserPurchasedProduct: () => false,
});

export const PurchaseProvider = ({ children }: PurchaseProps) => {
  const { user } = useAuth();
  const [loadingUserPurchases, setLoadingUserPurchases] = React.useState(true);
  const [refetchUserPurchases, setRefetchUserPurchases] = React.useState(false);
  const [userPurchases, setUserPurchases] = React.useState<UserProductPurchaseData[]>([]);

  const handleRefetchUserPurchases = useCallback(() => {
    setRefetchUserPurchases(!refetchUserPurchases);
  }, [refetchUserPurchases]);

  const hasUserPurchasedProduct = useCallback(
    (productId: string) => {
      if (userPurchases && userPurchases.length > 0) {
        return userPurchases.some((purchase) => purchase.productId === productId);
      }
      return false;
    },
    [userPurchases],
  );

  useEffect(() => {
    if (user) {
      let active = true;

      const getAllUserProductPurchasesOnMount = async () => {
        const result = await getAllUserProductPurchases();
        if (result && active) {
          setUserPurchases(result);
          setLoadingUserPurchases(false);
        } else {
          if (active) {
            setUserPurchases([]);
            setLoadingUserPurchases(false);
          }
        }
      };
      getAllUserProductPurchasesOnMount();

      return () => {
        active = false;
        setLoadingUserPurchases(true);
      };
    }
  }, [user, refetchUserPurchases]);

  const contextValue = useMemo(
    () => ({
      userPurchases,
      loadingUserPurchases,
      handleRefetchUserPurchases,
      hasUserPurchasedProduct,
    }),
    [userPurchases, loadingUserPurchases, handleRefetchUserPurchases, hasUserPurchasedProduct],
  );

  return <PurchaseContext.Provider value={contextValue}>{children}</PurchaseContext.Provider>;
};
