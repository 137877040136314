import { posthog } from "posthog-js";
import queryString from "query-string";

import Cookies from "universal-cookie";

export const persistUrlParams = () => {
  // Check for the friend referral query parameter here so it doesn't get lost in the redirect
  // to dashboard if the user is already logged in.
  if (typeof window === undefined) return;
  let friendReferral;
  let urlParams;
  try {
    urlParams = queryString.parse(window.location.search);
  } catch (e) {
    console.warn("unable to parse query params");
    return;
  }
  friendReferral = urlParams?.friend;

  const cookies = new Cookies();
  if (urlParams) {
    const {
      utm_source,
      utm_medium,
      utm_term,
      utm_campaign,
      utm_content,
      aaCampaign,
      source,
      ref,
      code,
      friend,
      slug,
      p,
    } = urlParams;
    if (utm_source && typeof utm_source === "string") {
      localStorage.setItem("utm_source", utm_source);
      posthog.people.set_once({
        utm_source: utm_source,
      });
    }
    if (source && typeof source === "string") {
      localStorage.setItem("source", source);
      posthog.people.set_once({
        source: source,
      });
    }
    if (utm_medium && typeof utm_medium === "string") {
      localStorage.setItem("utm_medium", utm_medium);
      posthog.people.set_once({
        utm_medium: utm_medium,
      });
    }
    if (utm_term && typeof utm_term === "string") {
      localStorage.setItem("utm_term", utm_term);
      posthog.people.set_once({
        utm_term: utm_term,
      });
    }
    if (utm_campaign && typeof utm_campaign === "string") {
      localStorage.setItem("utm_campaign", utm_campaign);
      posthog.people.set_once({
        utm_campaign: utm_campaign,
      });
    }
    if (utm_content && typeof utm_content === "string") {
      localStorage.setItem("utm_content", utm_content);
      posthog.people.set_once({
        utm_content: utm_content,
      });
    }
    if (aaCampaign && typeof aaCampaign === "string") {
      localStorage.setItem("aaCampaign", aaCampaign);
    }
    if (ref && typeof ref === "string") {
      localStorage.setItem("ref", ref);
      posthog.people.set_once({
        ref: ref,
      });
    }
    if (code && typeof code === "string") {
      localStorage.setItem("code", code);
      posthog.people.set_once({
        code: code,
      });
    }
    if (friend && typeof friend === "string") {
      localStorage.setItem("friend", friend);
      posthog.people.set_once({
        friend: friend,
      });
    }
    if (slug && typeof slug === "string") {
      localStorage.setItem("slug", slug);
      posthog.people.set_once({
        slug: slug,
      });
    }
    if (p && typeof p === "string") {
      localStorage.setItem("p", p);
      posthog.people.set_once({
        p: p,
      });
    }
    // console.log("persisting P code ? ", p);
  }
  if (friendReferral) {
    cookies.set("friendReferral", friendReferral, { path: "/" });
  }
};

export const resetReferralParams = () => {
  const referralParams = [
    "ref",
    "code",
    "friend",
    "utm_source",
    "utm_medium",
    "utm_term",
    "utm_campaign",
    "utm_content",
    "aaCampaign",
    "source",
    "noPriceOffer",
    "p",
    "slug",
  ];

  referralParams.forEach((param) => {
    localStorage.removeItem(param);
  });
};
