import { fetchApi } from "./fetchStudioApi";
import { UserWithConfirmedAttendance } from "./sfSessionEnroll";

export interface SFNotification {
  id: string;
  fromUser: UserWithConfirmedAttendance;
  title: string;
  description: string;
  isUnread: boolean;
  createdAt: string;
  reference?: {
    referenceType: string;
    referenceId: string;
  };
}

export interface SFNotificationResponse {
  notifications: SFNotification[];
  hasUnreadNotifications: boolean;
}

export const getNotifications = async (
  lastNotification: SFNotification | undefined = undefined,
  unreadOnly: boolean = false,
): Promise<SFNotificationResponse> => {
  let urlPath = `/getNotifications`;

  if (lastNotification) {
    urlPath += `?lastEvaluatedKey=${encodeURIComponent(
      JSON.stringify({
        id: lastNotification.id,
        targetUserId: lastNotification.fromUser.userId,
        createdAt: lastNotification.createdAt,
      }),
    )}`;
  }

  if (unreadOnly) {
    urlPath += lastNotification ? "&unreadOnly=true" : "?unreadOnly=true";
  }

  console.log("urlPath", urlPath);

  let response = await fetchApi({
    authRequired: true,
    path: urlPath,
    method: "GET",
  });

  return response.response;
};

export const markAllNotificationsAsRead = async (): Promise<void> => {
  await fetchApi({
    authRequired: true,
    path: "/markAllNotificationsAsRead",
    method: "POST",
  });
};

export const markNotificationAsRead = async (notificationId: string): Promise<void> => {
  const body = JSON.stringify({
    notificationId: notificationId,
  });

  await fetchApi({
    authRequired: true,
    path: `/markNotificationAsRead`,
    method: "POST",
    body: body,
    isJsonPayload: true,
  });
};

export const getContentForReference = async (
  referenceType: string,
  referenceId: string,
): Promise<any> => {
  let urlPath = `/getContentForReference?referenceType=${referenceType}&referenceId=${referenceId}`;

  let response = await fetchApi({
    authRequired: true,
    path: urlPath,
    method: "GET",
  });

  console.log("getContentForReference", response);

  return response.response;
};
