import "../../../../../node_modules/@vanilla-extract/webpack-plugin/vanilla.virtual.css?%7B%22fileName%22%3A%22src%2Fcomponents%2Fschool%2Fcommon%2FButton%2FButton.css.ts.vanilla.css%22%2C%22source%22%3A%22%23H4sIAAAAAAAAA71VXY%2BiMBR991fchGyiiTWAgNhJJhl39Dfs26ZAhe7UlpSiOJv575sWZwRHnPVhN9eH2ttzP865LbOfXugdFvTFhd8jgIxVJSdHDFtOm4cRAOEsF4hpuqswpFRoqsz2r7rSbHtEqRSaCt2eR1RkxpmTEsOitPi0VpVUGErJWuzbaPae0rMpS1kxzaTAoCgnmu3pw%2BdCOijfog4s0wUGz3W%2FmeMFZXmhz%2F%2FPQUlSSV5rG3TLuKYKQ8JrNfbCspmY3USqjCqkSMbqyoZoS09I%2BpIrWYsMA2eCEoVyc4gKPdYSlMk4BWez2izWoVl4q2j5NAVnvViG8dosomXw7BpXtHSfl1NwVn4crIMpOE9e9N2iVk%2FP3saf9Fqc2xa3Umi0JTvGjxj2RI0RqiOieeEfbN3Wfzg1Hrnux16lj5xeQI5nSMVeL72v1muaRO9Mdv1z1%2FpLkmVM5BjCsgEvOLHUZy86kZdKbmTvRgnaLF1ae25h3a%2BIiYw2GLwrYzA4j3bk4jb1oWCaoqokKcUg5EGRssducD%2B7PX8z%2BX9MB4Zp%2FybT7TYGr2ygkpxl4Mw9Y0MytP39C2ZDy2xXYMcPjQ2V0gwO8v3ldeqIvlBY3X9%2F9E1V69uq%2Bvu%2BqnHZgH%2F9%2FsSDov4Nk4lsUFWQTB4wuCZJ2dgRMmuVJ2TsTuH0m%2FmhhWhFxPtbeVbO%2BCugpKKICSRr3ecXZ6wiCafZheDoWmm7yQW4kHuqBpAfbXYQi89j5W2MDZEhJgMcvsO6we8uJ%2F5UzpXkw%2B9fb%2BwW7dh1RZAlSZk%2B3lYg%2FkoBx%2FeMDZWyG7gBvRQ3idm4xkyUU8EY3Fnci7C02B1RORNItVnase8cck9pqn1uj1sqtlLtcLvkRNMfY99%2Bq6%2FAklprKeDxC7w7hDewRyiJLto3g3HepyqcXwdefE6GxrCLNMmm51xd0Qnn4M7mw4LPTy0%2BgqKp7tTqhIGxh9HbHwhWPebLCQAA%22%7D"
export var assignmentButton = '_151w7ek3';
export var button = '_151w7ek5 _151w7ek4';
export var buttonContainer = '_151w7ek0';
export var gradientUnderlay = '_151w7ek2';
export var marginRight = '_151w7ek9';
export var optionalButton = '_151w7ek4';
export var quaternaryButton = '_151w7ek8';
export var relativePosition = '_151w7ek1';
export var tertiaryButton = '_151w7ek6';
export var tertiaryButtonFilled = '_151w7ek7';