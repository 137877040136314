import "../../../../../node_modules/@vanilla-extract/webpack-plugin/vanilla.virtual.css?%7B%22fileName%22%3A%22src%2Fcomponents%2Fschool%2FSchoolModal%2FCurriculumOverviewModal%2FCurriculumOverviewModal.css.ts.vanilla.css%22%2C%22source%22%3A%22%23H4sIAAAAAAAAA71WbW%2FbIBD%2Bnl%2FBVFVqJ1H5rUlKpWn%2FZCI2tlkxIMCx06n%2FfQI7Njhu2krTxAcnd9zdw3N3HA%2B%2F9nuj2yoCfzYANLiHHS1MjUAcRZHsnzcAzILb53FPTWhVGyc71lZ4FlhlLrgh3FixxEVBeYVAupM9yBLZg8h9rPKA85dKiZYXCNzEj3Y5seihrnEhOgSOWN1B2G6xYXVa3A9qVRCFQCx7oAWjBbhJY7tmJVS4oK1GIN7aSG%2Bbh%2FGQsTtkQbVk%2BIRAyYgDYr%2BwoIrkhgqOQC5Y23CHX2g6yBRh2NAj8d0l6%2B5%2Bt9rQ8nTmAYGccEOUVWFGKw6pIY32xQ3lE6XpmXaP41s%2FauqijknJFpvP%2F3PBhAr5y%2Fp7303m3ExBVlM9ZwiODj2uG6wqyi1gm1Xf9aNzPeU%2Bcitd5GK7Tt47DFVYIrBfBXlBt5Y4J%2FBATEcI90Pu%2FlVIcSSqZLZAa1oUYZD9l4IEjmcnT85JKbiBJW4oO4WpjLVrBafvxgzuomiSaXNiZGHSziaavi61R6dllJO5EJPrpTRXQAS2rrEdgK6mhkCXAwS46BSWVmxIb%2BDMG2GMSk31R2xiR0TeKm0xSEEXxNnOme6b2ezwdf6y%2F8nfMeTPh158AL2%2FhL79APrrNehJdAk90Mf3n62CCDwtrwKy3g2ut%2BKRoMEBNMKTvXclz57LcWBNFC7OkJw9zceOM0%2B0yPva%2BbrgwqxcwLlYcWvEch7uFuNwnpkDzDgaGmWgybsjs0GRTRqdK8EYPJAaH6lFphshTD2rDlidRzUXPJhLFUKwI4cXauC0N8zD0qL%2B3GBUovOt6PVSTbov31LJ6Wqpvl4v1TRatpV3Jsqd3YGJ%2FOVzExIDic3ATEkZQ%2BBm92jX89qN5Jmh2hbJhXFZlmH9%2FgDfZwKDcnzb%2FGxIQTG48x5k%2BzST%2Fb2zeAgebcGzLccsv7NlByBIIjm06Jtnsx9t3n%2F0jO0ZDb%2BDweWMtMHKDG5XgWb2BRMCzcegQQGCb7SRQhnMz97%2BAp7p3bqNCgAA%22%7D"
export var animatedSpinner = '_88tsug3';
export var area = '_88tsugh';
export var areaTitle = '_88tsugi';
export var areasContainer = '_88tsuge';
export var closeButton = '_88tsuga';
export var containerPaddingLeft = '_88tsug5';
export var dividerLine = '_88tsug4';
export var hideOnMobile = '_88tsugc';
export var loadingContainer = '_88tsug2';
export var markdownContent = '_88tsugf';
export var modalContent = '_88tsug0';
export var modalContentContainer = '_88tsug1';
export var modalSubtitle = '_88tsugb';
export var modalTitle = '_88tsug9';
export var modalTitleContainer = '_88tsug6';
export var scrollContainer = '_88tsugg';
export var sectionTitle = '_88tsugd';
export var titleContent = '_88tsug7';
export var titlesContainer = '_88tsug8';