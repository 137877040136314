import "../../../node_modules/@vanilla-extract/webpack-plugin/vanilla.virtual.css?%7B%22fileName%22%3A%22src%2Fcomponents%2Fauth%2FFormLayout.css.ts.vanilla.css%22%2C%22source%22%3A%22%23H4sIAAAAAAAAA82VwW6jMBCG73kKX1YqXbkykKZZVlrtm6wMnhB3jU2NSaCrvPsKQ1psCEnVS09IHjPD988%2Fw8Of8BDtFN0S9G%2BF0JEzs09QSMi3nyuEGK9KQdsE7QQ03UH3xIxryAxXMkGZEnUhu8hzXRm%2Ba3GmpAFpEpSBNKC7EBU8l5gbKKrxcakq3mfRIKjhB%2BhOC6pzLhNEEK2NsjWVNLjir5CgcFvaz0iVZqCxpozXVYLs6Wn1cGYJP8lSUsa4zHGqjFFFgqLIKxDZAjdnHL0Zz785Ua8qaQY4BXMEkBMRU1qB4BKc1Gub2qq1owUXbYIOVN9hXG%2BoEftQBm9qHoHne5OgJ0LeFTatAO%2BVMvAa4ERfbLT7DrwfEjpxbeOZEkq7kXURvLf6TWXi0Dwu00THKY0Tb4IlsqhdIotel8licpGsDsYmDjdlg8jEn5upP53oL3SPvqN7e2sQyagyQdHaS%2FRkr3jTEBLv1hZxWdZmlM4X%2B8dNA%2FNBk54n3akzgyZgZ2bYqL1zHsV5%2FvSCRJ4x1sTFzT42vh5XTw0HkKIdLSNbfuyFxyxwqrKv72gDjcEW1lnU4x4MG3jO%2By8uLyzzxuEybxwt8sbxEm%2B8vsL7eHGCD8Gs%2Ba%2Fv3931ec3tlazWVVe2VPwssRWeQaY07b1XSwa6L%2BFptPHMvJ%2FOkrOF3abweetf%2BkX3nR8WWUT63o8RHemscinN%2FuZa1ZLhGXnjPLjhB%2F789UflPBRTxWOr%2BO8CGKforqANHhbrhpCyCSyat3mXds%2FJvT5eoF7bh%2FbML8TT6vQfmYbOKuwJAAA%3D%22%7D"
export var altAction = '_1v2foa8g';
export var altActionContainer = '_1v2foa8e';
export var altActionSpaceOnLeft = '_1v2foa8h _1v2foa8g';
export var bottomAltActionContainer = '_1v2foa8f _1v2foa8e';
export var buttonSection = '_1v2foa8c';
export var container = '_1v2foa80';
export var formControls = '_1v2foa86';
export var generalFormError = '_1v2foa8d';
export var inputWithoutMargin = '_1v2foa88';
export var inputWrapper = '_1v2foa87';
export var messageContainer = '_1v2foa8i';
export var messageText = '_1v2foa8j';
export var primaryButton = '_1v2foa8b';
export var sectionRow = '_1v2foa89';
export var subTitle = '_1v2foa85';
export var termsAgreementRow = '_1v2foa8a _1v2foa89';
export var title = '_1v2foa84';
export var titleRow = '_1v2foa83';
export var titlesColumn = '_1v2foa82';
export var topSection = '_1v2foa81';