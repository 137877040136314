import { useContext } from "react";

import { AuthModalContext } from "./AuthModalProvider";

// full context provider
// not needed for most components
export const useAllAuthModalFunctions = () => {
  return useContext(AuthModalContext);
};

// useAuth contains a subset of auth properties
// likely to be widely used throughout the app
export const useAuth = () => {
  const {  presentAuthModal, closeAuthModal } = useContext(AuthModalContext);
  return { presentAuthModal, closeAuthModal };
};