import React, { useCallback, useMemo } from "react";

import { AUTH_MODAL_VIEW } from "~/analytics/Amplitude/amplitudeConstants";
import { useAnalytics } from "~/analytics/useAnalytics";
import { AuthModal } from "~/components/auth/AuthModal";
import { AuthForm } from "~/components/auth/authTypes";
import { AuthModalInteractionSource } from "./AuthProvider";

export type PresentAuthModalAnalyticsData = {
  pageSource?: string;
  interactionSource?: AuthModalInteractionSource;
};

interface AuthState {
  presentAuthModal(entryAuthForm?: AuthForm, analytics?: PresentAuthModalAnalyticsData): void;
  closeAuthModal(): void;
}

export const AuthModalContext = React.createContext<AuthState>({
  presentAuthModal: () => {},
  closeAuthModal: () => {},
});

interface AuthModalProviderProps {
  children: React.ReactNode;
}

export const AuthModalProvider = ({ children }: AuthModalProviderProps) => {
  const [isAuthModalOpen, setIsAuthModalOpen] = React.useState(false);
  const [entryAuthForm, setEntryAuthForm] = React.useState<AuthForm>(AuthForm.Login);
  const { trackAmplitudeEvent } = useAnalytics();

  const presentAuthModal = useCallback(
    (entryAuthForm?: AuthForm, analytics?: PresentAuthModalAnalyticsData) => {
      setIsAuthModalOpen(true);
      if (entryAuthForm) {
        setEntryAuthForm(entryAuthForm);
      }
      trackAmplitudeEvent(AUTH_MODAL_VIEW, {
        authType: entryAuthForm,
        pageSource: analytics?.pageSource,
        interactionSource: analytics?.interactionSource,
      });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );

  const onCloseModal = useCallback(() => {
    setIsAuthModalOpen(false);
  }, []);

  const closeAuthModal = () => {
    setIsAuthModalOpen(false);
  };

  // useMemo to not recreate object on each render
  const contextValue = useMemo(
    () => ({
      presentAuthModal,
      closeAuthModal,
    }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );

  return (
    <AuthModalContext.Provider value={contextValue}>
      {children}
      <AuthModal entryAuthForm={entryAuthForm} isOpen={isAuthModalOpen} onClose={onCloseModal} />
    </AuthModalContext.Provider>
  );
};
