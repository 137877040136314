/**
 * Amplitude tracking events for web
 *
 * These should match values in `frontend` repo to prevent analytics discrepancies
 * */

// API Gateway host for our amplitude proxy
export const API_GATEWAY_AMPLITUDE_PROXY = "w29w3narz0.execute-api.us-east-2.amazonaws.com/data";

// Checkout page
export const CHECKOUT_PAGE_VISIT = "checkout | page visit";
export const CHECKOUT_PAGE_PAY_SUBMIT_SUCCESS = "checkout | payment submit success";
export const CHECKOUT_PAGE_PAY_SUBMIT_FAILURE = "checkout | payment submit failure";
export const CHECKOUT_PAGE_PAYPAL_CLICK = "checkout | paypal checkout click";
export const CHECKOUT_PAGE_SELECT_AFFIRM_CLICK = "checkout | affirm continue checkout click";
export const CHECKOUT_PAGE_AFFIRM_CANCEL = "checkout | affirm checkout cancel";

// Account
export const CREATE_ACCOUNT_FORM_SUBMIT_SUCCESS = "create account | success";
export const CREATE_ACCOUNT_FORM_SUBMIT_FAILURE = "create account | failure";
export const ACCOUNT_PROFILE_UPDATED = "account | profile updated";
export const ACCOUNT_PROFILE_VIEWED = "account | profile viewed";
export const USER_PROPERTY_LEAD_SOURCE_INFO = "lead source info";

// Auth
export const AUTH_MODAL_VIEW = "auth modal | view";
export const AUTH_MODAL_SWITCH_TO_CREATE_ACCOUNT = "auth modal | create account clicked";
export const AUTH_MODAL_SWITCH_TO_LOGIN = "auth modal | log in clicked";
export const AUTH_MODAL_ENTER_EMAIL = "auth modal | email signup click";

export const LOGIN_SUCCESSFUL = "login | success";
export const LOGIN_FAILURE = "login | failure";

// Home page
export const HOME_PAGE_VISIT = "home landing page | page visit";
export const HOME_PAGE_GET_STARTED = "home landing page | get started clicked";
export const HOME_PAGE_LOGIN = "home landing page | log in clicked";

// Music page
export const MUSIC_PAGE_VISIT = "music landing page | page visit";
export const MUSIC_PAGE_GET_STARTED = "music landing page | get started clicked";
export const MUSIC_PAGE_LOGIN = "music landing page | log in clicked";

// Curriculum Page
export const CURRICULUM_PAGE_VISIT = "school curriculum page | page visit";

// Onboarding page
export const ONBOARDING_PAGE_VISIT = "school onboarding page | page visit";

export const CURRICULUM_LOADING_PAGE_VISIT = "school curriculum loading page | page visit";

// Music page
export const CHALLENGE_PAGE_VISIT = "challenge landing page | page visit";
export const CHALLENGE_PAGE_GET_STARTED = "challenge landing page | get started clicked";
export const CHALLENGE_PAGE_LOGIN = "challenge landing page | log in clicked";

// Product page
export const PRODUCT_LANDING_PAGE_VISIT = "product landing | page visit";
export const PRODUCT_PAGE_GET_STARTED = "product landing | get started clicked";
export const PRODUCT_LANDING_PAGE_LOGIN = "product landing | log in clicked";
export const PRODUCT_PAGE_BUY_NOW_SELECTED = "product landing | buy now selected";
export const PRODUCT_PAGE_ADD_TO_CART_SELECTED = "product landing | add to cart selected";
export const PRODUCT_PAGE_ADD_TO_CART_MODAL_VIEWED = "add to cart | modal viewed";

// Class landing page
export const CLASS_LANDING_PAGE_VISIT = "class landing | page visit";
export const CLASS_LANDING_PAGE_LOGIN = "class landing | log in clicked";

//Creator Dashboard
// Home page
export const CREATOR_STUDIO_PAGE_VISIT = "creator storefront page | page visit";
export const CREATOR_STUDIO_PAGE_GET_STARTED = "creator storefront page | get started clicked";
export const CREATOR_STUDIO_PAGE_LOGIN = "creator storefront page | log in clicked";

// BTS Video landing page
export const BTS_PRODUCT_VIDEO_PAGE_VISIT = "bts product video page | page visit";
export const BTS_PRODUCT_VIDEO_PAGE_GET_STARTED = "bts product video page | get started clicked";
export const BTS_PRODUCT_VIDEO_PAGE_LOGIN = "bts product video page | log in clicked";
export const BTS_PRODUCT_VIDEO_SUCCESS_MODAL_VIEW = "bts product video page | success modal viewed";
export const BTS_PRODUCT_VIDEO_END_SESSION = "bts product video page | end session";

// Creators landing page
export const CREATORS_PAGE_VISIT = "creators page | page visit";
export const CREATORS_PAGE_GET_STARTED = "creators page | get started clicked";
export const CREATORS_PAGE_LOGIN = "creators page | log in clicked";

// Creator Dashboard
export const CREATOR_DASHBOARD_PAGE_VISIT = "creator dashboard page | page visit";
export const CREATOR_DASHBOARD_PAGE_GET_STARTED = "creator dashboard page | get started clicked";

// Review
export const REVIEW_MODAL_SUBMIT_FEEDBACK = "bts video | feedback submitted";

// Creator Storefront sharing
export const CREATORS_PAGE_SHARE_MODAL_VISIT = "creator storefront page | share modal viewed";
export const CREATORS_PAGE_SHARE_MODAL_SHARED = "creator storefront page | share modal shared";

// Library landing page
export const LIBRARY_PAGE_VISIT = "library page | page visit";
export const LIBRARY_PAGE_LOGIN = "library page | log in clicked";

// Cart shopping page
export const CART_PAGE_VISIT = "shopping cart | page visit";
export const CART_EXPERIENCE_VIEWED = "experience | viewed";
export const CART_EXPERIMENT_VIEWED = "experiment | viewed";
export const CART_PAGE_ITEM_DELETED = "shopping cart | item deleted";
export const CART_PAGE_ITEM_ADDED = "shopping cart | item added";
export const CART_PAGE_ALL_ITEMS_ADDED = "shopping cart | all items added";

// Cart checkout page
export const CART_CHECKOUT_PAGE_VISIT = "checkout | page visit";
export const CART_CHECKOUT_PAGE_PURCHASE_SUCCESS = "checkout | purchase success";

// Collections page
export const COLLECTIONS_PAGE_VISIT = "collections page | page visit";
export const COLLECTIONS_PAGE_LOGIN = "collections page | log in clicked";
export const COLLECTIONS_PAGE_GET_STARTED = "collections page | get started clicked";
export const COLLECTIONS_PAGE_BUY_NOW_SELECTED = "collections page | buy now clicked";

// Browse page
export const BROWSE_PAGE_VISIT = "browse landing page | page visit";
export const BROWSE_PAGE_GET_STARTED = "browse landing page | get started clicked";
export const BROWSE_PAGE_LOGIN = "browse landing page | log in clicked";

// Schools page
export const SCHOOLS_PAGE_VISIT = "schools page | page visit";
export const SCHOOLS_PAGE_LOGIN = "schools page | log in clicked";
export const SCHOOLS_PAGE_GET_STARTED = "schools page | get started clicked";
