import Image, { ImageProps } from "next/image";

import { scale } from "@cloudinary/url-gen/actions/resize";

import {
  cloudinary,
  addCloudinaryParameters,
  getCloudinaryUrlWithoutParameters,
} from "~/util/cloudinary-utils";

type Props = Omit<ImageProps, "src"> & {
  src: string;
};

const cloudinaryRoute = "https://res.cloudinary.com/monthly/image/upload/";

export const ImageWithBlur = (imageProps: Props) => {
  const { src } = imageProps;
  if (typeof src !== "string" || !((src || "").indexOf(cloudinaryRoute) !== -1)) {
    return <Image {...imageProps} alt={imageProps.alt} />;
  }

  const optimizedSrc = addCloudinaryParameters(src, ["q_auto:best"]);

  const blurredImage = cloudinary
    .image(src.replace(cloudinaryRoute, ""))
    .quality(1)
    .resize(scale(0.2, 0.2))
    .toURL();

  return (
    <Image
      {...imageProps}
      src={optimizedSrc}
      alt={imageProps.alt}
      unoptimized={true}
    />
  );
};
