import { ProductData } from "data/product/products";

export const initializeFacebookPixel = () => {
  const FACEBOOK_PIXEL_CLIENT = (window as any)?.fbq;

  if (FACEBOOK_PIXEL_CLIENT) {
    FACEBOOK_PIXEL_CLIENT("track", "PageView");
  }
};

export const trackFBCreateUser = () => {
  const FACEBOOK_PIXEL_CLIENT = (window as any)?.fbq;

  if (FACEBOOK_PIXEL_CLIENT != null) {
    FACEBOOK_PIXEL_CLIENT("track", "CompleteRegistration");
  }
};

export const trackFBCollectEmail = (
  email: string,
  trackingEvent: string,
  classInterestedIn: string,
) => {
  console.log("tracking fb ", trackingEvent, classInterestedIn);
  const FACEBOOK_PIXEL_CLIENT = (window as any)?.fbq;

  if (FACEBOOK_PIXEL_CLIENT != null) {
    FACEBOOK_PIXEL_CLIENT("track", trackingEvent, { content_ids: [classInterestedIn] });
  }
};
