export const getUserFacingCognitoError = (
  errorMessage: string | undefined,
  authPhase: "login" | "signup",
) => {
  if (errorMessage === "Username should be an email.") {
    return "Email is not formatted correctly.";
  }
  if (errorMessage?.includes("Password did not conform with policy:")) {
    return errorMessage.substring("Password did not conform with policy:".length);
  }
  if (
    errorMessage ===
      "1 validation error detected: Value at 'password' failed to satisfy constraint: Member must have length greater than or equal to 6" ||
    errorMessage?.indexOf("Password not long enough") !== -1
  ) {
    return "Password must be at least 8 characters long.";
  }
  if (errorMessage?.indexOf("^[\\S]+.*[\\S]+$") !== -1) {
    return "Password cannot begin or end with a space.";
  }
  if (errorMessage === "Failed to fetch") {
    const erroringPhase = authPhase == "login" ? "logging in" : "creating you account";
    return `There was an error ${erroringPhase}. Please try again later or contact us.`;
  }
  return errorMessage;
};
