import React from "react";

import * as styles from "./CircleProgressBar.css"

interface CircleProgressBarProps {
  percentage?: number;
  label: string;
}

export const CircleProgressBar: React.FC<CircleProgressBarProps> = ({
  percentage = 0,
  label,
}: {
  percentage?: number;
  label: string;
}) => {
  const circleStyles = {
    width: "100%",
    height: "100%",
    borderRadius: "50%",
    background: `radial-gradient(closest-side, rgb(0,0,0,1) 92%, transparent 90% 100%), conic-gradient(#4167D1 ${percentage}%, #fff 0)`,
    boxSizing: "border-box",
  } as React.CSSProperties;

  return (
    <div className={styles.circleProgressBarStyles}>
      <div style={circleStyles}></div>
      <span className={styles.percentageCount}>{label}</span>
    </div>
  );
};
