import { FeaturedMedia, Session } from "~/hooks/currentSessionProvider";



import { fetchApi } from "./fetchStudioApi";
import { Post } from "./sfPeersApi";

export interface InstructorInfo {
  instructorDisplayName: string;
  instructorShortBio: string;
  instructorPhotoURL: string;
}
export interface CurriculumModule {
  id: string;
  userId: string;
  title: string;
  description: string;
  startDate: string;
  endDate: string;
  sessionSlug?: string;
  instructorInfoMap: {
    [key: string]: InstructorInfo;
  };
  completedOn?: string;
  isLocked?: boolean;
  items?: CurriculumItem[];
}

export interface CurriculumItem {
  id: string;
  userId: string;
  actionType: string;
  title: string;
  description: string;
  isFinalProject?: boolean;
  startDate: string;
  dueDate: string;

  completedOn?: string;

  itemThumbnailURLs: string[];
  instructorSlugs: string[];

  peerFeedbackAssignments: { postId: string; completedWithCommentId: string }[];
  isOptional: boolean;

  moduleId: string;

  content: CurriculumItemContent[];

  nextId?: string | null;
  prevId?: string | null;

  hasDismissedDetailCover?: boolean;
  whatToDo?: string;
}

export interface CurriculumItemContent {
  id: string;
  curriculumItemId: string;
  contentNotes: string;
  SFLessonOverview: string;

  instructor: InstructorInfo;

  lessonContentId: string;
  productId: string;
  wistiaHashedId: string;
  title: string;
  videoLength?: string;

  userVideoTimestampInSeconds: string;

  // for supplements
  supplementExplanation: string;
  startTimeInSeconds: string;
  endTimeInSeconds: string;

  // for vocal lessons
  type?: string; // "low voice", "high voice", "instrumental" kinda vibe
  description?: string;
}

export interface UserStat {
  slug: string;
  title: string;
  count: number;
}

export interface PeerFeedbackAssignment {
  completedWithComment: string;
  post: Post;
}

export interface FeedbackItem {
  prevFeedbackId: string | null;
  nextFeedbackId: string | null;
  postId: string;
}

export const getInstructorInfoMapFromSession = (
  session: Session,
): { [key: string]: InstructorInfo } => {
  let instructorInfoMap: { [key: string]: InstructorInfo } = {};
  session.featuredInstructors.forEach((instructor) => {
    instructorInfoMap[instructor.instructorSlug] = {
      instructorDisplayName: instructor.instructorDisplayName,
      instructorShortBio: instructor.instructorShortBio,
      instructorPhotoURL: instructor.instructorPhotoURL,
    };
  });
  return instructorInfoMap;
};

export const pullCurriculumModuleForToday = async (): Promise<CurriculumModule> => {
  let response = await fetchApi({
    path: `/pullCurriculumModuleForToday`,
    method: "GET",
    authRequired: true,
  });

  console.log("pullCurriculumModuleForToday response", response);

  return response.response;
};

export const pullCurriculumForSessionAndUser = async (
  sessionSlug?: string,
  userId?: string,
): Promise<CurriculumModule[]> => {
  let urlPath = `/pullCurriculumForSessionAndUser`;
  if (sessionSlug) {
    urlPath += `?session_slug=${sessionSlug}`;
  }
  if (userId) {
    urlPath += `&owner_user_id=${userId}`;
  }
  console.log("FETCHING CURRICULUM W ID ", userId);
  let response = await fetchApi({
    authRequired: true,
    path: urlPath,
    method: "GET",
  });

  return response.response;
};

export const getUserOnboardingConversation = async (
  sessionSlug?: string,
  userId?: string,
): Promise<string> => {
  let urlPath = `/getUserOnboardingConversation`;
  if (sessionSlug) {
    urlPath += `?session_slug=${sessionSlug}`;
  }
  if (userId) {
    urlPath += `&owner_user_id=${userId}`;
  }
  let response = await fetchApi({
    authRequired: true,
    path: urlPath,
    method: "GET",
  });
  return response.response;
};

export const getStatsForUser = async (userId: string): Promise<UserStat[]> => {
  let response = await fetchApi({
    path: `/getStatsForUser?user_id=${userId}`,
    method: "GET",
    authRequired: true,
  });
  console.log("pulling stats for user ", response);
  return response.response;
};

export const pullPeerFeedbackAssignmentsForItem = async (
  curriculumItemId: string,
  sessionSlug?: string,
): Promise<PeerFeedbackAssignment[]> => {
  let path = `/pullPeerFeedbackAssignmentsForItem?curriculum_item_id=${curriculumItemId}`;
  if (sessionSlug) {
    path += `&session_slug=${sessionSlug}`;
  }

  let response = await fetchApi({
    path: path,
    method: "GET",
    authRequired: true,
  });

  console.log(
    "pullPeerFeedbackAssignmentsForItem response",
    response.response as PeerFeedbackAssignment[],
  );

  return response.response;
};

export const pullSessionsAndProjectsForUser = async (userId?: string): Promise<Session[]> => {
  let urlPath = `/pullSessionsAndProjectsForUser`;
  if (userId) {
    urlPath += `?user_id=${userId}`;
  }

  let response = await fetchApi({
    path: urlPath,
    method: "GET",
    authRequired: true,
  });
  return response.response;
};

export const submitAssignedFeedback = async (
  feedback: string,
  postId: string,
  curriculumItemId?: string,
) => {
  const requestBody = JSON.stringify({
    feedback: feedback,
    post_id: postId,
    curriculum_item_id: curriculumItemId,
  });

  let response = await fetchApi({
    path: `/submitAssignedFeedback`,
    method: "POST",
    authRequired: true,
    isJsonPayload: true,
    body: requestBody,
  });
  return response;
};

interface UploadMediaRequest {
  media_list: FeaturedMedia[];
}

export const uploadMedia = async (mediaList: FeaturedMedia[]) => {
  const requestBody: UploadMediaRequest = { media_list: mediaList };

  let response = await fetchApi({
    path: `/uploadMedia`,
    method: "POST",
    authRequired: true,
    isJsonPayload: true,
    body: JSON.stringify(requestBody),
  });

  return response.response;
};

export const deletePost = async (postId: string) => {
  let response = await fetchApi({
    path: `/deletePost`,
    method: "POST",
    authRequired: true,
    isJsonPayload: true,
    body: JSON.stringify({ post_id: postId }),
  });
  return response;
};

export const addPost = async ({
  current_session_slug,
  title,
  description,
  media_ids,
  post_id,
  curriculum_item_id,
}: {
  current_session_slug: string;
  title: string;
  description: string;
  media_ids: string[];
  post_id?: string;
  curriculum_item_id?: string;
}) => {
  const requestBody = JSON.stringify({
    current_session_slug,
    title,
    description,
    media_ids,
    post_id,
    curriculum_item_id,
  });

  let response = await fetchApi({
    path: `/addPost`,
    method: "POST",
    authRequired: true,
    isJsonPayload: true,
    body: requestBody,
  });
  return response;
};

export const setCurriculumItemCompletedOn = async (curriculumItemId: string) => {
  let response = await fetchApi({
    path: `/setCurriculumItemCompletedOn`,
    method: "POST",
    authRequired: true,
    isJsonPayload: true,
    body: JSON.stringify({ curriculum_item_id: curriculumItemId }),
  });
  return response;
};