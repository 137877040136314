import { useState, useEffect } from 'react';



import { useRouter } from "next/router";

import { StudioUser, fetchUserById } from "~/api/userApi";
import { useAuth } from "~/hooks/auth/useAuth";


// Define the hook function with a parameter for the query object
export const useAdminUserId = () => {
  const [adminUserIdToUse, setAdminUserIdToUse] = useState("");
  const [adminUserToUse, setAdminUserToUse] = useState<StudioUser | undefined>(undefined);
  const { query } = useRouter();
  const { user } = useAuth();

  const adminUserEmails = [
    "valpe70@gmail.com",
    "valentin@studio.com",
    "chantalevett@gmail.com",
    "max+finalms@studio.com",
    "craig+safaritest3@studio.com",
    "valentin+first-one@studio.com",
    "chantal+test7@studio.com",
    "chantal+test8@studio.com",
    "alex1@studio.com",
    "max@studio.com",
    "craig@studio.com",
  ];
  useEffect(() => {
    const fetchData = async () => {
      console.log("checking for adminUserIdView in query w user email ", user?.email);
      const adminUserId = query.adminUserIdView as string;

      console.log("adminUserIdView in query ", adminUserId);

      console.log(
        "is email admin email? ",
        user?.email,
        adminUserEmails.includes(user?.email || ""),
      );
      if (adminUserId && adminUserEmails.includes(user?.email || "")) {
        console.log("IN HOOK Admin User ID:", adminUserId);
        setAdminUserIdToUse(adminUserId);

        const user = await fetchUserById(adminUserId);
        console.log("user fetched in admin user hook ", user);
        setAdminUserToUse(user);
      }
    };
    if (adminUserEmails.includes(user?.email || "")) {
      fetchData();
    }
  }, [query, user]); // Dependency on the specific query parameter

  return { adminUserIdToUse, adminUserToUse };
};;