/* eslint-disable react/display-name */
import React, { forwardRef, LegacyRef } from "react";

import ReactPlayer, { ReactPlayerProps } from "react-player";

// import useActivePlayer from "~/components/classLanding/hooks/useActivePlayer";

interface Props {
  onInterrupt?: () => void; // callback for when another active player interrupts this one
}

/**
 * Basic unstyled media player that taps into Redux to allow only one active player at a time
 * Consumer may implement onInterrupt handler to handle interruptions appropriately (i.e. pause media)
 */
export const ActiveMediaPlayer = forwardRef(
  (props: Props & ReactPlayerProps, ref: LegacyRef<ReactPlayer>) => {
    // prevent passing onInterrupt prop to ReactPlayer instance
    const { onInterrupt, ...otherProps } = props;

    // pass along props to active player hook to subscribe to redux active media player changes
    // allows this component to interrupt and get interrupted by other media players
    // const overriddenProps = useActivePlayer(props);
    return <ReactPlayer ref={ref} {...otherProps} />;
  },
);
