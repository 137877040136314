import React, {FC} from 'react';

type Props = {
    color: string;
}

const DropdownIcon: FC<Props> = ({ color }): JSX.Element => {
    return (
        <svg
            width='14px'
            height='8px'
            viewBox="0 0 14 8"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M0.989883 0.664785C0.663217 0.991451 0.663217 1.51812 0.989883 1.84478L6.52988 7.38479C6.78988 7.64479 7.20988 7.64479 7.46988 7.38479L13.0099 1.84479C13.3365 1.51812 13.3365 0.991452 13.0099 0.664785C12.6832 0.338118 12.1565 0.338118 11.8299 0.664785L6.99655 5.49145L2.16322 0.658118C1.84321 0.338118 1.30988 0.338118 0.989883 0.664785Z"
                fill={color}
            />
        </svg>
    );
};

export default DropdownIcon;