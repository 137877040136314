import { useEffect } from "react";

import { Amplify } from "aws-amplify";
import type { AppProps } from "next/app";
import { useRouter } from "next/router";
import posthog from "posthog-js";
import { PostHogProvider } from "posthog-js/react";
import { QueryClientProvider } from "react-query";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "react-tooltip/dist/react-tooltip.css";

import { ThemeWrapper, ThemeType } from "studio-design-system";
import "studio-design-system/dist/style.css";

//import TiktokPixel from "tiktok-pixel";
import { useAnalytics } from "~/analytics/useAnalytics";
import { queryClient } from "~/api/reactQuery/client";
import { AuthModalProvider } from "~/hooks/auth/AuthModalProvider";
import { AuthProvider } from "~/hooks/auth/AuthProvider";
import { CartProvider } from "~/hooks/cart/CartProvider";
import { CurrentSessionProvider } from "~/hooks/currentSessionProvider";
import { PurchaseProvider } from "~/hooks/purchase/PurchaseProvider";
import { SchoolCoreProvider } from "~/hooks/schoolCoreProvider";
import { SchoolModalProvider } from "~/hooks/schoolCoreProvider/SchoolModalProvider";
import { SocketProvider } from "~/hooks/socketio/SocketContext";
import { isDeployPreview } from "~/util/isDeployPreview";
import { persistUrlParams } from "~/util/persistUrlParams";

import "../styles/globals.css";

interface CookieStore {
  domain?: string;
  secure: boolean;
}

let cookieStorage: CookieStore | undefined = {
  domain: process.env.NEXT_PUBLIC_COOKIE_AUTH_DOMAIN,
  secure: !process.env.NEXT_PUBLIC_COOKIE_AUTH_DOMAIN?.includes("localhost"),
};
// check if deploy preview
// because those are not deployed to our known domain, we need to use a different cookieStorage strategy
// otherwise authentication won't work
if (isDeployPreview()) {
  console.log("Deploy preview detected... using local storage for cookies");
  cookieStorage = undefined;
} else {
  console.log("Deploy preview not detected... using cookie storage for cookies");
}

// if (process.env.NODE_ENV === "production") {
//   console.log = function () {};
//   console.warn = function () {};
//   console.error = function () {};
// }

Amplify.configure({
  Auth: {
    mandatorySignIn: true,
    region: process.env.NEXT_PUBLIC_COGNITO_REGION,
    userPoolId: process.env.NEXT_PUBLIC_COGNITO_USER_POOL_ID,
    userPoolWebClientId: process.env.NEXT_PUBLIC_COGNITO_APP_CLIENT_ID,
    authenticationFlowType: "USER_PASSWORD_AUTH",
    cookieStorage,
    // // cookies only for prod and development, not for deploy previews
    // ...(process.env.NODE_ENV === "development" || process.env.NODE_ENV === "production"
    //   ? {
    //       cookieStorage: {
    //         domain: process.env.NEXT_PUBLIC_COOKIE_AUTH_DOMAIN,
    //         path: "/",
    //         expires: 365,
    //         secure: process.env.NODE_ENV !== "development", // only secure in prod
    //       }, // Our custom cookie storage
    //     }
    //   : {}),
  },
  ssr: true,
});

// Check that PostHog is client-side (used to handle Next.js SSR)
if (typeof window !== "undefined") {
  posthog.init(process.env.NEXT_PUBLIC_POSTHOG_KEY || "", {
    api_host: process.env.NEXT_PUBLIC_POSTHOG_HOST || "https://d269lps5lm0jby.cloudfront.net",
    // Disable in development
    loaded: (posthog) => {
      if (process.env.NODE_ENV === "development") posthog.opt_out_capturing();
    },
  });
}

function App({ Component, pageProps }: AppProps): JSX.Element {
  const { trackAmplitudeEvent } = useAnalytics();

  const router = useRouter();

  useEffect(() => {
    // Track page views
    const handleRouteChange = () => posthog?.capture("$pageview");
    router.events.on("routeChangeComplete", handleRouteChange);

    // Check if URL has encoded characters
    if (router.asPath.includes("%2B")) {
      // Replace encoded characters and push the new URL
      const newPath = decodeURIComponent(router.asPath);
      router.push(newPath, undefined, { shallow: false });
    }

    return () => {
      router.events.off("routeChangeComplete", handleRouteChange);
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    persistUrlParams();
  }, []);

  // useEffect(() => {
  //   //TiktokPixel.init(process.env.NEXT_PUBLIC_TIKTOK_PIXEL_ID as string);
  // }, []);

  useEffect(() => {
    import("react-facebook-pixel")
      .then((x) => x.default)
      .then((ReactPixel) => {
        ReactPixel.init(process.env.NEXT_PUBLIC_FB_PIXEL_ID as string); // facebookPixelId
        ReactPixel.pageView();
        console.log("");
        router.events.on("routeChangeComplete", () => {
          ReactPixel.pageView();
        });
      });
  }, [router.events]);

  return (
    <QueryClientProvider client={queryClient}>
      <ThemeWrapper theme={ThemeType.light}>
        <PostHogProvider client={posthog}>
          <SocketProvider>
            <AuthProvider>
              <ToastContainer
                position={"top-center"}
                style={{ width: "800px", lineHeight: "24px" }}
              />
              <AuthModalProvider>
                <PurchaseProvider>
                  <CartProvider>
                    <CurrentSessionProvider>
                      <SchoolCoreProvider>
                        <SchoolModalProvider>
                          <Component {...pageProps} />
                        </SchoolModalProvider>
                      </SchoolCoreProvider>
                    </CurrentSessionProvider>
                  </CartProvider>
                </PurchaseProvider>
              </AuthModalProvider>
            </AuthProvider>
          </SocketProvider>
        </PostHogProvider>
      </ThemeWrapper>
    </QueryClientProvider>
  );
}

export default App;
