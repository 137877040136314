/* eslint-disable react-hooks/exhaustive-deps */
import React, { useCallback, useMemo } from "react";

import { useRouter } from "next/router";
import { usePostHog } from "posthog-js/react";

import { AUTH_MODAL_VIEW } from "~/analytics/Amplitude/amplitudeConstants";
import { useAnalytics } from "~/analytics/useAnalytics";
import {
  authDeleteUser,
  authSignIn,
  AuthSignInArgs,
  authSignOut,
  authSignUp,
  AuthSignUpArgs,
  getCurrentAuthUser,
} from "~/api/authApi";
import { StudioUser } from "~/api/userApi";
import { AuthModal } from "~/components/auth/AuthModal";
import { AuthForm } from "~/components/auth/authTypes";
import { Result } from "~/util/resultType";

export enum AuthModalInteractionSource {
  HomepageCTA = "HomepageCTA",
  Header = "Header",
  Onboarding = "Onboarding",
}
export type PresentAuthModalAnalyticsData = {
  pageSource?: string;
  interactionSource?: AuthModalInteractionSource;
};

interface AuthState {
  loadingCurrentUser: boolean;
  user: StudioUser | null;
  signIn(args: AuthSignInArgs): Promise<Result<StudioUser>>;
  signUp(args: AuthSignUpArgs): Promise<Result<StudioUser>>;
  signOut(): Promise<Result<null>>;
  deleteUser(): Promise<Result<null>>;
}

export const AuthContext = React.createContext<AuthState>({
  loadingCurrentUser: false,
  user: null,
  signIn: async () => ({ type: "error", error: new Error("not initialized") }),
  signUp: async () => ({ type: "error", error: new Error("not initialized") }),
  signOut: async () => ({ type: "error", error: new Error("not initialized") }),
  deleteUser: async () => ({
    type: "error",
    error: new Error("not initialized"),
  }),
});

interface AuthProps {
  children: React.ReactNode;
}

export const AuthProvider = ({ children }: AuthProps) => {
  const [loadingCurrentUser, setLoadingCurrentUser] = React.useState(true);
  const [user, setUser] = React.useState<StudioUser | null>(null);
  const posthog = usePostHog();

  const router = useRouter();

  React.useEffect(() => {
    let active = true;
    const getCurrentUserOnMount = async () => {
      const result = await getCurrentAuthUser();
      if (result.type === "success" && active) {
        const user = result.value;
        setUser(user);

        console.log("GOT USER user.currentSessionSlug: ", user.currentSessionSlug);

        if (user.currentSessionSlug && router.asPath == "/") {
          router.push(`/school/music`);
        }

        posthog?.identify(user.userId, {
          email: user.email,
          firstName: user.firstName,
          lastName: user.lastName,
          username: user.username,
          signUpDate: user.signUpDate,
          miniBio: user.miniBio,
          membershipStatus: user.membershipStatus,
        });

        setLoadingCurrentUser(false);
      } else {
        if (active) {
          setUser(null);
          setLoadingCurrentUser(false);
        }
      }
    };
    getCurrentUserOnMount();
    return () => {
      active = false;
    };
  }, [setUser, setLoadingCurrentUser]);

  const signIn = useCallback(
    async (args: AuthSignInArgs) => {
      console.log("signing in with args: ", args);
      const result = await authSignIn(args);
      console.log("this is the signing result: ", result);
      if (result.type === "success") {
        const user = result.value;
        setUser(user);

        console.log("GOT USER user.currentSessionSlug: ", user.currentSessionSlug, user);
        console.log("GOT router.asPath: ", router.asPath);

        if (user.currentSessionSlug && !router.asPath.startsWith("/school/")) {
          router.push(`/school/music`);
        }

        posthog?.identify(user.userId, {
          email: user.email,
          firstName: user.firstName,
          lastName: user.lastName,
          username: user.username,
          signUpDate: user.signUpDate,
          miniBio: user.miniBio,
          membershipStatus: user.membershipStatus,
        });
      } else {
        setUser(null);
      }
      return result;
    },
    [setUser],
  );

  const signOut = useCallback(async () => {
    const result = await authSignOut();
    if (result.type === "success") {
      setUser(null);

      console.log("SIGNING OUT, router.push('/') ");
      await router.push("/");
    }
    return result;
  }, [setUser]);

  const signUp = useCallback(
    async (args: AuthSignUpArgs) => {
      const result = await authSignUp(args);
      if (result.type === "success") {
        setUser(result.value);
      } else {
        setUser(null);
      }
      return result;
    },
    [setUser],
  );

  const deleteUser = useCallback(async () => {
    const result = await authDeleteUser();
    if (result.type === "success") {
      setUser(null);
    }
    return result;
  }, [setUser]);

  // useMemo to not recreate object on each render
  const contextValue = useMemo(
    () => ({
      user,
      loadingCurrentUser,
      signIn,
      signUp,
      signOut,
      deleteUser,
    }),
    [user, loadingCurrentUser],
  );

  return <AuthContext.Provider value={contextValue}>{children}</AuthContext.Provider>;
};
