import classNames from "classnames";

import { CheckIcon } from "../Icons";
import * as styles from "./Button.css";

interface ButtonProps extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  className?: string;
  containerClassName?: string;
  gradientOverlayCustom?: string;
  label: string;
  variant: "primary" | "secondary" | "tertiary" | "quaternary";
  icon?: React.ReactNode;
  hasCheckIcon?: boolean;
  removeMargin?: boolean;
  hideGradient?: boolean;
}

export const Button = ({
  removeMargin,
  className,
  containerClassName,
  variant,
  icon,
  label,
  hasCheckIcon,
  gradientOverlayCustom,
  hideGradient,
  ...props
}: ButtonProps) => (
  <div className={classNames(styles.buttonContainer, containerClassName)}>
    {variant === "primary" ? (
      <>
        <div className={styles.relativePosition}>
          {!hideGradient && (
            <div className={classNames(styles.gradientUnderlay, gradientOverlayCustom)} />
          )}
          <button className={classNames(className, styles.assignmentButton)} {...props}>
            {hasCheckIcon && <CheckIcon />}
            {label}
          </button>
        </div>
        {icon && icon}
      </>
    ) : variant === "secondary" ? (
      <>
        <button className={classNames(className, label === "Optional" ? styles.optionalButton : styles.button)} {...props}>
          {hasCheckIcon && <CheckIcon />}
          {label}
        </button>
        {icon && icon}
      </>
    ) : variant === "tertiary" ? (
      <>
        <button className={classNames(className, styles.tertiaryButton)} {...props}>
          {label}
        </button>
      </>
    ) : (
      <>
        <div className={classNames(!removeMargin && styles.marginRight, styles.relativePosition)}>
          {!props.disabled || (!hideGradient && <div className={styles.gradientUnderlay} />)}
          <button
            className={classNames(
              styles.quaternaryButton,
              styles.tertiaryButton,
              styles.assignmentButton,
              className,
            )}
            {...props}
          >
            {label}
          </button>
        </div>
      </>
    )}
  </div>
);
