import React, { ComponentProps, forwardRef, Ref, useEffect, useState } from "react";

import classnames from "classnames";
import ReactPlayer from "react-player";

import { ActiveMediaPlayer } from "~/components/videoProductPage/components/ActiveMediaPlayer";

import * as styles from "./VideoPlayer.css";

type ActiveMediaPlayerProps = ComponentProps<typeof ActiveMediaPlayer>;

export const VideoPlayer = forwardRef((props: ActiveMediaPlayerProps, ref: Ref<ReactPlayer>) => {
  const [hasWindow, setHasWindow] = useState(false);
  const [isPlaying, setIsPlaying] = useState(props.shouldNotPlayOnLoad ? false : true);
  
  useEffect(() => {
    if (typeof window !== "undefined") {
      setHasWindow(true);
    }
  }, []);

  if (!hasWindow) {
    return <> </> 
  }

  const {
    light: videoThumbnailOrShowDefault,
    width = "100%",
    height = "100%",
    playIcon,
    playing,
  } = props;
  return (
    <div className={styles.container} style={{ height, width }}>
      <ActiveMediaPlayer
        {...props}
        ref={ref}
        light={videoThumbnailOrShowDefault}
        playing={playing !== undefined ? playing : isPlaying}
        playIcon={playIcon}
        controls={props.controls ?? true}
        width={width}
        height={height}
        className={classnames(props.className, styles.player)}
        onInterrupt={() => setIsPlaying(false)}
        onReady={() => setIsPlaying(props.shouldNotPlayOnLoad ? false : true)}
        onClickPreview={() => setIsPlaying(true)}
      />
    </div>
  );
});

VideoPlayer.displayName = "VideoPlayer";
