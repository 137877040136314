import { ApiStatus } from "~/api/fetchStudioApi";

import { fetchApi } from "./fetchStudioApi";

export interface OptionalPromptParameters {
  userId?: string;
  conversationId?: string;
  top_k?: number;
  top_p?: number;
  temperature?: number;
  customContextPrompt?: string;
  customExamples?: string[];
  shouldUseExamples?: boolean;
  shouldUseClassInfoSearch?: boolean;
  shouldUseClassTranscriptsSearch?: boolean;
}

// # {
// #     "counter": int,
// #     "content": {"resp"}{"onse"}{"s": "str}
// # }
// # {
// #     "sessionSlug": str,
// #     "currSectionIndex": int,
// #     "currQuestionIndex": int,
// #     "isAnswerSatisfactory": bool,
// #     "skipToQuestion": int,
// #     "questionOptions": [List[str]],
// #     "questionStepper": {
// #         "leadingCaption": str,
// #         "trailingCaption": str,
// #     },
// #     "progressBarData": [{
// #         "title": str,
// #         "percentage": float,
// #     }]
// # }

export const getPreviousQuestion = async (
  sessionSlug: string,
  currSectionIndex: number,
  currQuestionIndex: number,
) => {
  const objToSend: {
    sessionSlug: string;
    currQuestionIndex: number;
    currSectionIndex: number;
  } = {
    sessionSlug: "",
    currQuestionIndex: 0,
    currSectionIndex: 0,
  };

  console.log("DO WE HAVE CURR QUESTION INDEX? ", currQuestionIndex, currSectionIndex);

  if (sessionSlug) {
    objToSend.sessionSlug = sessionSlug;
  }
  if (currQuestionIndex !== undefined) {
    objToSend.currQuestionIndex = currQuestionIndex;
  }
  if (currSectionIndex !== undefined) {
    objToSend.currSectionIndex = currSectionIndex;
  }
  console.log("THE OBJECT TO PROCESS TO SEND IS ", objToSend);
  const response = await fetchApi({
    path: "/getPreviousQuestion",
    method: "POST",
    body: JSON.stringify(objToSend),
    isJsonPayload: true,
    authRequired: true,
  });

  console.log("response ", response);
  //if (response.status === ApiStatus.SUCCESS) {
  return response;
  //}
};

export const processResponseAndGetNextQuestion = async (
  answer?: string,
  values?: string[],
  sessionSlug?: string,
  currSectionIndex?: number,
  currQuestionIndex?: number,
  shouldIgnoreAnswer?: boolean,
  welcomeMessage?: boolean,
) => {
  const objToSend: {
    answer?: string;
    values?: string[];
    sessionSlug?: string;
    currQuestionIndex?: number;
    currSectionIndex?: number;
    shouldIgnoreAnswer?: boolean;
    welcomeMessage?: boolean;
  } = {};

  console.log("DO WE HAVE CURR QUESTION INDEX? ", currQuestionIndex, currSectionIndex);
  if (answer) {
    objToSend.answer = answer;
  }
  if (welcomeMessage) {
    objToSend.welcomeMessage = welcomeMessage;
  }
  if (values) {
    objToSend.values = values;
  }
  if (sessionSlug) {
    objToSend.sessionSlug = sessionSlug;
  }
  if (currQuestionIndex !== undefined) {
    objToSend.currQuestionIndex = currQuestionIndex;
  }
  if (currSectionIndex !== undefined) {
    objToSend.currSectionIndex = currSectionIndex;
  }
  if (shouldIgnoreAnswer !== undefined) {
    objToSend.shouldIgnoreAnswer = shouldIgnoreAnswer;
  }
  console.log("THE OBJECT TO PROCESS TO SEND IS ", objToSend);
  const response = await fetchApi({
    path: "/processResponseAndGetNextQuestion",
    method: "POST",
    body: JSON.stringify(objToSend),
    isJsonPayload: true,
    authRequired: true,
  });

  console.log("response ", response);
  //if (response.status === ApiStatus.SUCCESS) {
  return response;
  //}
};

export const generateCurriculumForUser = async () => {
  const response = await fetchApi({
    path: "/generateCurriculumForUser",
    method: "POST",
    isJsonPayload: true,
    authRequired: true,
  });

  console.log("response ", response);
  //if (response.status === ApiStatus.SUCCESS) {
  return response;
  //}
};

export const getCurriculumSummaryForUser = async (ownerUserId?: string, sessionSlug?: string) => {
  let path = "/getCurriculumSummaryForSessionAndUser";
  if (ownerUserId) {
    path += "?owner_user_id=" + ownerUserId;
  }

  if (sessionSlug) {
    if (ownerUserId) {
      path += "&session_slug=" + sessionSlug;
    } else {
      path += "?session_slug=" + sessionSlug;
    }
  }

  const response = await fetchApi({
    path: path,
    method: "GET",
    authRequired: true,
  });

  return response;
};

export const clearOnboardingForUser = async () => {
  const response = await fetchApi({
    path: "/clearOnboarding",
    method: "POST",
    isJsonPayload: true,
    authRequired: true,
  });

  console.log("response ", response);
  //if (response.status === ApiStatus.SUCCESS) {
  return response;
  //}
};

export const clearOnboardingSectionForUser = async (currSectionIndex: number) => {
  const objToSend = {
    currSectionIndex: currSectionIndex,
  };

  console.log("CLEARING onboarding sending ", objToSend);
  const response = await fetchApi({
    path: "/clearSectionOfOnboarding",
    method: "POST",
    isJsonPayload: true,
    authRequired: true,
    body: JSON.stringify(objToSend),
  });

  console.log("response ", response);
  //if (response.status === ApiStatus.SUCCESS) {
  return response;
  //}
};

export const getAiCoachResponse = async (
  prompt: string,
  optionalPromptParameters: OptionalPromptParameters,
) => {
  const promptObject = {
    userId: "userId",
  };

  // TODO: how to protect from random people calling this endpoint and spamming it?
  const response = await fetchApi({
    path: "/getAiCoachResponse",
    method: "POST",
    body: JSON.stringify({ prompt: prompt, ...optionalPromptParameters }),
    isJsonPayload: true,
    authRequired: false,
  });

  // console.log("response ", response);
  //if (response.status === ApiStatus.SUCCESS) {
  return response;
  //}
};

export const getAiClassSuggestion = async (
  classSectionId: string,
  contextInfo: string,
  additionalInstructions?: string,
  optionalPromptParameters?: OptionalPromptParameters,
) => {
  const promptObject = {
    userId: "userId",
  };

  // console.log(
  //   "sending this info: ",
  //   classSectionId,
  //   contextInfo,
  //   additionalInstructions,
  //   optionalPromptParameters,
  // );

  // TODO: how to protect from random people calling this endpoint and spamming it?
  const response = await fetchApi({
    path: "/getAiClassCopySuggestion",
    method: "POST",
    body: JSON.stringify({
      prompt: prompt,
      sectionId: classSectionId,
      contextInfo: contextInfo,
      additionalInstructions: additionalInstructions,
      ...optionalPromptParameters,
    }),
    isJsonPayload: true,
    authRequired: false,
  });

  // console.log("response ", response);
  //if (response.status === ApiStatus.SUCCESS) {
  return response;
  //}
};

export const getAiConversationsForUser = async () => {
  const response = await fetchApi({
    path: "/getAiConversationsForUser",
    method: "GET",
    isJsonPayload: true,
    authRequired: true,
  });

  // console.log("response ", response);
  if (response.status === ApiStatus.SUCCESS) {
    return response.conversations;
  } else {
    return [];
  }
};

export const getConversationMessages = async (conversationId: string) => {
  const response = await fetchApi({
    path: "/getConversationMessages?conversation_id=" + conversationId,
    method: "GET",
    isJsonPayload: true,
    authRequired: true,
  });

  // console.log("response ", response);
  if (response.status === ApiStatus.SUCCESS) {
    return response.messages;
  } else {
    return [];
  }
};

export const editConversationTitle = async (conversationId: string, newTitle: string) => {
  const requestBody = JSON.stringify({
    conversationId: conversationId,
    newTitle: newTitle,
  });

  // console.log("Sending this request body: ", requestBody);
  const response = await fetchApi({
    path: "/editConversationTitle",
    body: requestBody,
    method: "POST",
    isJsonPayload: true,
    authRequired: true,
  });

  // console.log("response ", response);
  if (response.status === ApiStatus.SUCCESS) {
    return response.status;
  } else {
    return response.status;
  }
};

export const deleteConversation = async (conversationId: string) => {
  const requestBody = JSON.stringify({
    conversationId: conversationId,
  });

  // console.log("DELETE Sending this request body: ", requestBody);
  const response = await fetchApi({
    path: "/deleteConversation",
    body: requestBody,
    method: "POST",
    isJsonPayload: true,
    authRequired: true,
  });

  // console.log("response ", response);
  if (response.status === ApiStatus.SUCCESS) {
    return response.status;
  } else {
    return response.status;
  }
};
