import { Auth } from "aws-amplify";

import * as Sentry from "@sentry/nextjs";

export enum ApiStatus {
  DEFAULT = "default",
  PENDING = "pending",
  ERROR = "error",
  SUCCESS = "success",
  FORBIDDEN = "forbidden",
  NOT_FOUND = "not found",
}

export interface ApiResult {
  status: ApiStatus;
  message: string;
}

export const API_ENDPOINT = process.env.NEXT_PUBLIC_ENV_API;
const CORS_HEADER = "MONTHLY-HEADER";
const USER_TIMEZONE_HEADER = "User-Browser-Timezone";

export const fetchApiPublic = async (args: {
  path: string;
  method: "GET" | "POST" | "PATCH" | "DELETE";
  body?: any;
  isJsonPayload?: boolean;
}) => await fetchApi({ ...args, authRequired: false });

export const fetchApiAuthenticated = async (args: {
  path: string;
  method: "GET" | "POST" | "PATCH" | "DELETE";
  body?: any;
  isJsonPayload?: boolean;
}) => await fetchApi({ ...args, authRequired: true });

export const fetchApi = async (args: {
  path: string;
  method: "GET" | "POST" | "PATCH" | "DELETE";
  body?: any;
  isJsonPayload?: boolean;
  authRequired?: boolean;
}) => {
  const { path, method, body, isJsonPayload = false, authRequired = false } = args;
  const response = await fetch(`${API_ENDPOINT}${path}`, {
    method,
    mode: "cors",
    headers: await generateDefaultHeaders({
      isJsonPayload,
      authRequired,
    }),
    body,
  });
  console.log("response", response);
  const result = await response.json();
  return result;
};

const generateDefaultHeaders = async (args: {
  authRequired?: boolean;
  isJsonPayload?: boolean;
}) => {
  const { authRequired = false, isJsonPayload = false } = args;
  const headers: HeadersInit = {
    accept: "application/json",
    "Access-Control-Request-Headers": CORS_HEADER,
    ...(isJsonPayload && { "Content-Type": "application/json" }),
  };
  if (authRequired) {
    const authorizationToken = await getJWTToken();
    if (authorizationToken && authorizationToken.length > 0) {
      headers["Authorization"] = "Bearer " + authorizationToken;
    } else {
      //throw new Error("No auth token");
    }
  }

  headers[USER_TIMEZONE_HEADER] = Intl.DateTimeFormat().resolvedOptions().timeZone;

  // console.log("headers", headers);

  return headers;
};

export const getJWTToken = async () => {
  try {
    const userSession = await Auth.currentSession();
    if (userSession) {
      return userSession.getAccessToken().getJwtToken();
    } else {
      //throw new Error("No user session");
    }
  } catch (error: any) {
    console.log("Error - no auth token found");
    //localStorage.clear();
    Sentry.captureException(error);
    // window.location.href = `${window.location.protocol}//${window.location.host}/login`;
    return;
  }
};
