import React from "react";

export const ModalPreviousIcon = () => (
  <svg width="17" height="16" viewBox="0 0 17 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect
      width="10.0787"
      height="2.16046"
      transform="matrix(0.664454 -0.747329 0.664454 0.747329 0.5 8.03223)"
      fill="#757575"
    />
    <rect
      width="10.0874"
      height="2.16046"
      transform="matrix(-0.664454 -0.747329 0.664454 -0.747329 7.20703 15.5908)"
      fill="#757575"
    />
    <rect
      width="14.0049"
      height="2.28825"
      transform="matrix(-1 0 -0.0037914 -0.999993 16.7266 9.16309)"
      fill="#757575"
    />
  </svg>
);
