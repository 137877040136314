import { useEffect, useRef, useState } from "react";

import classNames from "classnames";
import dynamic from "next/dynamic";
import Image from "next/image";
import { Tooltip } from "react-tooltip";

import { CloseIcon } from "studio-design-system";
import { v4 as uuidv4 } from "uuid";

import { SF_CURRICULUM_PROJECT_SUBMITTED } from "~/analytics/Posthog/events";
import { usePostHogAnalytics } from "~/analytics/Posthog/usePostHogAnalytics";
import { CloudinaryTag, uploadFileToCloudinary } from "~/api/creatorApi";
import { CurriculumItem, addPost, uploadMedia } from "~/api/sfCurriculumApi";
import { Post, pullPostPage } from "~/api/sfPeersApi";
import { OverlayModal } from "~/components/common/OverlayModal";
import {
  albumArtContainer,
  audioFileUpload,
  uploadAlbumArt,
  uploadFileText,
} from "~/components/school/ProfileOnboarding/ProfileOnboarding.css";
import { Button } from "~/components/school/common/Button/Button";
import { CircleProgressBar } from "~/components/school/common/CircleProgressBar";
import {
  AddFileIcon,
  RemoveFileIcon,
  VideoPlayButtonIcon,
  PlusIcon,
  UploadMediasIcon,
  ThunderIcon,
  UploadFileIcon,
  UploadAlbumIcon,
} from "~/components/school/common/Icons";
import { getMediaType } from "~/components/school/utils";
import { VideoPlayer } from "~/components/videoProductPage/common/VideoPlayer/VideoPlayer";
import { useCurrentSession } from "~/hooks/currentSessionProvider/useCurrentSession";

import * as styles from "./SubmitProjectModal.css";

const AudioPlayer = dynamic(
  () =>
    import(
      "~/components/videoProductPage/components/VideoSection/BeatPreview/AudioPlayer/AudioPlayer"
    ),
  { ssr: false },
);

export interface FilesState {
  loading: boolean;
  attachmentVariant: string;
  uploadedFiles: {
    id: string;
    url: string;
    type: string;
    name: string;
    progress?: number;
    preview?: string;
    metadata?: {
      waveform?: string;
      durationInSeconds?: string;
    } | null;
  }[];
}
export interface AttachmentModalState {
  isAttachmentModalOpen: boolean;
  attachmentType: string;
  attachment: string;
  attachmentName: string;
}

export interface SubmitProjectModalProps {
  post?: Post | null;
  variant: "update" | "project" | "edit" | "finalProject";
  isOpen: boolean;
  onClose: () => void;
  onConfirm?: (post: {
    title: string;
    description: string;
    media: Post["media"];
    youtubeLink: string;
  }) => void | (() => void) | (() => Promise<void>);
  mobileSubmit?: boolean;
  currentCurriculumItem?: CurriculumItem | undefined;
  setSavedModalState?:
    | React.Dispatch<{
        title: string;
        description: string;
        media: Post["media"];
        youtubeLink: string;
      }>
    | undefined
    | null;
}

const FinalProjectSongSection = ({
  filesState,
  setFilesState,
  uploadInputRef,
}: {
  filesState: FilesState;
  setFilesState: (filesState: FilesState) => void;
  uploadInputRef: React.RefObject<HTMLInputElement>;
}) => {
  const uploadedMedia = filesState.uploadedFiles?.find((file) => file.type === "audio");
  const uploadedMediaPreview = filesState.uploadedFiles?.find(
    (file) => file.type === "photo",
  )?.preview;

  return (
    <>
      <div className={styles.formSection}>
        <span className={styles.formSectionTitle}>
          Upload your song’s audio file (we accept MP3, M4As, or wav files)
        </span>
        {uploadedMedia ? (
          <div className={styles.uploadedFilePreview}>
            <div className={styles.videoPlayerContainer}>
              <div
                id="remove-file"
                className={styles.removeFileIcon}
                onClick={() => {
                  setFilesState({
                    ...filesState,
                    uploadedFiles: filesState.uploadedFiles?.filter(
                      (file) => file.type !== "audio",
                    ),
                  });
                }}
              >
                <RemoveFileIcon id="remove-file" />
                <Tooltip
                  place="top"
                  anchorSelect={`#remove-file`}
                  content="Remove file"
                  style={{ zIndex: 1, borderRadius: 6 }}
                />
              </div>
              <AudioPlayer
                darkTheme
                src={uploadedMedia.url}
                durationInSeconds={uploadedMedia.metadata?.durationInSeconds}
                projectInfo={{
                  waveformData: JSON.stringify(
                    uploadedMedia.metadata?.waveform &&
                      uploadedMedia.metadata.waveform.split(",").map(parseFloat),
                  ),
                }}
                customStyles={{
                  backgroundColor: "#252525",
                  border: "none",
                }}
              />
            </div>
          </div>
        ) : (
          <div
            className={audioFileUpload}
            onClick={() => {
              uploadInputRef.current?.setAttribute("accept", "audio/*");
              uploadInputRef.current?.click();
            }}
          >
            <UploadFileIcon />
            <span className={uploadFileText}>Upload file</span>
          </div>
        )}
      </div>
      <div className={styles.formSection}>
        <span className={styles.formSectionTitle}>Upload album art (optional)</span>
        {uploadedMediaPreview ? (
          <div className={uploadAlbumArt}>
            <Image
              src={uploadedMediaPreview}
              alt="Album art"
              layout="fill"
              objectFit="cover"
              style={{
                borderRadius: 8,
              }}
            />
            <div
              id="remove-file"
              className={styles.removeFileIcon}
              onClick={() => {
                setFilesState({
                  ...filesState,
                  uploadedFiles: filesState.uploadedFiles?.filter((file) => file.type !== "photo"),
                });
              }}
            >
              <RemoveFileIcon id="remove-file" />
              <Tooltip
                place="top"
                anchorSelect={`#remove-file`}
                content="Remove file"
                style={{ zIndex: 1, borderRadius: 6 }}
              />
            </div>
          </div>
        ) : (
          <div
            className={albumArtContainer}
            onClick={() => {
              uploadInputRef.current?.setAttribute("accept", "image/*");
              uploadInputRef.current?.click();
            }}
          >
            <UploadAlbumIcon />
          </div>
        )}
      </div>
    </>
  );
};

const FilledAttachmentSection = ({
  mobileSubmit,
  filesState,
  setFilesState,
  attachmentModalState,
  setAttachmentModalState,
  uploadInputRef,
}: {
  mobileSubmit?: boolean;
  filesState: FilesState;
  setFilesState: (filesState: FilesState) => void;
  attachmentModalState: AttachmentModalState;
  setAttachmentModalState: (attachmentModalState: AttachmentModalState) => void;
  uploadInputRef: React.RefObject<HTMLInputElement>;
}) => {
  const loadingFiles = filesState.uploadedFiles?.filter((file) => file.type === "loading");
  const nonLoadingFiles = filesState.uploadedFiles?.filter((file) => file.type !== "loading");
  const firstTwoLoadingFiles = loadingFiles?.slice(0, 2);
  const remainingLoadingFiles = loadingFiles?.slice(2);
  const allFiles = [...(nonLoadingFiles || []), ...(firstTwoLoadingFiles || [])];

  return (
    <>
      <span className={styles.formSectionTitle}>
        {mobileSubmit ? "Attach media to your project*" : "Attach audio, photos, or videos"}
      </span>
      <div className={styles.uploadedFilesPreviewContainer}>
        {allFiles.map((filePreview, index) => (
          <div key={index}>
            <div className={styles.uploadedFilePreview}>
              {filePreview.type !== "loading" && (
                <div
                  id="remove-file"
                  className={styles.removeFileIcon}
                  onClick={() => {
                    const newFiles = Array.from(filesState.uploadedFiles);
                    newFiles.splice(index, 1);
                    const newFilesPreview = Array.from(filesState.uploadedFiles);
                    newFilesPreview.splice(index, 1);
                    setFilesState({
                      ...filesState,
                      uploadedFiles: newFilesPreview,
                    });
                  }}
                >
                  <RemoveFileIcon id="remove-file" />
                  {!mobileSubmit && (
                    <Tooltip
                      place="top"
                      anchorSelect={`#remove-file`}
                      content="Remove file"
                      style={{ zIndex: 1, borderRadius: 6 }}
                    />
                  )}
                </div>
              )}
              {filePreview.type === "photo" ? (
                <Image
                  src={filePreview.preview || filePreview.url}
                  layout="fill"
                  objectFit="cover"
                  alt=""
                  style={{ overflow: "hidden", borderRadius: "12px" }}
                  onClick={() => {
                    setAttachmentModalState({
                      isAttachmentModalOpen: true,
                      attachmentType: "photo",
                      attachmentName: filePreview.name,
                      attachment: filePreview.preview || filePreview.url,
                    });
                  }}
                />
              ) : filePreview.type === "audio" ? (
                <div className={styles.videoPlayerContainer}>
                  <AudioPlayer
                    darkTheme
                    src={filePreview.url}
                    durationInSeconds={filePreview.metadata?.durationInSeconds}
                    projectInfo={{
                      waveformData: JSON.stringify(
                        filePreview.metadata?.waveform &&
                          filePreview.metadata.waveform.split(",").map(parseFloat),
                      ),
                    }}
                    customStyles={{
                      backgroundColor: "#252525",
                      border: "none",
                    }}
                  />
                </div>
              ) : filePreview.type === "loading" ? (
                <div className={styles.loadingFilePreview}>
                  <CircleProgressBar
                    percentage={filePreview.progress}
                    label={`${filePreview.progress}%`}
                  />
                </div>
              ) : (
                <div
                  className={styles.videoPlayerContainer}
                  onClick={() => {
                    setAttachmentModalState({
                      isAttachmentModalOpen: true,
                      attachmentType: "video",
                      attachmentName: filePreview.name,
                      attachment: filePreview.preview || filePreview.url,
                    });
                  }}
                >
                  <VideoPlayButtonIcon />
                  <VideoPlayer
                    light=""
                    url={filePreview?.preview || filePreview.url}
                    className={styles.videoPlayer}
                    height={mobileSubmit ? "48px" : "72px"}
                    shouldNotPlayOnLoad
                    controls={false}
                  />
                </div>
              )}
            </div>
            {attachmentModalState.isAttachmentModalOpen && (
              <OverlayModal
                contentClass={styles.attachmentModal}
                isOpen={attachmentModalState.isAttachmentModalOpen}
                onClose={() =>
                  setAttachmentModalState({ ...attachmentModalState, isAttachmentModalOpen: false })
                }
              >
                <div>
                  <div className={styles.modalHeader}>
                    <h2 className={styles.modalTitle}>{attachmentModalState.attachmentName}</h2>
                  </div>
                  <div className={styles.attachmentModalContent}>
                    {attachmentModalState.attachmentType === "photo" ? (
                      <Image
                        src={attachmentModalState.attachment}
                        layout="fixed"
                        width={350}
                        height={350}
                        style={{
                          objectFit: "cover",
                          borderRadius: "12px",
                        }}
                        alt={attachmentModalState.attachment}
                      />
                    ) : (
                      <VideoPlayer
                        light=""
                        url={attachmentModalState.attachment}
                        className={styles.videoPlayer}
                        height={"350px"}
                        shouldNotPlayOnLoad
                        controls
                      />
                    )}
                  </div>
                </div>
              </OverlayModal>
            )}
          </div>
        ))}
        {remainingLoadingFiles?.length > 0 && (
          <div className={styles.loadingFilesPreview}>
            <CircleProgressBar
              percentage={Math.floor(
                remainingLoadingFiles.reduce(
                  (acc, file) => (file?.progress ? acc + file?.progress : acc),
                  0,
                ) / remainingLoadingFiles.length,
              )}
              label={`${loadingFiles.length - remainingLoadingFiles.length}/${loadingFiles.length}`}
            />
          </div>
        )}
        <div
          id="add-file"
          className={styles.addFileIcon}
          onClick={() => uploadInputRef.current?.click()}
        >
          {mobileSubmit ? <PlusIcon /> : <AddFileIcon />}
          {!mobileSubmit && (
            <Tooltip
              content="Add file"
              place="top"
              anchorSelect="#add-file"
              style={{ zIndex: 1, borderRadius: 6, opacity: 1 }}
            />
          )}
        </div>
      </div>
    </>
  );
};

export const SubmitProjectModal = ({
  post,
  variant,
  isOpen,
  onClose,
  onConfirm,
  mobileSubmit,
  currentCurriculumItem,
  setSavedModalState,
}: SubmitProjectModalProps) => {
  const uploadInputRef = useRef<HTMLInputElement>(null);
  const [preventUpdate, setPreventUpdate] = useState(false);
  const {
    initialConfirmedUser,
    confirmedUser,
    setCurrentCurriculumItemCompletedOn,
    currentSession,
    setPeerFeedPosts,
  } = useCurrentSession();
  const [attachmentModalState, setAttachmentModalState] = useState({} as AttachmentModalState);
  const [formState, setFormState] = useState({
    title: "",
    youtubeLink: "",
    descriptionText: "",
    descriptionMediaLinks: [],
  });

  const [filesState, setFilesState] = useState({
    loading: false,
    attachmentVariant: "files",
    uploadedFiles: [] as FilesState["uploadedFiles"],
  });

  useEffect(() => {
    if (!setSavedModalState) return;

    return () => {
      setPreventUpdate(true);
      setSavedModalState({
        title: formState.title,
        description: formState.descriptionText,
        media: filesState.uploadedFiles,
        youtubeLink: formState.youtubeLink,
      });
    };
  }, [formState, filesState, setSavedModalState]);

  useEffect(() => {
    const sidebar = document.querySelector("aside") as HTMLElement;

    if (isOpen) {
      sidebar.style.opacity = "0.225";
    }

    return () => {
      sidebar.style.opacity = "1";
    };
  }, [isOpen]);

  useEffect(() => {
    if (!post || preventUpdate) return;

    setFormState({
      ...formState,
      title: post.title,
      descriptionText: post.description,
      youtubeLink: post.media?.find((item) => item.type === "youtube")?.url || "",
    });

    setFilesState({
      ...filesState,
      attachmentVariant: post.media?.some((item) => item.type === "youtube") ? "youtube" : "files",
      uploadedFiles: post.media?.map((item) => {
        return { id: item.id, url: item.url, type: item.type, name: "", metadata: item?.metadata };
      }),
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [post, preventUpdate]);

  const modalContent = {
    finalProject: {
      title: "Submit your final project",
      subtitle: "",
      pillText: currentCurriculumItem?.title.split(":")[0],
      titleLabel: "Give your final song a title" + (mobileSubmit ? "*" : ""),
      titlePlaceholder: "Song Title",
    },
    project: {
      title: "Submit your project",
      subtitle: "",
      pillText: currentCurriculumItem?.title.split(":")[0],
      titleLabel: "Give your project a title" + (mobileSubmit ? "*" : ""),
      titlePlaceholder: currentCurriculumItem?.title
        ? currentCurriculumItem?.title
        : mobileSubmit
        ? `e.g. Project 1A: Getting started on my song`
        : `Project Title (i.e. "Project 1A: Getting started on my song")`,
      descriptionLabel: "Give your project a description",
      descriptionPlaceholder: `How did that session go? Did you make any progress? Hit any roadblocks? Have any questions? Share your experience with your peer group. (optional)`,
    },
    update: {
      title: "Share an update",
      subtitle:
        "Not ready to submit your next project, but have progress to share? Post an update.",
      pillText: "Update",
      titleLabel: "Give your update a title",
      titlePlaceholder: `Title (i.e. "Made some progress on my song")`,
      descriptionLabel: "Give your update a description",
      descriptionPlaceholder: `Share what progress you've made. (optional)`,
    },
    edit: {
      title: "Edit your project",
      pillText: post?.curriculumItemTeaser?.title
        ? post?.curriculumItemTeaser?.title.split(":")[0]
        : post?.title.split(":")[0],
      titleLabel: "Edit your project title",
      titlePlaceholder: `Project Title (i.e. "Project 1A: Getting started on my song")`,
      descriptionLabel: "Edit your project description",
      descriptionPlaceholder: `How did that session go? Did you make any progress? Hit any roadblocks? Have any questions? Share your experience with your peer group. (optional)`,
    },
  };

  const isSubmitButtonDisabled =
    filesState.attachmentVariant === "youtube"
      ? !formState.title || !formState.youtubeLink
      : !formState.title || filesState.uploadedFiles?.length === 0;

  const onUploadFiles = async (e: React.ChangeEvent<HTMLInputElement>) => {
    const uploadedFiles = e.target.files as FileList;

    if (uploadedFiles) {
      const uploadFileToCloudinaryAndUpdateState = async (index: number, commonId: string) => {
        return uploadFileToCloudinary({
          file: uploadedFiles[index],
          userId: initialConfirmedUser?.userId as string,
          tag: CloudinaryTag.ClassPreviewVideo,
          setProgress: (number: number) => {
            if (number < 100) {
              setFilesState((prevState) => {
                const updatedFiles = [...prevState.uploadedFiles];
                const index = updatedFiles.findIndex((file) => file.id === commonId);

                updatedFiles[index] = {
                  ...updatedFiles[index],
                  progress: Math.floor(number),
                  type: "loading",
                };

                return {
                  ...prevState,
                  loading: true,
                  uploadedFiles: updatedFiles,
                };
              });
            }
          },
        }).then(async (res) => {
          setFilesState((prevState) => {
            const updatedFiles = [...prevState.uploadedFiles];
            const index = updatedFiles.findIndex((file) => file.id === commonId);
            updatedFiles[index] = {
              ...updatedFiles[index],
              type: getMediaType(res.url),
              url: res.url,
            };

            return {
              ...prevState,
              loading: false,
              uploadedFiles: updatedFiles,
            };
          });
          return {
            id: commonId,
            type: getMediaType(res.url),
            url: res.url,
          };
        });
      };

      const updatedFilesData: FilesState["uploadedFiles"][0][] = [];

      for (let i = 0; i < uploadedFiles.length; i++) {
        const imageType = uploadedFiles[i].type.split("/")[0] === "image";

        const fileData = {
          id: uuidv4(),
          name: uploadedFiles[i].name,
          progress: imageType ? 100 : 0,
          type: uploadedFiles[i].type.split("/")[0] === "image" ? "photo" : "loading",
          url: "",
          preview: imageType ? URL.createObjectURL(uploadedFiles[i]) : "",
        };
        updatedFilesData.push(fileData);
      }

      setFilesState((prevState) => {
        const updatedFiles = [...prevState.uploadedFiles, ...updatedFilesData];
        return {
          ...prevState,
          uploadedFiles: updatedFiles,
        };
      });

      const uploadMediaResults = await Promise.all(
        updatedFilesData.map((fileData, i) => uploadFileToCloudinaryAndUpdateState(i, fileData.id)),
      );

      await uploadMedia(uploadMediaResults).then((res) => {
        setFilesState((prevState) => {
          const updatedFiles = [...prevState.uploadedFiles];

          if (res && res.length > 0) {
            for (let i = 0; i < res.length; i++) {
              const index = updatedFiles.findIndex((file) => file.id === res[i].id);
              updatedFiles[index] = {
                ...updatedFiles[index],
                metadata: res[i].metadata,
              };
            }
          }
          return {
            ...prevState,
            uploadedFiles: updatedFiles,
          };
        });
      });
    }
  };

  const EmptyAttachmentSection = () => (
    <>
      <span className={styles.formSectionTitle}>Attach audio, photos, or videos</span>
      <div
        onClick={(e) => {
          if (!(e.target as HTMLElement).innerText?.includes("YouTube")) {
            uploadInputRef.current?.click();
          }
        }}
        className={styles.dropzone}
        onDragOver={(e) => {
          e.preventDefault();
        }}
        onDragEnter={(e) => {
          e.preventDefault();
        }}
        onDrop={(e) => {
          if (uploadInputRef.current) {
            const uploadedFiles = e.dataTransfer.files;
            onUploadFiles({
              target: { files: uploadedFiles },
            } as React.ChangeEvent<HTMLInputElement>);
          }
          e.preventDefault();
        }}
      >
        <label className={styles.dropzoneLabel}>
          <UploadMediasIcon />
          <div className={styles.dropzoneContent}>
            <h3 className={styles.dropzoneTitle}>Drag and drop or click to upload</h3>
            <p className={styles.dropzoneSubtitle}>
              We accept MP3, M4As, or wav files. You can also{" "}
              <span
                className={styles.underline}
                onClick={() => setFilesState({ ...filesState, attachmentVariant: "youtube" })}
              >
                share a YouTube link
              </span>
              .
            </p>
          </div>
        </label>
      </div>
    </>
  );

  const { capturePostHogEvent } = usePostHogAnalytics();
  const handleSubmit = async (e: React.FormEvent<HTMLButtonElement>) => {
    e.preventDefault();

    const handleAddPost = async (mediaIds: string[]) => {
      const postId = variant === "edit" ? post?.id : "";
      if (!currentSession) return;
      await addPost({
        current_session_slug: currentSession.sessionSlug,
        title: formState.title,
        description: formState.descriptionText,
        media_ids: mediaIds,
        ...(postId && { post_id: postId }),
        ...(currentCurriculumItem && { curriculum_item_id: currentCurriculumItem?.id }),
      }).then((res) => {
        if (res.status === "success") {
          onConfirm &&
            onConfirm({
              title: formState.title,
              description: formState.descriptionText,
              media: filesState.uploadedFiles as Post["media"],
              youtubeLink: formState.youtubeLink,
            });
          setFilesState({
            loading: false,
            attachmentVariant: "files",
            uploadedFiles: [] as FilesState["uploadedFiles"],
          });
          setFormState({
            title: "",
            youtubeLink: "",
            descriptionText: "",
            descriptionMediaLinks: [],
          });

          capturePostHogEvent(SF_CURRICULUM_PROJECT_SUBMITTED, {
            curriculumItemId: currentCurriculumItem?.id,
            projectTitle: formState.title,
          });

          // refresh peer feed
          pullPostPage(currentSession?.sessionSlug).then((response) => {
            setPeerFeedPosts(response);
          });
        }
        // variant === "project" && refetchCurriculum();
        variant === "project" && setCurrentCurriculumItemCompletedOn(true); // mark as complete
        onClose();
      });
    };

    if (filesState.attachmentVariant === "youtube") {
      const youtubeId = uuidv4();
      setFilesState({
        ...filesState,
        loading: true,
      });
      await uploadMedia([
        {
          id: youtubeId,
          type: "youtube",
          url: formState.youtubeLink,
        },
      ]).then(() => {
        handleAddPost([youtubeId]);
        setFilesState({
          ...filesState,
          loading: false,
        });
      });
    }

    if (filesState.attachmentVariant === "files" && filesState.uploadedFiles.length > 0) {
      handleAddPost(filesState.uploadedFiles.map((file) => file.id));
    }
  };

  if (mobileSubmit) {
    return (
      <form className={styles.mobileForm}>
        <input
          type="file"
          id="uploadInput"
          hidden
          accept="audio/*,video/*,image/*"
          ref={uploadInputRef}
          onChange={onUploadFiles}
        />
        <div className={styles.formSection}>
          <span className={styles.formSectionTitle}>{modalContent[variant].titleLabel}</span>
          <input
            required
            type="text"
            value={formState.title}
            placeholder={modalContent[variant].titlePlaceholder}
            onChange={(e) => setFormState({ ...formState, title: e.target.value })}
            className={styles.formInput}
          />
        </div>
        {variant !== "finalProject" ? (
          <>
            <div className={styles.formSection}>
              <span className={styles.formSectionTitle}>
                {modalContent[variant].descriptionLabel}
              </span>
              <textarea
                rows={3}
                className={styles.formInput}
                value={formState.descriptionText}
                placeholder={modalContent[variant].descriptionPlaceholder}
                onChange={(e) => setFormState({ ...formState, descriptionText: e.target.value })}
              />
            </div>
            <div className={styles.formSection}>
              <FilledAttachmentSection
                mobileSubmit
                filesState={filesState}
                setFilesState={setFilesState}
                attachmentModalState={attachmentModalState}
                setAttachmentModalState={setAttachmentModalState}
                uploadInputRef={uploadInputRef}
              />
            </div>
          </>
        ) : (
          <FinalProjectSongSection
            uploadInputRef={uploadInputRef}
            filesState={filesState}
            setFilesState={setFilesState}
          />
        )}
        <div className={classNames(styles.buttonsContainer, styles.formSection)}>
          <Button
            variant="quaternary"
            label="Submit"
            disabled={
              isSubmitButtonDisabled ||
              filesState.loading ||
              initialConfirmedUser?.userId !== confirmedUser?.userId
            }
            hideGradient={isSubmitButtonDisabled || filesState.loading}
            onClick={handleSubmit}
          />
        </div>
      </form>
    );
  }

  return (
    <OverlayModal
      overlayClass={styles.modalOverlay}
      contentClass={styles.modalContent}
      isOpen={isOpen}
      onClose={onClose}
      preserveOnOutsideClick
    >
      <div>
        <input
          type="file"
          id="uploadInput"
          hidden
          accept="audio/*,video/*,image/*"
          ref={uploadInputRef}
          onChange={onUploadFiles}
        />
        <div className={styles.modalHeader}>
          <div className={styles.modalTitleBox}>
            <h2 className={styles.modalTitle}>{modalContent[variant].title}</h2>
            {variant === "update" && (
              <p className={styles.modalSubtitle}>{modalContent[variant].subtitle}</p>
            )}
          </div>
          <div className={styles.subtitlePill}>
            <ThunderIcon />
            <span className={styles.pillText}>{modalContent[variant].pillText}</span>
          </div>
          <button
            onClick={onClose}
            aria-label="Close pop-up"
            className={styles.absoluteCloseIconButton}
          >
            <CloseIcon width={17} height={18} />
          </button>
        </div>
        <form>
          <div className={styles.formSection}>
            <span className={styles.formSectionTitle}>{modalContent[variant].titleLabel}</span>
            <input
              required
              type="text"
              value={formState.title}
              placeholder={modalContent[variant].titlePlaceholder}
              onChange={(e) => setFormState({ ...formState, title: e.target.value })}
              className={styles.formInput}
            />
          </div>
          {variant !== "finalProject" ? (
            <>
              <div className={styles.formSection}>
                <span className={styles.formSectionTitle}>
                  {modalContent[variant].descriptionLabel}
                </span>
                <textarea
                  rows={5}
                  className={styles.formInput}
                  value={formState.descriptionText}
                  placeholder={modalContent[variant].descriptionPlaceholder}
                  onChange={(e) => setFormState({ ...formState, descriptionText: e.target.value })}
                />
              </div>
              <div className={styles.formSection}>
                {filesState.attachmentVariant === "files" && !filesState.uploadedFiles?.length ? (
                  <EmptyAttachmentSection />
                ) : filesState.attachmentVariant === "youtube" ? (
                  <>
                    <span className={styles.formSectionTitle}>Paste a YouTube Link</span>
                    <input
                      required
                      type="text"
                      placeholder="YouTube URL"
                      value={formState.youtubeLink}
                      onChange={(e) => setFormState({ ...formState, youtubeLink: e.target.value })}
                      className={styles.formInput}
                    />
                  </>
                ) : (
                  <FilledAttachmentSection
                    filesState={filesState}
                    setFilesState={setFilesState}
                    attachmentModalState={attachmentModalState}
                    setAttachmentModalState={setAttachmentModalState}
                    uploadInputRef={uploadInputRef}
                  />
                )}
              </div>
            </>
          ) : (
            <FinalProjectSongSection
              uploadInputRef={uploadInputRef}
              filesState={filesState}
              setFilesState={setFilesState}
            />
          )}
          <div
            className={classNames(
              variant === "edit" && styles.doubleButtonsContainer,
              styles.buttonsContainer,
              styles.formSection,
            )}
          >
            {variant === "edit" && (
              <Button
                variant="tertiary"
                label="Cancel"
                onClick={onClose}
                className={styles.cancelButton}
              />
            )}
            <Button
              variant="quaternary"
              label="Submit"
              disabled={isSubmitButtonDisabled || filesState.loading}
              hideGradient={isSubmitButtonDisabled || filesState.loading}
              onClick={handleSubmit}
            />
          </div>
        </form>
      </div>
    </OverlayModal>
  );
};
