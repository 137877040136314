import { useEffect, useState } from "react";

import { Post } from "~/api/sfPeersApi";
import { ImageWithBlur } from "~/components/common/ImageWithBlur/ImageWithBlur";
import { OverlayModal } from "~/components/common/OverlayModal";
import { VideoPlayer } from "~/components/videoProductPage/common/VideoPlayer/VideoPlayer";

import { getVideoAndPhotoFiles } from "../../utils";
import * as styles from "./GalleryMediaModal.css";

export const GalleryMediaModal = ({
  post,
  media,
  onClose,
  isOpen,
}: {
  post?: Post | null;
  media?: Post["media"][0] | null;
  onClose: () => void;
  isOpen: boolean;
}) => {
  const [currentMedia, setCurrentMedia] = useState(media);
  const [contentDimensions, setContentDimensions] = useState({ width: "0px", height: "0px" });

  useEffect(() => {
    const mainElement = document.getElementsByTagName("main")[0];
    const handleResize = () => {
      if (window.innerWidth < 800) {
        setContentDimensions({
          width: `calc(${mainElement.clientWidth}px - 150px)`,
          height: `calc(${mainElement.clientHeight}px - 150px)`,
        });
        return;
      }

      setContentDimensions({
        width: `calc(${mainElement.clientWidth}px - 105px)`,
        height: `calc(${mainElement.clientHeight}px - 105px)`,
      });
    };

    if (mainElement) {
      handleResize();
    }

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  useEffect(() => {
    setCurrentMedia(media);
  }, [media]);

  if (!currentMedia || !post) return null;

  const isVideo = (media: { type: string; url: string }) => media.type === "video";
  const sortedMedia =
    getVideoAndPhotoFiles(post.media).sortedMedia?.sort((a, b) => {
      if (isVideo(a) && !isVideo(b)) return -1;
      if (!isVideo(a) && isVideo(b)) return 1;
      return 0;
    }) || [];

  const currentMediaIndex = sortedMedia.findIndex((m) => m.id === currentMedia?.id);

  const hasPrevious = currentMediaIndex > 0;
  const hasNext = currentMediaIndex < sortedMedia.length - 1;

  const handlePrevious = () => {
    const previousMedia = sortedMedia[currentMediaIndex - 1];
    setCurrentMedia(previousMedia);
  };

  const handleNext = () => {
    const nextMedia = sortedMedia[currentMediaIndex + 1];
    setCurrentMedia(nextMedia);
  };

  const handleClose = () => {
    setCurrentMedia(null);
    onClose();
  }

  return (
    <div>
      <OverlayModal
        onGalleryNext={hasNext ? handleNext : undefined}
        onGalleryPrevious={hasPrevious ? handlePrevious : undefined}
        contentClass={styles.contentContainer}
        isOpen={isOpen && currentMedia !== null}
        onClose={handleClose}
      >
        <div onClick={handleClose}>
          {isVideo(currentMedia) ? (
            <VideoPlayer
              url={currentMedia.url}
              controls
              height={contentDimensions.height}
              width={contentDimensions.width}
            />
          ) : (
            <div
              style={{
                position: "relative",
                width: contentDimensions.width,
                height: contentDimensions.height,
                borderRadius: "8px",
                overflow: "hidden",
              }}
            >
              <ImageWithBlur src={currentMedia.url} layout="fill" />
            </div>
          )}
        </div>
      </OverlayModal>
    </div>
  );
};
