import classNames from "classnames";



import * as styles from "./ProgressBar.css";


export const ProgressBar = ({
  progress,
  className,
  containerClassName,
  showPercentage,
}: {
  progress: number | string;
  className?: string;
  containerClassName?: string;
  showPercentage?: boolean;
}): React.ReactElement => (
  <div className={classNames(styles.progressBarContainer, containerClassName)}>
    <div className={classNames(styles.progressContainer, className)}>
      {progress !== "0" && progress !== 0 && (
        <div
          style={{
            width: progress === "0" ? 0 : `calc(${progress}% + 10px)`,
          }}
          className={styles.progressBarGradient}
        />
      )}
      <div
        style={{
          width: `${progress}%`,
        }}
        className={styles.progressBar}
      />
    </div>
    {showPercentage && <span className={styles.progressPercentage}>{`${progress}%`}</span>}
  </div>
);