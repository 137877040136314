import React, { useEffect, useState } from "react";

import classNames from "classnames";
import Link from "next/link";
import posthog from "posthog-js";

import { assignInlineVars } from "@vanilla-extract/dynamic";
import { HeroData, YellowBannerObject } from "data/homepage";
import { CloseIcon } from "studio-design-system";

import { HeroContent } from "~/components/homepage/HeroSection/HeroContent";
import { LandingPageSources, useLandingPage } from "~/components/homepage/useLandingPage";

import * as styles from "./HeroSection.css";

export enum CampaignValues {
  Alumni = "student",
  Account = "account",
}

export const HeroSection = ({
  heroData,
  browseCategories,
}: {
  heroData: HeroData;
  browseCategories?: string[];
}): React.ReactElement => {
  const { ctaText, onCtaClick } = useLandingPage(LandingPageSources.Home);
  const [closeYellowBadge, setCloseYellowBadge] = useState(false);

  const [yellowBannerBoldTitle, setYellowBannerBoldTitle] = useState(heroData.titleB);
  const [yellowBannerDescription, setYellowBannerDescription] = useState(heroData.descriptionB);
  const [yellowBannerDescriptionAfterCountdown, setYellowBannerDescriptionAfterCountdown] =
    useState(heroData.descriptionAfterCountdown);
  const [yellowBannerCountdownEndDate, setYellowBannerCountdownEndDate] = useState(
    heroData.countdownEndDate,
  );
  const [yellowBannerLinkUrl, setYellowBannerLinkUrl] = useState(heroData.yellowBannerLinkUrl);
  const [yellowBannerTheme, setYellowBannerTheme] = useState("none");
  const [shouldYellowBannerShow, setShouldYellowBannerShow] = useState(false);
  const currentTime = new Date().toISOString();

  useEffect(() => {
    posthog.onFeatureFlags(function () {
      // feature flags should be available at this point
      if (posthog.isFeatureEnabled("homepage-yellow-banner-text")) {
        // do something
        const yellowBannerInfo = posthog.getFeatureFlagPayload(
          "homepage-yellow-banner-text",
        ) as unknown as YellowBannerObject;
        console.log("yellow banner info is ", yellowBannerInfo);
        if (yellowBannerInfo) {
          setShouldYellowBannerShow(true);
          setYellowBannerBoldTitle(yellowBannerInfo.titleBoldText);
          setYellowBannerDescription(yellowBannerInfo.descriptionText);
          setYellowBannerDescriptionAfterCountdown(yellowBannerInfo.descriptionTextAfterCountdown);
          setYellowBannerCountdownEndDate(yellowBannerInfo.countdownEndDate);
          setYellowBannerLinkUrl(yellowBannerInfo.url);
          setYellowBannerTheme(yellowBannerInfo.specialTheme);

          console.log("setting banner theme to ", yellowBannerInfo.specialTheme);
          console.log("yellow banner theme ", yellowBannerTheme);
        }
      }
    });

    console.log("CALLED USEEFFECT");
  }, []);

  return (
    <div className={styles.outerContainer}>
      {!closeYellowBadge && shouldYellowBannerShow ? (
        <Link href={yellowBannerLinkUrl} passHref>
          <a className={styles.yellowMobileContainerLink}>
            <div
              className={classNames(
                styles.yellowMobileContainer,
                yellowBannerTheme === "holiday" && styles.holidayTheme,
                yellowBannerTheme === "sfschool" && styles.sfSchoolTheme,
                yellowBannerTheme == "spring" && styles.springTheme,
              )}
            >
              <div
                className={styles.yellowClose}
                onClick={(e) => {
                  e.preventDefault();
                  e.stopPropagation();
                  setCloseYellowBadge(true);
                }}
              >
                <CloseIcon height={10} fill={yellowBannerTheme === "sfschool" ? "#ffff" : "#000"} />
              </div>
              <span className={styles.yellowTitle}>{yellowBannerBoldTitle}</span>
              <span className={styles.yellowDesc}>
                {currentTime >= yellowBannerCountdownEndDate
                  ? yellowBannerDescriptionAfterCountdown
                  : yellowBannerDescription}
              </span>
            </div>
          </a>
        </Link>
      ) : null}
      <div className={styles.container}>
        <div className={styles.innerContainer}>
          <div
            className={styles.mobileBackground}
            style={assignInlineVars({
              [styles.backgroundMobileImageUrl]: `url(${heroData.mobileBackgroundUrl})`,
            })}
          ></div>
          <div className={styles.info}>
            <HeroContent
              title={heroData.title}
              description={heroData.description}
              onCtaClick={onCtaClick}
              ctaText={ctaText}
              pricingText={heroData.pricingText}
              browseCategories={browseCategories}
            />
          </div>
        </div>
        <div
          className={styles.heroBackground}
          style={assignInlineVars({
            [styles.backgroundImageUrl]: `url(${heroData.backgroundUrl})`,
          })}
        />
      </div>

      {shouldYellowBannerShow && (
        <div
          className={classNames(
            styles.yellowContainer,
            yellowBannerTheme === "holiday" && styles.holidayTheme,
            yellowBannerTheme === "sfschool" && styles.sfSchoolTheme,
            yellowBannerTheme == "spring" && styles.springTheme
          )}
        >
          <Link href={yellowBannerLinkUrl} passHref>
            <a className={styles.yellowLink}>
              <span className={styles.yellowTitle}>{yellowBannerBoldTitle}</span> —{" "}
              <span className={styles.yellowDesc}>
                {currentTime >= yellowBannerCountdownEndDate
                  ? yellowBannerDescriptionAfterCountdown
                  : yellowBannerDescription}
              </span>
            </a>
          </Link>
        </div>
      )}
    </div>
  );
};
