import { Cloudinary, CloudinaryImage } from "@cloudinary/url-gen";
import { crop as cropTransform, scale as scaleTransform } from "@cloudinary/url-gen/actions/resize";

// The image url can have 2 optional sections after image/upload/:
// performance params w_1600,f_auto,q_auto:best and version v1601....
// This regex will grab everything after the two optional sections

const cloudinaryDomain = "monthly-admin-images";
const URL_REGEX = new RegExp(`/(?:(?:.+)v([0-9]*)\\/)*${cloudinaryDomain}/(.+)`);
const RELATIVE_REGEX = new RegExp(`^/*${cloudinaryDomain}/.+$`);

export const CLOUDINARY_CLOUD_NAME = "monthly"; //'symbolimages'
export const CLOUDINARY_UPLOAD_PRESET = "discard-filename-preset"; //'s5yvzj6l'

// 1024 * 1024 bytes per megabyte
const BYTES_PER_MEGABYTE = 1048576;
export const IMAGE_MAX_FILE_SIZE_MB = 50;
export const IMAGE_MAX_FILE_SIZE_BYTES = BYTES_PER_MEGABYTE * IMAGE_MAX_FILE_SIZE_MB;

export const cloudinary = new Cloudinary({
  cloud: {
    cloudName: "monthly",
  },
});

export const makeCloudinaryImage = (url: string): CloudinaryImage => {
  const encodedUrl = url.replace("%20", " ");
  const match = URL_REGEX.exec(encodedUrl);
  // Match 0 = Whole url
  // Match 1 = version
  // Match 2 = url after cloudinaryDomain
  if (!match) {
    const relativeMatch = RELATIVE_REGEX.exec(encodedUrl);
    // relative path, e.g. monthly-admin-images/david-blaine-magic/instructor-card-david.jpg
    // this is the best case, since it avoids the need to deconstruct and reconstruct the url
    if (!relativeMatch) {
      console.warn(`Improper cloudinary URL: ${url}`);
    }

    return cloudinary.image(relativeMatch?.[0]);
  }
  const image = cloudinary.image(`${cloudinaryDomain}/${match[2]}`);
  // If there is a vsrsion from the first capture croup, add it as the version number
  if (match[1]) {
    image.setVersion(match[1]);
  }
  return image;
};

// \/upload\/ matches /upload/ in the URL
// (.+) will collect anything between /upload/ and /v...
// \/v\d+\/ matches /v then any number of digits
// Example: https://res.cloudinary.com/monthly/image/upload/q_auto:best,f_auto/v123456/whatever.jpg
// In the above URL we detect the params (q_auto:best,f_auto)
const CLOUDINARY_PARAMETER_REGEX = new RegExp(/\/upload\/(.+)\/v\d+\//);

/**
 * Gets a Cloudinary URL without any parameters so we can inject parameters
 *
 * @param {string} url A Cloudinary URL with or without parameters
 * @returns {array} A Cloudinary URL separated by upload/ so we can inject parameters
 */
export function getCloudinaryUrlWithoutParameters(url: string) {
  const hasExistingCloudinaryParameters = CLOUDINARY_PARAMETER_REGEX.test(url);

  let cloudinaryUrlWithoutParameters: string[] = [];

  // Check for parameters and split on them if they exist
  // otherwise just split on upload/
  const parameterString = url.match(CLOUDINARY_PARAMETER_REGEX)?.[1];
  if (hasExistingCloudinaryParameters && parameterString) {
    cloudinaryUrlWithoutParameters = url.split(parameterString);
  } else {
    // Add in our own delimiter so that we can easily split the URL
    // as if it already had parameters
    const urlWithDelimiter = url.replace(/upload\//gi, "upload/<SPLIT>");
    cloudinaryUrlWithoutParameters = urlWithDelimiter.split("<SPLIT>");
    // We need to add a forward slash since we'll be injecting a parameter string
    cloudinaryUrlWithoutParameters[1] = `/${cloudinaryUrlWithoutParameters[1]}`;
  }

  return cloudinaryUrlWithoutParameters.flat();
}

/**
 * Parses Cloudinary transformation parameters from a Cloudinary URL
 *
 * @param {string} url A valid Cloudinary URL with (or without) parameters
 * @returns An object containing any Cloudinary parameters that existed in
 *          the original Cloudinary URL
 */
export function getCloudinaryParameters(url: string) {
  // Check if the URL already has parameters
  const cloudinaryParameters = {};
  const hasExistingCloudinaryParameters = CLOUDINARY_PARAMETER_REGEX.test(url);

  if (hasExistingCloudinaryParameters) {
    // We know we have a matching group because of the test above
    // then we will split on / just in case we have accidentally doubled
    // up on Cloudinary params (this currently happens in some places)
    const parameterStrings = url.match(CLOUDINARY_PARAMETER_REGEX)?.[1].split("/");

    parameterStrings?.forEach((parameterString) => {
      const parameters = parameterString.split(",");
      parameters.forEach((parameter) => injectCloudinaryParameter(parameter, cloudinaryParameters));
    });
  }

  return cloudinaryParameters;
}

/**
 * Adds parameters to a Cloudinary URL to allow for transformations, etc.
 * Transformation API reference: https://cloudinary.com/documentation/transformation_reference
 *
 * @param {string} url A Cloudinary URL to add transformation parameters to
 * @param {string[]} parameters An array of Cloudinary transformations eg. ["q_auto:best", "f_auto"]
 * @returns A Cloudinary URL with the new parameters added to it
 */
export function addCloudinaryParameters(url: string, parameters: string[]) {
  // Make sure we have a Cloudinary URL
  if (!/https:\/\/res\.cloudinary\.com/.test(url)) {
    return url;
  }

  const cloudinaryParameters = getCloudinaryParameters(url);

  parameters.forEach((parameter) => injectCloudinaryParameter(parameter, cloudinaryParameters));

  return getCloudinaryUrlWithParameters(url, cloudinaryParameters);
}

/**
 * PRIVATE METHODS
 */

/**
 * Adds a new parameter into a Cloudinary parameter object
 * SIDE EFFECT: Modifies the existingParameters object
 *
 * @param {string} parameter The new Cloudinary parameter to add
 * @param {object} existingParameters Existing Cloudinary parameters
 */
function injectCloudinaryParameter(
  parameter: string,
  existingParameters: { [key: string]: string },
) {
  const [key, value] = parameter.split(":");
  existingParameters[key] = value || "";
}

/**
 *  Builds a Cloudinary URL with the given parameters
 *
 * @param {string} url A valid Cloudinary URL
 * @param {object} parameters Existing Cloudinary parameters
 * @returns A Cloudinary URL with the parameters injected into it
 */
function getCloudinaryUrlWithParameters(url: string, parameters: { [key: string]: string }) {
  const parameterString = Object.entries(parameters)
    .map(([key, value]) => (value ? `${key}:${value}` : key))
    .join(",");

  return getCloudinaryUrlWithoutParameters(url).join(parameterString);
}

const TRANSFORM_FUNCTIONS = {
  scale: scaleTransform,
  crop: cropTransform,
};

interface ImageOptions {
  format?: string;
  quality?: string;
  useDpr?: boolean;
  transform?: keyof typeof TRANSFORM_FUNCTIONS;
  width?: number | string;
}

export const getCloudinaryImageUrl = (
  url: string,
  { format = "auto", quality = "auto:best", transform = "scale", width, useDpr }: ImageOptions = {},
): CloudinaryImage => {
  const image = makeCloudinaryImage(url)
    .format(format)
    .quality(quality)
    .resize(TRANSFORM_FUNCTIONS[transform](width));

  // SSG/SSR issues - can't use window.
  // TODO: look into whether we should be using Cloudinary stuff, or Next/Image for better optimization
  // https://nextjs.org/docs/api-reference/next/image
  // if (useDpr) {
  //   image.delivery(dpr(window.devicePixelRatio));
  // }

  return image;
};

/**
 * Cloudinary accepts specific values for DPR
 * depending on the device. This helper function
 * ensures we're passing valid DPR values to cloudinary
 * and sets the minimum DPR to 2.0
 *
 * @param devicePixelRatio
 * @returns {string}
 */
const getDevicePixelRatio = (devicePixelRatio: number) => {
  const roundedDevicePixelRatio = Math.round(devicePixelRatio);

  if (roundedDevicePixelRatio <= 2) {
    return "2.0";
  } else {
    return "3.0";
  }
};

/**
 * getResponsiveImageURL modifies the cloudinary image url
 * with the provided width and adds exif to image if needed.
 *
 * @param imageURL - unmodified media link of the image
 * @param width - max width to set
 * @return new cloudinary url with the requested dimensions.
 */
export const getResponsiveImageURL = (imageURL: string, width = "auto") => {
  // q_auto optimizes image quality
  // f_auto optimizes the file format returned to the browser
  // w_width sets the width
  // h_height sets the height
  // dpr sets the device pixel ratio of the device
  // c_limit ensures that the image is only resized if the original image size is
  //   larger than the requested size
  return addCloudinaryParameters(imageURL, [
    "a_exif",
    "q_auto:best",
    "f_auto",
    `w_${width}`,
    `dpr_2`,
    "c_limit",
  ]);
};

export const getCloudinaryVideoThumnailURL = (videoURL: string, width = "auto") => {
  // replace videoURL's ".mp4" with ".jpg" to get the thumbnail

  if (!videoURL.includes("cloudinary")) {
    return videoURL;
  }

  var upToLastDot = videoURL.split(".");
  upToLastDot.pop();
  const thumbnailURL = upToLastDot.join(".") + ".jpg";

  const editedURL = addCloudinaryParameters(thumbnailURL, [
    "q_auto:best",
    "f_auto",
    `w_${width}`,
    "c_limit",
  ]);
  console.log("REPLACING VIDEOURL", videoURL, "WITH", editedURL);
  return editedURL;
};
