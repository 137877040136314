import { useState, useEffect, createContext, useMemo, useCallback } from "react";

import { useRouter } from "next/router";

import { getCoachFeedback, CoachFeedback } from "~/api/sfCoachApi";
import { InstructorInfo } from "~/api/sfCurriculumApi";
import { SFNotification } from "~/api/sfNotificationsApi";
import { getNotifications } from "~/api/sfNotificationsApi";

import { useAuth } from "../auth/useAuth";

interface SchoolCoreState {
  coachFeedback: CoachFeedback | undefined;
  notifications: SFNotification[];
  hasUnreadNotifications: boolean;
  setNotifications: React.Dispatch<React.SetStateAction<SFNotification[]>>;
  setHasUnreadNotifications: React.Dispatch<React.SetStateAction<boolean>>;
}

export const SchoolCoreContext = createContext<SchoolCoreState>({
  coachFeedback: undefined,
  notifications: [],
  hasUnreadNotifications: false,
  setNotifications: () => {},
  setHasUnreadNotifications: () => {},
});

export interface UseSchoolCoreProviderProps {
  children: React.ReactNode;
}

export const SchoolCoreProvider = ({ children }: UseSchoolCoreProviderProps) => {
  const [coachFeedback, setCoachFeedback] = useState<CoachFeedback>();

  const [notifications, setNotifications] = useState<SFNotification[]>([]);
  const [hasUnreadNotifications, setHasUnreadNotifications] = useState(false);
  const router = useRouter();
  const { user } = useAuth();

  useEffect(() => {
    if (router.asPath.includes("school/music") && (!coachFeedback || !notifications)) {
      setupCoreData();
    }
  }, [router.asPath, user]);

  const clearSchoolCoreData = useCallback(() => {
    setCoachFeedback(undefined);
    setNotifications([]);
    setHasUnreadNotifications(false);
  }, [user]);

  const setupCoreData = useCallback(async () => {
    console.log("Setting up core data", user?.firstName || "No user found");

    if (!user) {
      console.log("User not found");
      clearSchoolCoreData();
      return;
    }

    const getCoachFeedbackPromise = getCoachFeedback().then((response) => {
      if (!response) {
        console.log("COACH FEEDBACK not returned");
        return;
      }

      console.log("COACH FEEDBACK recieved", response);

      setCoachFeedback(response[0] as CoachFeedback);
    });

    const getNotificationsPromise = getNotifications().then((newNotificationsResponse) => {
      console.log("NOTIFICATIONS recieved", newNotificationsResponse);
      if (newNotificationsResponse) {
        setNotifications(newNotificationsResponse.notifications);
        setHasUnreadNotifications(newNotificationsResponse.hasUnreadNotifications);
      }
    });

    await Promise.all([getCoachFeedbackPromise, getNotificationsPromise]);
  }, [user]);

  const contextValue = useMemo(
    () => ({
      coachFeedback,
      notifications,
      hasUnreadNotifications,
      setNotifications,
      setHasUnreadNotifications,
    }),
    [
      coachFeedback,
      notifications,
      hasUnreadNotifications,
      setNotifications,
      setHasUnreadNotifications,
    ],
  );

  return <SchoolCoreContext.Provider value={contextValue}>{children}</SchoolCoreContext.Provider>;
};
