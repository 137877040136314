export function getHasUserEverEnrolledInClass(classesEnrolled, classId) {
  if (classesEnrolled) {
    const classIdsUserHasEnrolledIn = Object.keys(classesEnrolled).map((className) => {
      return className.split("__")[0];
    });
    return classIdsUserHasEnrolledIn.includes(classId);
  }
  return false;
}

export function getDoesUserHaveActiveClasses(user) {
  const currentClassesEnrolled = user?.classesEnrolled
    ? Object.values(user.classesEnrolled)
        .filter((experience) => Date.now() <= new Date(experience.classEndDate))
        .sort((a, b) => new Date(b.classEndDate).getDate() - new Date(a.classStartDate).getDate())
    : [];

  return currentClassesEnrolled.length > 0;
}

export function getDoesUserHaveClasses(user) {
  if(!user){
    return false;
  }
  return user.classesEnrolled && Object.keys(user.classesEnrolled).length > 0;
}
