import { useEffect, useState } from "react";

import { useRouter } from "next/router";

import { BrowseQueryState } from "../types";

export const useQueryState = ({
  isHomepage,
  classesCount,
}: {
  isHomepage?: boolean;
  classesCount?: number;
}) => {
  const router = useRouter();
  const defaultTitle = "Explore Studio classes";

  const [state, setState] = useState<BrowseQueryState>({
    title: "",
    view: "All classes",
    category: "",
    search: "",
    topics: [],
    genres: [],
  });

  const getPageTitle = (query: { view?: string; category?: string }, classesCount?: number) => {
    let pageTitle = defaultTitle;
    if (query.view && query.view === "New releases") {
      pageTitle = `New Classes (${classesCount})`;
    } else if (query.view && query.view === "Top rated") {
      pageTitle = `Top Rated Classes`;
    } else if (query.view && query.view === "Collections") {
      pageTitle = `Collections`;
    } else if (query.category) {
      pageTitle = `${query.category} Classes (${classesCount})`;
    }
    return pageTitle;
  };

  useEffect(() => {
    setState({
      title: getPageTitle(router.query, classesCount),
      view:
        (router.query.category && !router.query.view || router.query.search && !router.query.view)
          ? ""
          : router.query.view
          ? router.query.view
          : state.view,
      category: typeof router.query.category === "string" ? router.query.category : "",
      search: typeof router.query.search === "string" ? router.query.search : "",
      topics:
        typeof router.query.topics === "string"
          ? router.query.topics.split(",").filter((topic) => topic !== "")
          : (router.query.topics as string[]),
      genres:
        typeof router.query.genres === "string"
          ? router.query.genres.split(",").filter((genre) => genre !== "")
          : (router.query.genres as string[]),
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [router.query, defaultTitle, classesCount]);

  const setQueryParams = (query: BrowseQueryState) => {
    router.push(
      {
        pathname: isHomepage ? "/browse" : router.pathname,
        query: {
          ...Object.entries(query).reduce((acc, [key, value]) => {
            if (value) {
              if (key === "title" || (Array.isArray(value) && value.length === 0) || value === "") {
                return {
                  ...acc,
                };
              }

              if (Array.isArray(value)) {
                value = value.join(",");
              }

              return {
                ...acc,
                [key]: value,
              };
            }
            return acc;
          }, {}),
        },
      },
      undefined,
      {
        shallow: true,
      },
    );
  };

  return { state, setQueryParams };
};
