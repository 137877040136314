import "../../../../../node_modules/@vanilla-extract/webpack-plugin/vanilla.virtual.css?%7B%22fileName%22%3A%22src%2Fcomponents%2Fschool%2Fcommon%2FDropdown%2FDropdown.css.ts.vanilla.css%22%2C%22source%22%3A%22%23H4sIAAAAAAAAA81U0W7bIBR9z1fcqZqUSSOy3TpJydNe9hsTtonNioHCdWxn6r9P4LiJ0zRNX6YJyRb33MvlHA4sfsWldM%2FPLoI%2FM4BCOCNZT2ErebeZQfiTQlieo9CKQq5lUyuPlMxQSE23mb3MFuMqcVhlqxWSLauF7CnsmJ0T0iwZyippv21GvOWirPAM7464w17yM7g%2FgcX%2BHN0HVArFSXVh8fso4LmW2k6RB9%2F2hEXyAQv7AQt3nQVeZdFcZ5HsTlnc%2FfjpR4g01vmQ0UIhtz6EliknXg9OW4gWiZtQvaeV3nEbCN%2BgzMNllzApSkUE8toNQeKQWbxuoN%2BNQ7HtSa4VcoUUnGE5JxnHlvOQYVhRCFVSiBPTQbw2oVnG8qfS6kYVFO6S1I8Q1rbgllhWiMZROCQHl8bRoTKkUIhNB05LUYAtMzaPV%2Bl3OH6iRTo4ZZQ%2FXqdDvVdqK3VLegqsQR32qEeFWea0bJAPnRB1TYGsD633RKiCd34vflqzjrSiwIpCzmQ%2Bj6PoKxBYpma4AQfMh6f6U0panj0JJC63WsqM2emRKK34pCS99WyXIfGTGh2PyBfEK9PBF1EbbZEpnCy%2FusE6OR%2Bte24bq9t%2F5pk3N%2BmExTqwqJkthaKQ%2BB6RH5Okx%2F%2F%2F%2FXhHkDP1lqf35o1M71jq6KHBiwCWD%2Fsc57pBv78LZn2k1EiW80rLYvoqvb50L38BhxbIP7MGAAA%3D%22%7D"
export var activeSelectedOptionContainer = '_1glsqqs6';
export var container = '_1glsqqs0';
export var hoverableOption = '_1glsqqs3';
export var inputContainer = '_1glsqqs8';
export var label = '_1glsqqs1';
export var option = '_1glsqqs2';
export var optionsContainer = '_1glsqqs4';
export var selectedOption = '_1glsqqs5';
export var selectedOptionContainer = '_1glsqqs7';
export var textarea = '_1glsqqs9';