import { useEffect } from "react";

import posthog from "posthog-js";

import { useAuth } from "~/hooks/auth/useAuth";

export const usePostHogAnalytics = () => {
  const { user } = useAuth();

  useEffect(() => {
    if (!user) {
      return;
    }

    // Identify user on login
    posthog.identify(user.userId, {
      email: user.email,
      firstName: user.firstName,
      lastName: user.lastName,
      username: user.username,
      signUpDate: user.signUpDate,
      miniBio: user.miniBio,
      membershipStatus: user.membershipStatus,
    });
  }, [user]);

  const capturePostHogEvent = <T extends Record<string, any>>(event: string, properties?: T) => {
    posthog.capture(event, properties);
  };

  return { capturePostHogEvent };
};
