import { useQuery } from "react-query";

import { ApiStatus } from "~/api/fetchStudioApi";
import { fetchUserMembershipInfo, MembershipInfo } from "~/api/membershipApi";
import { QueryKeys } from "~/api/reactQuery/client";
import { useAuth } from "~/hooks/auth/useAuth";

export const useMembershipInfo = (): {
  membershipInfo?: MembershipInfo;
  isLoading: boolean;
  fetchMembershipInfo: () => void;
} => {
  const { user, loadingCurrentUser } = useAuth();

  const { data: membershipInfo, isLoading } = useQuery(
    [QueryKeys.MembershipInfo],
    () => fetchMembershipInfo(),
    { enabled: !!user },
  );

  const fetchMembershipInfo = async () => {
    const result = await fetchUserMembershipInfo();
    if (result.type === ApiStatus.SUCCESS) {
      return result.value;
    }

    return;
  };

  return {
    membershipInfo,
    isLoading: isLoading || loadingCurrentUser,
    fetchMembershipInfo,
  };
};
