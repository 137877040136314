import { useQuery } from "react-query";



import { ContentProgressData, ProductData, ProductProgressData, UserProductPurchaseData } from "data/product/products";
import { getVideoData } from "data/videoProductPage";



import { ProductProgressInfo } from "~/components/BTSInProductVideoPage/BTSVideoPlayer/BTSVideoPlayer";
import { useAuth } from "~/hooks/auth/useAuth";
import { Review } from "~/hooks/review/ReviewProvider";
import { isUUID, getProductIdFromSlug } from "~/util/stringUtils";



import { ApiResult, fetchApi } from "./fetchStudioApi";


// Note: this is a temporary datastructure to represent music school in a few entry points.
// TODO: replace with a proper datastructure that comes from the new backend when it's ready.
export const SF_MUSIC_SCHOOL_DATA = {
  id: "70a7f2dd-3888-4eb8-8ca3-8b68cf76ed41",
  storefrontId: "studio",
  status: "Published",
  title: "Music School Of The Future: The World’s First AI-Powered School",
  description:
    "The online accelerator for songwriters, producers, and artists. Finish & release 1 new song every month  — with in-depth guidance from the world’s best.",
  dateDescription: "Next session: May 2024",
  priceInfo: {
    price: 19999,
    crossedPrice: 19999,
    crossedPriceApplicableFrom: "2024-01-03T18:00:00.000Z",
  },
  productImageURL:
    "https://res.cloudinary.com/monthly/image/upload/v1706304535/studio_schoolfuture/music-school-cover.jpg",
  productPreviewURL:
    "https://res.cloudinary.com/monthly/image/upload/v1706304535/studio_schoolfuture/music-school-cover.jpg",
  createdAt: "2024-01-03T18:00:00.000Z",
  lastUpdatedAt: "2024-01-03T18:00:00.000Z",
  creatorName: "studio",
  creatorProfileImageURL: "",
  storefrontSlug: "studio",
  totalVideoLengthInSeconds: 0,
  creatorBio: "Studio",
  creatorOnStudioSinceDate: "2024-01-03T18:00:00.000Z",
  tags: [],
  genreTags: [],
  content: [],
  classSlug: "music",
  slug: "music",
  isCollection: false,
  creatorProfileImages: [],
  totalLessons: 0,
  totalHours: 0,
  productsInfoIncluded: [],
  availableUntil: "2024-01-03T18:00:00.000Z",
  totalValuePriceInCents: 0,
  productsIncluded: [],
  orderIndex: 0,
  isSchool: true,
  overviewSectionTitle: "",
  overviewSectionDescription: "",
  whoIsThisForSection: [],
  skills: [],
  exampleMediaSectionTitle: "",
  exampleMediaSectionDescription: "",
} as ProductData;

export const getProductData = async (
  productIdSlug: string,
  storefrontSlug?: string,
): Promise<ProductData> => {
  const productId = getProductIdFromSlug(productIdSlug);
  let productData = undefined;

  // If it's an actuall UUID, then we can just fetch the product data
  if (isUUID(productId)) {
    productData = await getProductDataFromProductId(productId);
  } else if (storefrontSlug) {
    // Otherwise, it's a class slug, so fetch that.
    productData = await getProductDataFromClassSlug(productId, storefrontSlug);
  } else {
    // Else, if there's no storefront slug try fetching with just the productId
    productData = await getProductDataFromClassSlug(productId);
  }

  return productData;
};

export const getProductDataFromProductId = async (productId: string): Promise<ProductData> => {
  console.log("fetching product data ");
  let response = await fetchApi({
    path: "/getProduct?id=" + productId,
    method: "GET",
  });

  console.log("fetched product data and got response ", response);
  return response.product;
};

export const getSecureProductForLibrary = async (productId: string): Promise<ProductData> => {
  console.log("fetching product data ");
  let response = await fetchApi({
    path: "/getSecureProductForLibrary?id=" + productId,
    method: "GET",
    authRequired: true,
  });

  console.log("fetched secure product data and got response ", response);
  return response.product;
};

export const getProductDataFromClassSlug = async (
  classSlug: string,
  storefrontSlug?: string,
): Promise<ProductData> => {
  // console.log("fetching product data FROM CLASS SLUG ");

  let queryParams = `?class_slug=${classSlug}`;

  if (storefrontSlug) {
    queryParams += `&storefront_slug=${storefrontSlug}`;
  }
  let response = await fetchApi({
    //path: `/getProductWithClassSlug?class_slug=${classSlug}&storefrontSlug=${storefrontSlug}`,
    path: `/getProductWithClassSlug` + queryParams,
    method: "GET",
  });

  // console.log("fetched product data and got response ", response);
  return response.product;
};

export const getProductFeaturedReviewsData = async (productId: string): Promise<any> => {
  console.log("fetching product reviews data ");
  let response = await fetchApi({
    path: "/getReviewsOfProduct?product_id=" + productId,
    method: "GET",
  });

  // console.log("fetched reviews data and got response ", response);

  const reviews = response.reviews.filter((review: Review) => review.isFeatured);
  //const mockReviews = getVideoData().reviews;
  return reviews;
};

export interface ProductPathObject {
  productIdSlug: string;
  storefrontSlug: string;
  productId: string;
  classSlug?: string;
}

export const getProducts = async (
  limit: number,
  lastProductId?: string,
  categories?: string,
  topics?: string[],
  genres?: string[],
): Promise<{
  products: ProductData[];
  isMoreAvailable: boolean;
  totalAvailableCount?: number;
}> => {
  let extraQueryParams = "";
  // test limit changing
  if (limit) {
    extraQueryParams = `?limit=${limit}`;
  }
  // test product ID
  if (lastProductId && lastProductId !== "") {
    extraQueryParams += `&lastProductId=${lastProductId}`;
  }

  // test categories
  // categories = ["Music"];
  if (categories) {
    extraQueryParams += `&categories=${encodeURIComponent(categories)}`;
  }
  // topics = ["Music Production"];
  if (topics) {
    extraQueryParams += `&topics=${encodeURIComponent(topics.join(","))}`;
  }
  // genres = ["Dance", "Pop"];
  if (genres) {
    extraQueryParams += `&genres=${encodeURIComponent(genres.join(","))}`;
  }

  let response = await fetchApi({
    path: "/getProducts" + extraQueryParams,
    method: "GET",
  });

  console.log("fetched products and got this response ", response);
  return response;
};

export const getMostPopularProducts = async (
  isForHomePage: boolean,
  limit?: number,
  lastProductId?: string,
): Promise<{
  products: ProductData[];
  isMoreAvailable: boolean;
  totalAvailableCount?: number;
}> => {
  let extraQueryParams = "";
  //isForHomePage = true;
  if (isForHomePage) {
    extraQueryParams = `?isForHomepage=${isForHomePage}`;
  } else {
    extraQueryParams = `?isForHomepage=${false}`;
  }
  if (limit) {
    extraQueryParams += `&limit=${limit}`;
  }
  if (lastProductId && lastProductId !== "") {
    extraQueryParams += `&lastProductId=${lastProductId}`;
  }

  let response = await fetchApi({
    path: "/getMostPopularProducts" + extraQueryParams,
    method: "GET",
  });
  return response;
};

export const getNewReleasesProducts = async (
  isForHomePage: boolean,
  limit?: number,
  lastProductId?: string,
): Promise<{
  products: ProductData[];
  isMoreAvailable: boolean;
  totalAvailableCount?: number;
}> => {
  let extraQueryParams = "";
  //isForHomePage = true;

  console.log("THIS IS WHAT WE HAVE FOR ISHOMEPAGE? ", isForHomePage);
  if (isForHomePage) {
    extraQueryParams = `?isForHomepage=${isForHomePage}`;
  } else {
    extraQueryParams = `?isForHomepage=${false}`;
  }
  if (limit) {
    extraQueryParams += `&limit=${limit}`;
  }
  if (lastProductId && lastProductId !== "") {
    extraQueryParams += `&lastProductId=${lastProductId}`;
  }

  let response = await fetchApi({
    path: "/getNewReleasesProducts" + extraQueryParams,
    method: "GET",
  });

  return response;
};

export const getAllProductPathsToPreRender = async (): Promise<ProductPathObject[]> => {
  console.log("in productApi fetching all products ");
  let response = await fetchApi({
    path: "/getAllProductPathsToPreRender",
    method: "GET",
  });

  // console.log("fetched products and got this response ", response);
  return response.productsUrlPaths;
};

export const getAllUserProductPurchases = async (): Promise<UserProductPurchaseData[]> => {
  // console.log("fetching user product purchases ");
  let response = await fetchApi({
    path: "/getUserPurchases",
    method: "GET",
    authRequired: true,
  });

  // console.log("fetched product data and got response ", response);
  // Filter out the refunded purchases
  return response.purchases.filter((purchase: UserProductPurchaseData) => !purchase.refundedInfo);
};

export const getUserProductPurchaseForProduct = async (
  productId: string,
): Promise<UserProductPurchaseData> => {
  // console.log("fetching user product purchase for product id ", productId);
  let response = await fetchApi({
    path: "/getUserPurchaseForProduct?product_id=" + productId,
    method: "GET",
    authRequired: true,
  });

  // console.log("fetched product data and got response ", response);
  return response.purchase;
};

export const useUserProductPurchaseForProduct = (productId: string) => {
  const { user, loadingCurrentUser } = useAuth();

  const { data: userProductPurchase, isLoading } = useQuery(
    ["userProductPurchases"],
    () => fetchUserProductPurchase(productId),
    { enabled: !!user },
  );

  const fetchUserProductPurchase = async (productId: string) => {
    const purchase = await getUserProductPurchaseForProduct(productId);
    // console.log("got result from purchase ", purchase.productInfo.storefrontSlug, purchase);

    // Only return the purchase if it's not refunded
    if (purchase && !purchase.refundedInfo) {
      return purchase;
    }

    return;
  };

  return {
    userProductPurchase,
    isLoading: isLoading || loadingCurrentUser,
    getUserProductPurchaseForProduct,
  };
};

export const updateUserProductProgress = (
  productProgressInfo: ProductProgressInfo,
): Promise<ApiResult> => {
  const requestBody = JSON.stringify({
    product_id: productProgressInfo.productId,
    content_id: productProgressInfo.contentId,
    percent_complete: productProgressInfo.percentageComplete,
    additional_seconds_watched: productProgressInfo.additionalSecondsWatched,
    position_in_seconds: productProgressInfo.positionInSeconds,
  });
  return fetchApi({
    path: "/updateProductProgress",
    body: requestBody,
    method: "POST",
    isJsonPayload: true,
    authRequired: true,
  });
};

export const getContentProgress = async (
  productId: string,
  contentId: string,
): Promise<ContentProgressData> => {
  let response = await fetchApi({
    path: `/getContentProgress?product_id=${productId}&content_id=${contentId}`,
    method: "GET",
    authRequired: true,
  });
  return response.progress;
};

export const getProductProgress = async (productId: string): Promise<ProductProgressData> => {
  let response = await fetchApi({
    path: "/getProductProgress?product_id=" + productId,
    method: "GET",
    authRequired: true,
  });

  return response.progress;
};

export const getMusicSchoolFeaturedProducts = async (): Promise<{
  products: ProductData[];
}> => {
  let response = await fetchApi({
    path: "/getMusicSchoolFeaturedProducts",
    method: "GET",
  });
  return response;
};