import { Post } from "~/api/sfPeersApi";
import { UserWithConfirmedAttendance } from "~/api/sfSessionEnroll";

export function transformDates(startDate?: string, endDate?: string) {
  if (!startDate || !endDate) {
    return "";
  }

  const [startYear, startMonth, startDay] = startDate.split("-").map(Number);
  const startDateObj = new Date(startYear, startMonth - 1, startDay); // Month is 0-indexed in JavaScript

  const startStr = startDateObj.toLocaleDateString("default", {
    month: "short",
    day: "numeric",
  });

  const [year, month, day] = endDate.split("-").map(Number);
  const endDateObj = new Date(year, month - 1, day); // Month is 0-indexed in JavaScript

  const endStr = endDateObj.toLocaleDateString("default", {
    month: "short",
    day: "numeric",
    year: "numeric",
  });

  const resultStr = `${startStr} - ${endStr}`;

  return resultStr;
}

export function calculatePercentageRemaining(startDate?: string, endDate?: string): number {
  if (!startDate || !endDate) {
    return 0;
  }

  const now: Date = new Date();
  const startDateObj: Date = new Date(startDate);
  const endDateObj: Date = new Date(endDate);

  const totalDuration: number =
    (endDateObj.getTime() - startDateObj.getTime()) / (1000 * 3600 * 24);
  const elapsedDuration: number = (now.getTime() - startDateObj.getTime()) / (1000 * 3600 * 24);

  let remainingPercentage: number = Math.floor((elapsedDuration / totalDuration) * 100);

  remainingPercentage = Math.max(0, Math.min(100, remainingPercentage));

  return remainingPercentage;
}

export const transformDate = (inputDate?: string) => {
  if (!inputDate) return ""; // Handle empty input

  const [year, month, day] = inputDate.split("-").map(Number);
  const dateObject = new Date(year, month - 1, day); // Month is 0-indexed in JavaScript

  return dateObject.toLocaleDateString("default", {
    weekday: "short",
    month: "short",
    day: "numeric",
  });
};

export const getWeekInfo = (startDate?: string, endDate?: string) => {
  const startDateObj = new Date(startDate || "");
  const endDateObj = new Date(endDate || "");

  const currentDate = new Date();
  const totalWeeks = Math.ceil(
    (endDateObj.getTime() - startDateObj.getTime()) / (7 * 24 * 60 * 60 * 1000),
  );

  let currentWeek = Math.ceil(
    (currentDate.getTime() - startDateObj.getTime()) / (7 * 24 * 60 * 60 * 1000),
  );

  currentWeek = Math.max(0, currentWeek);

  return {
    totalWeeks: Math.max(0, totalWeeks),
    currentWeek,
  };
};

export const daysUntilDate = (date: string | undefined, label: string) => {
  const startDateObj = new Date(date || "");

  const currentDate = new Date();
  const timeDiff = startDateObj.getTime() - currentDate.getTime();

  const daysRemaining = Math.ceil(timeDiff / (1000 * 3600 * 24));

  if (daysRemaining === 1) {
    return `${label} tomorrow`;
  } else if (daysRemaining > 1) {
    return `${label} in ${daysRemaining} days`;
  } else if (daysRemaining === 0) {
    return `${label} today!`;
  } else if (daysRemaining < 0) {
    return `Overdue!`;
  }

  return "";
};

export const dayInCurriculum = (startDate: string, endDate: string, sessionStartDate: string) => {
  const endDateObj = new Date(endDate);
  const sessionStartDateObj = new Date(sessionStartDate);

  const timeDiff = endDateObj.getTime() - sessionStartDateObj.getTime();

  const dayEndInCurriculum = Math.ceil(timeDiff / (1000 * 3600 * 24)) + 1;

  if (startDate) {
    const startDateObj = new Date(startDate);
    const endTimeDiff = startDateObj.getTime() - sessionStartDateObj.getTime();
    const dayStartInCurriculum = Math.ceil(endTimeDiff / (1000 * 3600 * 24)) + 1;
    if (startDateObj && dayStartInCurriculum && dayStartInCurriculum !== dayEndInCurriculum) {
      return `Days ${dayStartInCurriculum}-${dayEndInCurriculum}`;
    }
  }

  return `Day ${dayEndInCurriculum}`;
};

export const getCurrentRoute = (route: string, isCurrentUser: boolean) => {
  if (route.includes("peer-group")) {
    return "peer-group";
  } else if (route.includes("profile") && isCurrentUser) {
    return "profile";
  } else if (route.includes("curriculum")) {
    return "curriculum";
  } else if (route === "/school/music") {
    return;
  } else {
    return "/";
  }
};

export function getAudioFiles(media: Post["media"]) {
  return media?.filter((item) => item.type === "audio").map((audioItem) => audioItem);
}

export function getYouTubeItems(media: Post["media"]) {
  return media?.filter((item) => item.type === "youtube");
}

export function getVideoAndPhotoFiles(media: Post["media"]) {
  if (!media) return { length: 0, firstMedia: undefined, excludingFirstFile: [] };

  const sortedMedia = media.filter(
    (item) => item.type !== "audio" && item.type !== "youtube" && item.url.includes("cloudinary"),
  );
  const firstMedia = sortedMedia.find((item) => item.type === "video") || sortedMedia[0]; // first item is preferably video
  const excludingFirstFile = sortedMedia.filter((item) => item !== firstMedia);

  return {
    sortedMedia,
    length: sortedMedia.length,
    firstMedia,
    excludingFirstFile,
  };
}

export const calculateBottomWidth = (files: number) => {
  const maxFiles = Math.min(files, 3);
  const bottomImageWidth = `calc((100% - ${maxFiles}px) / ${maxFiles})`;
  return bottomImageWidth;
};

export const calculateBottomHeight = (files: number) => {
  if (files === 1) {
    return 175;
  } else {
    return 83;
  }
};

export const determineDropdownPosition = (
  dropdownRef: React.RefObject<HTMLElement>,
  dropdownHeight: number,
  dropdownContaineRef: React.RefObject<HTMLDivElement>,
) => {
  const dropdownPicker = dropdownRef.current;
  const dropdownContainer = dropdownContaineRef.current;
  const parentContainer = document.querySelector("main");

  if (!dropdownPicker || !parentContainer || !dropdownContainer) {
    console.error("Dropdown picker or parent container not found");
    return {};
  }

  const parentContainerRect = parentContainer.getBoundingClientRect();
  const dropdownRect = dropdownPicker.getBoundingClientRect();
  const dropdownContainerRect = dropdownContainer.getBoundingClientRect();

  const spaceBottom = parentContainerRect.bottom - dropdownRect.bottom;
  const spaceRight = parentContainerRect.right - dropdownContainerRect.right;

  const hasEnoughSpaceBottom = spaceBottom >= dropdownHeight;
  const hasEnoughSpaceRight = spaceRight >= dropdownContainerRect.width;

  const top = hasEnoughSpaceBottom ? "26px" : `-${dropdownHeight + 9}px`;
  const positionY = !hasEnoughSpaceRight
    ? {
        right: 0,
      }
    : {
        left: 0,
      };

  return {
    top,
    positionY,
  };
};

export const getMediaType = (url: string): string => {
  const imageRegex = /\.(jpeg|jpg|gif|png|webp|svg)$/i;
  const videoRegex = /\.(mp4|mov|avi|mkv|flv)$/i;
  const audioRegex = /\.(mp3|wav|m4a|ogg)$/i;
  const youtubeRegex =
    /^(https?:\/\/)?(www\.)?(youtube\.com\/(.*v=|.*\/videos\/|.*\/embed\/|.*\/v\/)|youtu\.be\/)([^"&?\/\s]{11})$/i;

  if (imageRegex.test(url)) {
    return "photo";
  }

  if (videoRegex.test(url) || youtubeRegex.test(url)) {
    return "video";
  }

  if (audioRegex.test(url)) {
    return "audio";
  }

  return "";
};

export const getGradientColor = (imageUrl: string | undefined) => {
  return new Promise((resolve) => {
    if (!imageUrl) {
      return resolve("#1E1E1E");
    }

    const img = new Image();
    img.crossOrigin = "Anonymous";
    img.src = imageUrl;

    img.onload = () => {
      const canvas = document.createElement("canvas");
      const ctx = canvas.getContext("2d");

      canvas.width = img.width;
      canvas.height = img.height;

      if (!ctx) {
        return resolve("#1E1E1E");
      }

      ctx.drawImage(img, 0, 0);

      const imageData = ctx.getImageData(0, 0, canvas.width, canvas.height).data;

      let totalRed = 0,
        totalGreen = 0,
        totalBlue = 0;

      for (let i = 0; i < imageData.length; i += 4) {
        totalRed += imageData[i];
        totalGreen += imageData[i + 1];
        totalBlue += imageData[i + 2];
      }

      const averageRed = Math.round(totalRed / (imageData.length / 4));
      const averageGreen = Math.round(totalGreen / (imageData.length / 4));
      const averageBlue = Math.round(totalBlue / (imageData.length / 4));

      const averageColor = `rgb(${averageRed},${averageGreen},${averageBlue})`;

      resolve(averageColor);
    };
  });
};

export const emptySessionDescription = (
  isCurrentUser: boolean,
  user: UserWithConfirmedAttendance | undefined,
) => {
  return isCurrentUser
    ? "Looks like you haven’t completed any projects for this session. Once you start submitting projects, they will be displayed in order on your profile. This will help you easily track your progress, all in one place!"
    : `Looks like ${user?.firstName} hasn’t completed any projects for this session. Once they start submitting projects, they will be displayed in order on their profile. `;
};

export const combineWithoutDuplicates = (arr1: any[] | undefined, arr2: any[] | undefined) => {
  if (!arr1 && !arr2) return [];
  if (!arr1) return arr2;
  if (!arr2) return arr1;

  // Function to flatten any nested array structure
  const flatten = (arr: any[]): string[] => {
    return arr.reduce(
      (acc, val) => (Array.isArray(val) ? acc.concat(flatten(val)) : acc.concat(val)),
      [],
    );
  };

  // Flatten both input arrays to ensure no nested structures
  const flatArr1 = flatten(arr1);
  const flatArr2 = flatten(arr2);

  // Use a Set to ensure uniqueness
  const uniqueSet = new Set<string>();

  [...flatArr1, ...flatArr2].forEach((element) => {
    uniqueSet.add(element);
  });

  // Convert back to array
  const arr = Array.from(uniqueSet);

  return arr;
};
export const timestampFromSeconds = (seconds: number) => {
  // calculates the timestamp from seconds, like 1:30 from 90 seconds, or 1:21:30 from 4890 seconds
  const hours = Math.floor(seconds / 3600);
  const minutes = Math.floor((seconds % 3600) / 60);
  const remainingSeconds = Math.floor(seconds % 60);

  const hoursStr = hours > 0 ? `${hours}:` : "";
  const minutesStr = `${minutes < 10 && hours > 0 ? "0" : ""}${minutes}:`;
  const secondsStr = `${remainingSeconds < 10 ? "0" : ""}${remainingSeconds}`;

  return `${hoursStr}${minutesStr}${secondsStr}`;
};

const getCurrentTimestamp = () => {
  // Returns the current timestamp as 2024-04-04T22:39:30.020239+00:00
  const date = new Date();
  return date.toISOString();
};
