import React, { useCallback, useMemo } from "react";

import { Post } from "~/api/sfPeersApi";
import { UserWithConfirmedAttendance } from "~/api/sfSessionEnroll";
import { SchoolModal } from "~/components/school/SchoolModal/SchoolModal";
import { SubmitProjectModalProps } from "~/components/school/SchoolModal/SubmitProjectModal";

export enum SchoolModalType {
  Report = "report",
  Project = "project",
  Delete = "delete",
  Update = "update",
  Edit = "edit",
  Gallery = "gallery",
  Overview = "overview",
}

export type OpenModalFunction = (
  variant: SchoolModalType,
  extraProps?: SchoolModalExtraProps,
) => void;

export interface SchoolModalExtraProps {
  peer?: UserWithConfirmedAttendance;
  variant?: string;
  post?: Post | null;
  currentCurriculumItem?: SubmitProjectModalProps["currentCurriculumItem"];
  onConfirm?: (submittedPost: Post) => void | (() => void) | (() => Promise<void>);
  media?: {
    id: string;
    type: string;
    url: string;
    metadata?:
      | {
          durationInSeconds?: string | undefined;
          waveform?: string | undefined;
          artURL?: string | undefined;
          title?: string | undefined;
        }
      | null
      | undefined;
  } | null;
}

export interface SchoolModalProps {
  openModal(variant: SchoolModalType, extraProps?: SchoolModalExtraProps): void;
  closeModal(): void;
}

export const SchoolModalContext = React.createContext<SchoolModalProps>({
  openModal: () => {},
  closeModal: () => {},
});

interface SchoolModalProviderProps {
  children: React.ReactNode;
}

export const SchoolModalProvider = ({ children }: SchoolModalProviderProps) => {
  const [isModalOpen, setIsModalOpen] = React.useState(false);
  const [schoolModalProps, setSchoolModalProps] = React.useState<SchoolModalExtraProps>({
    variant: "",
    post: null,
    currentCurriculumItem: undefined,
    onConfirm: () => {},
    media: null,
  });
  const [modalVariant, setModalVariant] = React.useState<SchoolModalType>(SchoolModalType.Report);

  const openModal = useCallback(
    (modalVariant: SchoolModalType, extraProps: SchoolModalExtraProps) => {
      setIsModalOpen(true);
      setSchoolModalProps(extraProps);
      setModalVariant(modalVariant);
    },
    [],
  );

  const closeModal = useCallback(() => {
    setIsModalOpen(false);
    setSchoolModalProps({
      peer: undefined,
      post: null,
      currentCurriculumItem: undefined,
      onConfirm: () => {},
      media: null,
    });
  }, []);

  const contextValue = useMemo(
    () => ({
      openModal,
      closeModal,
    }),
    [openModal, closeModal],
  );

  return (
    <SchoolModalContext.Provider value={contextValue}>
      {children}
      <SchoolModal
        modalVariant={modalVariant}
        isOpen={isModalOpen}
        onClose={closeModal}
        schoolModalProps={schoolModalProps}
      />
    </SchoolModalContext.Provider>
  );
};
